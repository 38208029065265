import * as React from 'react';
import pin_blue from './imgs/driver_pin/pin_blue.png';
import pin_blue_border from './imgs/driver_pin/pin_blue_border.png';
import pin_blue_thick from './imgs/driver_pin/pin_blue_thick.png';
import pin_black from './imgs/driver_pin/pin_black.png';
import pin_black_border from './imgs/driver_pin/pin_black_border.png';
import pin_black_thick from './imgs/driver_pin/pin_black_thick.png';

import pin_red from './imgs/driver_pin/pin_red.png';
import pin_red_border from './imgs/driver_pin/pin_red_border.png';
import pin_red_thick from './imgs/driver_pin/pin_red_thick.png';
import pin_gray from './imgs/driver_pin/pin_gray.png';
import pin_gray_border from './imgs/driver_pin/pin_gray_border.png';
import pin_gray_thick from './imgs/driver_pin/pin_gray_thick.png';

import pin_brown from './imgs/driver_pin/pin_brown.png';
import pin_brown_border from './imgs/driver_pin/pin_brown_border.png';
import pin_brown_thick from './imgs/driver_pin/pin_brown_thick.png';
import pin_maroon from './imgs/driver_pin/pin_purple.png';
import pin_maroon_border from './imgs/driver_pin/pin_purple_border.png';
import pin_maroon_thick from './imgs/driver_pin/pin_purple_thick.png';

import pin_orange from './imgs/driver_pin/pin_orange.png';
import pin_orange_border from './imgs/driver_pin/pin_orange_border.png';
import pin_orange_thick from './imgs/driver_pin/pin_orange_thick.png';

function DriverPin({size = 40, onHover, onClick, drNum, pin}) {
    //console.log("DriverPin-" + pin.drNum);
    var pin_src = pin_blue;
    var text_color = 'white';
    if(pin.mapColor.toLowerCase()=== 'black' && pin.border ===0){
        pin_src = pin_black;
        text_color = 'white';
    }
    else if(pin.mapColor.toLowerCase()=== 'black' && pin.border ===2){
       pin_src = pin_black_border;
       text_color = 'white';
    }
    else if(pin.mapColor.toLowerCase()=== 'black' && pin.border ===3){
        pin_src = pin_black_thick;
        text_color = 'white';
    }
    else if(pin.mapColor.toLowerCase()=== 'gray' && pin.border ===0){
        pin_src = pin_gray;
        text_color = '#0D191E';
    }
    else if(pin.mapColor.toLowerCase()=== 'gray' && pin.border ===2){
        pin_src = pin_gray_border;
        text_color = '#0D191E';
    }
    else if(pin.mapColor.toLowerCase()=== 'gray' && pin.border ===3){
        pin_src = pin_gray_thick;
        text_color = '#0D191E';
    }
    else if(pin.mapColor.toLowerCase()=== 'blue' && pin.border ===0){
        pin_src = pin_blue;
        text_color = 'white';
    }
    else if(pin.mapColor.toLowerCase()=== 'blue' && pin.border ===2){
        pin_src = pin_blue_border;
        text_color = 'white';
    }
    else if(pin.mapColor.toLowerCase()=== 'blue' && pin.border ===3){
        pin_src = pin_blue_thick;
        text_color = 'white';
    }
    else if(pin.mapColor.toLowerCase()=== 'brown' && pin.border ===0){
        pin_src = pin_brown;
    }
    else if(pin.mapColor.toLowerCase()=== 'brown' && pin.border ===2){
        pin_src = pin_brown_border;
    }
    else if(pin.mapColor.toLowerCase()=== 'brown' && pin.border ===3){
        pin_src = pin_brown_thick;
    }  
    else if(pin.mapColor.toLowerCase()=== 'maroon' && pin.border ===0){
        pin_src = pin_maroon;
    }
    else if(pin.mapColor.toLowerCase()=== 'maroon' && pin.border ===2){
        pin_src = pin_maroon_border;
    }
    else if(pin.mapColor.toLowerCase()=== 'maroon' && pin.border ===3){
        pin_src = pin_maroon_thick;
    }  
    else if(pin.mapColor.toLowerCase()=== 'orange' && pin.border ===0){
        pin_src = pin_orange;
    }
    else if(pin.mapColor.toLowerCase()=== 'orange' && pin.border ===2){
        pin_src = pin_orange_border;
    }
    else if(pin.mapColor.toLowerCase()=== 'orange' && pin.border ===3){
        pin_src = pin_orange_thick;
    } 
    else if(pin.mapColor.toLowerCase()=== 'red' && pin.border ===0){
        pin_src = pin_red;
    }
    else if(pin.mapColor.toLowerCase()=== 'red' && pin.border ===2){
        pin_src = pin_red_border;
    }
    else if(pin.mapColor.toLowerCase()=== 'red' && pin.border ===3){
        pin_src = pin_red_thick;
    }  
  

    return (
    <div style={{display: "flex", justifyContent: "center"}} onMouseEnter={onHover} onClick={onClick}>
      <div style={{position: "absolute", fontWeight:'600', color: text_color, bottom:30, fontSize: size/3}}>{drNum}</div>
      <img
        src={pin_src}
        onClick={onClick}
        style={{width:'60px', height:'auto', marginTop: '-70px'}}
      />
    </div>
  );
}

export default React.memo(DriverPin);