import React, { useState, useEffect } from 'react';
import DataGrid, { Column, Selection, FilterRow, Paging, Export, ColumnChooser, Pager, SearchPanel, GroupPanel, Grouping, MasterDetail } from 'devextreme-react/data-grid';
import { HeaderFilter, FilterPanel, FilterBuilderPopup } from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import HttpRequest from '../HttpService';
import { format, parseISO } from 'date-fns';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import 'devextreme/dist/css/dx.dark.css';
import { maxWidth } from '@mui/system';

const useStyles = makeStyles((theme) => ({
  // ... (keep your existing styles)
}));

const statusToString = new Map([
  [-1, "All"],
  [1, "New/Review"],
  [2, "Avail for Dispatch"],
  [3, "Driver Pending"],
  [4, "Driver Responsbility"],
  [8, "Delivered"],
  [16, "Verified"],
  [32, "Invoiced"],
]);

const locationToString = new Map([
  [-1, "All"],
  [1, "At Client"],
  [2, "On Driver"],
  [4, "In Sort"],
  [5, "Rotation Sort"],
  [8, "Delivered"],
]);

function TestPage(props) {
  const classes = useStyles();
  const [newTrips, setNewTrips] = useState([]);
  const [autoDispatch, setAutoDispatch] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      console.log("Fetching new trips...");
      HttpRequest.get_newTripsHistory(props.accessToken, props.companyID)
        .then(response => {
          console.log("New trips data:", response.data);
          const formattedTrips = formatTrips(response.data);
          setNewTrips(formattedTrips);
        })
        .catch(error => console.error("Error fetching new trips:", error));
  
      console.log("Fetching auto dispatch...");
      HttpRequest.get_autoDispatchHistory(props.accessToken, props.companyID)
        .then(response => {
          console.log("Auto dispatch data:", response.data);
          setAutoDispatch(response.data);
        })
        .catch(error => console.error("Error fetching auto dispatch:", error));
    };

    
  
    fetchData();
    const interval = setInterval(fetchData, 5000);
    return () => clearInterval(interval);
  }, [props.accessToken, props.companyID]);

  const formatTrips = (trips) => {
    console.log("Formatting trips:", trips);
    const dateFormat = "MM/dd/yyyy HH:mm";
    const formattedTrips = trips.map((trip, index) => ({
      ...trip,
      id: index,
      StatusCode: trip.Status,
      Status: statusToString.get(trip.Status) || 'Unknown',
      location: locationToString.get(trip.location) || 'Unknown',
      dtDCT_UTC: trip.dtDCT_UTC ? format(parseISO(trip.dtDCT_UTC), dateFormat) : null,
      dtCallIn_UTC: trip.dtCallIn_UTC ? format(parseISO(trip.dtCallIn_UTC), dateFormat) : null,
      dtDelivered_UTC: trip.dtDelivered_UTC ? format(parseISO(trip.dtDelivered_UTC), dateFormat) : null,
    }));
    console.log("Formatted trips:", formattedTrips);
    return formattedTrips;
  };

  const renderDueBy = (params) => {
    return (
      <Typography
        className={
          params.data["diff"] < 0 && params.data["StatusCode"] < 8
            ? classes.redRow
            : params.data["diff"] >= 0 &&
              params.data["diff"] <= 10 * 60 * 1000 &&
              params.data["StatusCode"] < 8
            ? classes.yellowRow
            : params.data["blueStar"] && params.data["StatusCode"] < 8
            ? classes.blueRow
            : params.data["diff"] > 10 * 60 * 1000 &&
              params.data["StatusCode"] < 8
            ? classes.greenRow
            : classes.evenRow
        }
      >
        {params.value}
      </Typography>
    );
  };

  const renderJobID = (params) => {
    return (
      <a
        style={{ color: "#13ACB1" }}
        onClick={() => {
          let inxURL = "/jobdetail/" + params.value;
          window.open(inxURL, "_blank");
        }}
      >
        {params.value}{" "}
      </a>
    );
  };

  const onExporting = (e) => {
    console.log("onExporting-" + e.format);

    if (e.format === "xlsx") {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Companies");
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "inx-orders.xlsx"
          );
        });
      });
      e.cancel = true;
    } 
    // else if (e.format === "pdf") {
    //   const doc = new jsPDF();
    //   exportDataGridToPdf({
    //     jsPDFDocument: doc,
    //     component: e.component,
    //   }).then(() => {
    //     doc.save("inx-orders.pdf");
    //   });
    // } 
    else if (e.format === "csv") {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Employees");
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
      }).then(function () {
        // https://github.com/exceljs/exceljs#writing-csv
        // https://github.com/exceljs/exceljs#reading-csv
        workbook.csv.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "inx-orders.csv"
          );
        });
      });
    }
  };
  
  useEffect(() => {
    const timer = setTimeout(() => {
      document.title = "Test";
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <h2>Last Trips Created</h2>
      <DataGrid
        dataSource={newTrips}
        showBorders={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        onExporting={onExporting}
      >
        <GroupPanel visible={true} />
        <Grouping autoExpandAll={false} />
        <Selection mode="multiple" selectAllMode="page" showCheckBoxesMode="always" />
        <FilterRow visible={true} />
        <FilterPanel visible={true} />
        <HeaderFilter visible={true} />
        <Paging defaultPageSize={50} />
        <Pager visible={true} allowedPageSizes="auto" showInfo={true} showPageSizeSelector={true} />
        <SearchPanel visible={true} width={240} placeholder="Search..." />
        
        <Column dataField="created" caption="Created" />
        <Column dataField="serv" caption="Service" />
        <Column dataField="callin" caption="Call In" />
        <Column dataField="DCT" caption="DCT" />
        <Column dataField="DShipper" caption="Shipper" />
        <Column dataField="DConsign" caption="Consignee" />
        <Column dataField="jobID" caption="Job ID" cellRender={renderJobID} />
        <Column dataField="zinvoice" caption="Invoice" />

        <Export enabled={true} allowExportSelectedData={true} />
      </DataGrid>

      <h2>Last Auto-Dispatch Decisions</h2>
      <DataGrid
        dataSource={autoDispatch}
        showBorders={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
      >
        <Column dataField="Service" caption="Service" />
        <Column dataField="shipper" caption="Shipper" />
        <Column dataField="consign" caption="Consignee" />
        <Column dataField="jobID" caption="Job ID" />
        <Column dataField="descr" caption="Description" />
        <Column dataField="displayLine" caption="Display Line" />
      </DataGrid>
    </div>
  );
}

export default TestPage;