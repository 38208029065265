import React from 'react';
import { Grid, ButtonGroup, Button, Typography } from '@material-ui/core';
import MapboxPanel from '../../components/MapboxPanel';
import StreetViewMap from '../../components/streetViewSideBySideMap';

const MapView = ({ 
  view, 
  setView, 
  viewAddress, 
  order, 
  addr, 
  setAddr, 
  photoArray, 
  handleGPSChange, 
  gpsData, 
  GPSUpdateButtonText, 
  setLocationUpdateDialog 
}) => {
  const handleViewChange = () => {
    setView(view === "map" ? "street" : "map");
  };

  const handleAddrChange = () => {
    setAddr(addr === "pickup" ? "delivery" : "pickup");
    setView("map");
  };

  const classes = {
    jobDetailLabel: {
      fontSize: 12,
      fontWeight: 500,
    },
  };

  return (
    <>
    <Grid item md={12} container direction="row" justifyContent="flex-start" alignItems="center" >
        <Typography noWrap variant="button" color="primary" style={classes.jobDetailLabel}>View</Typography>
        &ensp;
        <ButtonGroup size="small" variant="outlined" aria-label="outlined button group">
          <Button onClick={handleViewChange} color="primary" disabled={view === "map"}
            style={view === "map" ? { color: "#3BA264", borderColor: "#3BA264" } : {}}>
            {view === "map" && (<i className="fa fa-check" aria-hidden="true"></i>)}
            Map
          </Button>
          <Button onClick={handleViewChange} color="primary" disabled={view === "street"}
            style={view === "street" ? { color: "#3BA264", borderColor: "#3BA264" } : {}}>
            {view === "street" && (<i className="fa fa-check" aria-hidden="true"></i>)}
            Street
          </Button>
        </ButtonGroup>
        &emsp;
        <Typography noWrap variant="button" color="primary" style={classes.jobDetailLabel}>Type</Typography>
        &ensp;
        <ButtonGroup size="small" variant="outlined" aria-label="outlined button group">
          <Button onClick={handleAddrChange} color="primary" disabled={addr === "delivery" || view === "map"}
            style={addr === "delivery" && view !== "map" ? { color: "#3BA264", borderColor: "#3BA264" } : {}}>
            {addr === "delivery" && (<i className="fa fa-check" aria-hidden="true"></i>)}
            Delivery
          </Button>
          <Button onClick={handleAddrChange} color="primary" disabled={addr === "pickup" || view === "map"}
            style={addr === "pickup" && view !== "map" ? { color: "#3BA264", borderColor: "#3BA264" } : {}}>
            {addr === "pickup" && (<i className="fa fa-check" aria-hidden="true"></i>)}
            Pickup
          </Button>
        </ButtonGroup>
        &emsp;
        {view === "street" && (
          <Button size="small" color="primary" variant="contained" onClick={() => { setLocationUpdateDialog(true) }}>
            {GPSUpdateButtonText}
          </Button>
        )}
      </Grid>
      <div style={{ height: '600px', width: '100%', paddingTop:"12px" }}>
        {view === "street" ? (
          viewAddress.lat !== "" && viewAddress.long !== "" && (
            <StreetViewMap
              address={viewAddress.companyName}
              lat={viewAddress.lat}
              lng={viewAddress.long}
              type={addr}
              sLat={order.sLat}
              sLong={order.sLong}
              cLat={order.cLat}
              cLong={order.cLong}
              images={photoArray}
              orderStatus={order.Status}
              dtDelivered={order.dtDelivered}
              delLat={order.delLat}
              delLong={order.delLong}
              handleGPSChange={handleGPSChange}
            />
          )
        ) : (
          <MapboxPanel
            orderID={order.jobID}
            orderStatus={order.Status}
            dtDelivered={order.dtDelivered}
            delLat={order.delLat}
            delLong={order.delLong}
            gpsData={gpsData}
            images={photoArray}
          />
        )}
      </div>
      
    </>
  );
};

export default MapView;