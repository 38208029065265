import React from 'react';
import { Grid, Box, Menu, MenuItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import { memo } from 'react';




const DescriptionIconSection = memo(({
  isEdit,
  order,
  handleDescriptionIconClick,
  descriptionMenuAnchorEl,
  handleDescriptionMenuClose,
  handleDescriptionSelect,
  availableDescriptions = [],
  loadedIcons,
  iconErrors
}) => {

  const handleDescriptionMenuItemClick = (event, descriptionID, serviceSettingsID) => {
    event.preventDefault(); // Prevent event bubbling
    event.stopPropagation(); // Stop event propagation
    handleDescriptionSelect(descriptionID, serviceSettingsID);
    handleDescriptionMenuClose();
  };

  return (
    <Grid item xs={2}>
      <Tooltip title={isEdit ? "Click to change package type" : ""}>
        <Box
          onClick={handleDescriptionIconClick}
          sx={{
            position: 'relative',
            width: '60px',
            height: '60px',
            padding: '10px',
            cursor: isEdit ? 'pointer' : 'default',
            border: isEdit ? '2px solid #4e9182' : 'none',
            borderRadius: '8px',
            transition: 'all 0.3s ease',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            '&:hover': {
              borderColor: isEdit ? '#6eb5a5' : 'none',
              transform: isEdit ? 'scale(1.05)' : 'none'
            }
          }}
        >
          <img 
            src={order?.descriptionIconURL} 
            alt="Description Icon"
            style={{ 
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              borderRadius: '4px'
            }}
          />
        </Box>
      </Tooltip>

      <Menu
        anchorEl={descriptionMenuAnchorEl}
        keepMounted
        open={Boolean(descriptionMenuAnchorEl)}
        onClose={handleDescriptionMenuClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            maxHeight: '400px',
            width: '300px',
            backgroundColor: '#1e1e1e' // Dark background
          }
        }}
      >
        {availableDescriptions && availableDescriptions.length > 0 ? (
          availableDescriptions.map((description) => (
            <MenuItem 
              key={`${description.descriptionID}-${description.serviceSettingsID}`}
              onClick={(event) => handleDescriptionMenuItemClick(event, description.descriptionID, description.serviceSettingsID)}
              style={{ 
                height: 'auto',
                padding: '8px 16px',
                whiteSpace: 'normal',
                minHeight: '48px',
                '&:hover': {
                  backgroundColor: '#2e2e2e'
                }
              }}
            >
              <ListItemIcon style={{ 
                minWidth: '40px', 
                display: 'flex', 
                justifyContent: 'center',
                alignItems: 'flex-start',
                paddingTop: '4px'
              }}>
                {loadedIcons[description.descriptionID] ? (
                  <img 
                    src={loadedIcons[description.descriptionID]}
                    alt={description.iconTitleText}
                    style={{ 
                      width: '24px', 
                      height: '24px', 
                      objectFit: 'contain'
                    }}
                  />
                ) : (
                  <div style={{ 
                    width: '24px', 
                    height: '24px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#ccc',
                    borderRadius: '4px',
                    fontSize: '12px',
                    color: iconErrors[description.descriptionID] ? 'red' : '#666'
                  }}>
                    {iconErrors[description.descriptionID] ? '!' : '?'}
                  </div>
                )}
              </ListItemIcon>
              <ListItemText 
                primary={description.choiceText || description.iconTitleText}
                secondary={description.iconSubText}
                primaryTypographyProps={{
                  style: { 
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: '#ffffff', // Bright white for primary text
                    marginBottom: description.iconSubText ? '4px' : 0
                  }
                }}
                secondaryTypographyProps={{
                  style: {
                    fontSize: '12px',
                    color: '#a0a0a0', // Light gray for secondary text
                    whiteSpace: 'normal',
                    lineHeight: '1.2'
                  }
                }}
              />
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No descriptions available</MenuItem>
        )}
      </Menu>
    </Grid>
  );
});

export default DescriptionIconSection;