import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import TextInput from "../../../components/inputFields/TextInput";
import Tooltip from '@mui/material/Tooltip';
import CustomIconButton from "../../../components/inputFields/CustomIconButton.js";
import Typography from '@mui/material/Typography';
import "animate.css/animate.css";

const TagTextFieldsEditable = (props) => {
  const [textValue, setTextValue] = useState(props.value || ""); // Initialize with props.value

  const tipText = props.prompt || '';

  // Update textValue only when props.value changes
  useEffect(() => {
    if (props.value !== undefined && props.value !== textValue) {
      setTextValue(props.value);
    }
  }, [props.value]); // Only trigger on props.value changes

  const handleChange = (event) => {
    const value = event.target.value;
    if (props.maxLength === undefined || value.length <= props.maxLength) {
      setTextValue(value); // Update local state
      props.onChange({
        name: props.param,
        value: value,
      });
    }
  };

  const clearText = () => {
    setTextValue("");
    props.onChange({
      name: props.param,
      value: "",
    });
  };

  const createHelperText = () => {
    if (props.maxLength === undefined || textValue === undefined) {
      return '';
    }
    return `There are ${props.maxLength - (textValue.length || 0)} characters available to enter.`;
  };

  return (
    <Grid container direction="row" justifyContent="stretch" style={{ margin: "0", width: "100%", minWidth:'400px', marginTop:'-18px'}} spacing={0} sm={12}>
      <Grid item sm={10}>
        <Typography variant="caption" style={{ display: 'block' }}>
          {props.label}
        </Typography>
        <Tooltip title={tipText} placement="top" arrow>
          <TextInput
            id={props.param}
            type="text"
            hideLabel
            inputProps={{ maxLength: props.maxLength }}
            variant="outlined"
            size="large"
            color={props.color}
            value={textValue}
            placeholder={props.prompt}
            onChange={handleChange}
            helperText={createHelperText()}
            multiline={props.multiline}
            minRows={props.minRows}
          />
        </Tooltip>
      </Grid>
      <Grid item sm={2}>
        <CustomIconButton
          icon={props.icon || "ClearIcon"}
          tooltip={props.iconTooltip || "Clear"}
          onClick={clearText}
          color={props.color}
          style={{ height: '52px', marginTop: '20px'}}
        />
      </Grid>
    </Grid>
  );
};

export default TagTextFieldsEditable;
