import React, { useState, useEffect } from 'react';
import HttpRequest from "../../HttpService";
import AddIcon from "@mui/icons-material/AddComment";
import ShowAllIcon from "@mui/icons-material/AllOut";
import { Grid, FormGroup, Button,Tooltip,IconButton,ButtonGroup} from '@mui/material';
import TagContainer from './tags/TagContainer';
import TagAddTagDialog from './tags/TagAddTagDialog';
import ShowLessIcon from "@mui/icons-material/UnfoldLess";





export default function TagsView(props) {

    const [tagList, setTagList] = useState([]);
    const [availableTagsList, setAvailableTagsList] = useState([]);
    const [dialogIsOpen, setDialogIsOpen] = useState(false); 
    const [showAllTags, setShowAllTags] = useState(false);



    useEffect(() => {    
        fetchTripTags('TagEdit');
       // getTripAvailableTags();
    }, [props.jobId]);


    // const getTripAvailableTags = async (type) => {
    //     HttpRequest.getTripAvailableTags(props.accessToken,props.jobId).then(async (response) => {
    //        console.log('getTripAvailableTags',JSON.stringify(response.data)); 
    //        var tempTags =[];           
    //        await response.data.forEach((element) => {
    //             //console.log("getTripAvailableTags-element: ", element);   
    //             try{   
    //                const params = element.variableDefinition !== null ? JSON.parse(element.variableDefinition): null; 
    //                element.params = params;
    //                tempTags.push(element);
    //             }
    //             catch(e){
    //                 console.log(getTripAvailableTags + "-exception-" + e);
    //             }
    //         });  
    //         console.log('getTripAvailableTags-tags',JSON.stringify(tempTags))
    //         setAvailableTagsList(tempTags);            
    //     })
    // }

    const fetchTripTags = async (type) => {
          HttpRequest.getTripTags(props.accessToken,props.jobId,type).then(async (response) => {
            console.log('getTripTags',JSON.stringify(response.data));            
            var tempTags =[];
           
            await response.data.forEach((element) => {
                //console.log("fetchQuoteTags-element: ", element);   
                try{   
                const restrictions = element.variableDefinition !== null ? JSON.parse(element.variableDefinition): null;          
                //console.log("restrictions: ", JSON.stringify(restrictions));
                element.checked = element.active;
                if(restrictions!== undefined && restrictions !== null) {    
                  let resList = [];
                  restrictions.forEach((restriction) =>{      
                    if (restriction.type === "dropdown" ) {  
                      restriction.choices =  restriction.values.map((item) => {
                        return {label: item};
                      });
                      restriction.value = restriction.default;          
                    }
                    else{
                      if(restriction.default && restriction.type==="checkbox")
                        restriction.value = parseInt(restriction.default);
                      else if(restriction.default)
                        restriction.value =restriction.default;  
                      else
                        restriction.value= restriction.type==='integer'? 0: restriction.type==='decimal'? 0.0: restriction.type==='datetime'? new Date():
                        restriction.type==='date'? new Date() : restriction.type==='time'? new Date() : restriction.type==='checkbox'? 1:'';      
                    }
                    restriction.prompt = (!restriction.prompt) ? element.clientLabel :restriction.prompt; 
                    if(restriction.newline === undefined || restriction.newline ==='0'){    
                      restriction.newline=false;
                      resList.push(restriction);
                    }  
                    else{
                      resList.push({param:"newLine", type:"newline", newline:true});
                      restriction.newline=false;
                      resList.push(restriction)
                    }      
                  })
                  element.restrictions = resList;      
                }
                else{
                  let resList = [];
                  resList.push({type :'none'});
                  element.restrictions = resList;  
                }    
                tempTags.push(element);
              }
              catch(e){
                console.log(element.variableDefinition + "-exception-" + e);
              }
            });
            setTagList(tempTags);    
            console.log("fetchQuoteTags-tagList: ", JSON.stringify(tempTags));
        });  
    };


    const handleSave = (id) => () => {
      console.log("Tag handleSave-id: "+ id);     
      tagList.forEach((element) => {         
          if(element.masterTagID === id){
            console.log("handle save- element: ", JSON.stringify(element));
            let postTag ={}; 
            postTag.checked = element.checked? 1:0;
            element.restrictions.forEach((restriction) =>{             
                postTag[restriction.param]=restriction.value;
            });            
            const content = {
              masterTagID: id,
              varJSON:  JSON.stringify(postTag)
            }
            console.log("updateTripTag content " , content);
            HttpRequest.updateTripTag(props.accessToken,props.jobId,content).then((body) =>{
              console.log("updateTripTag " + JSON.stringify(body.data));
              props.loadTripTags();
             
            }); 
           
          }
      });       
    };


    const handleChange = (id) => (event) => {
        console.log("Tag handleChange-id: "+ id);
        //props.onChange({"target":event.name, "value":event.value});  
        tagList.forEach((element) => {         
            if(element.masterTagID === id){
              console.log("handleChange-before- element: ", JSON.stringify(element));
              element.restrictions.forEach((restriction) =>{
                if(restriction.param === event.name)
                   restriction.value=  event.value;
              });
              console.log("handleChange-element after: ", JSON.stringify(element));
            }
        });
        setTagList(tagList);   
    };

    const handlerCheckedChange  = (id) =>(val) =>{  
        console.log("Tag handlerCheckedChange-id: "+ id);
        tagList.forEach((element) => {      
          if(element.masterTagID === id){
            element.checked = val; 
            element.active = val; 
          }    
        });
        setTagList(tagList);
    }    

    const handleShowAllToggle = () => {
      if (showAllTags) {
          fetchTripTags("TagEdit");
      } else {
          fetchTripTags("ShowAll");
      }
      setShowAllTags(!showAllTags);
    };

    const doAddTag = () =>{     
      HttpRequest.getTripAvailableTags(props.accessToken,props.jobId).then(async (response) => {
        console.log('getTripAvailableTags',JSON.stringify(response.data)); 
        var tempTags =[];           
        await response.data.forEach((element) => {
             //console.log("getTripAvailableTags-element: ", element);   
             try{   
                const params = element.variableDefinition !== null ? JSON.parse(element.variableDefinition): null; 
                element.params = params;
                tempTags.push(element);
             }
             catch(e){
                 console.log("getTripAvailableTags-exception-" + e);
             }
         });  
         console.log('getTripAvailableTags-tags',JSON.stringify(tempTags))
         setAvailableTagsList(tempTags);    
         setDialogIsOpen(true);        
     })
    }

    return (       
        <div>
            <FormGroup>             
               {tagList && tagList.length>0 && tagList.map((element) => 
                 (element.restrictions !== undefined && element.restrictions !== null)?            
                    <TagContainer
                      key={element.masterTagID}                
                      label={element.clientLabel}
                      variant="contained"                
                      required={element.alwaysOn || element.defaultOn}  
                      onChange={handleChange(element.masterTagID)}
                      onCheckedChange={handlerCheckedChange(element.masterTagID)}
                      onSave={handleSave(element.masterTagID)}
                      info={element}
                      restrictions={element.restrictions}
                      element={element}
                />:<></>
              )}   
            </FormGroup>
            <div style={{ paddingBottom: "10px" }} />

            <Grid item container xs={12}>
                <Grid item xs={1}></Grid>
                <Grid itme xs={3}>
                    <ButtonGroup  size="small" variant="outlined" aria-label="outlined button group">
                        <Button onClick={()=>{doAddTag()}} color="primary" style={{ color: "#007E82", borderColor: "#007E82" }}>
                           <AddIcon style={{ verticalAlign: "middle", transform: "scale(0.8)" }}/>
                            Add Tag
                        </Button>
                        <Button onClick={handleShowAllToggle} color="primary" style={{ color: "#007E82", borderColor: "#007E82" }}>
                          {showAllTags ? 
                            <ShowLessIcon style={{ verticalAlign: "middle", transform: "scale(0.8)" }}/> :
                            <ShowAllIcon style={{ verticalAlign: "middle", transform: "scale(0.8)" }}/>
                          }
                            {showAllTags ? "Show Less" : "Show All"}
                        </Button>
                    </ButtonGroup>
                </Grid>               
            </Grid> 
            <TagAddTagDialog  accessToken={props.accessToken} jobId={props.jobId} tagsList={availableTagsList}  open={dialogIsOpen} 
                handleDialogClose={() => {
                  setDialogIsOpen(false); 
                  fetchTripTags(showAllTags ? "ShowAll" : "TagEdit");
              }}
            />          
        </div>       
    );
}