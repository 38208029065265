import React from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CameraButton from './cameraButton';
import Gallery from './gallery';
import StreetViewMap from '../../components/streetViewSideBySideMap';

const PicturesTab = ({ 
  viewAddress, 
  addr, 
  order, 
  photoArray, 
  isStreetViewExpanded, 
  setIsStreetViewExpanded, 
  handleGPSChange, 
  toggleGallery, 
  galleryVisible, 
  accessToken, 
  jobId, 
  setIsUploadFile, 
  classes 
}) => {
  const handleAccordionChange = (event, isExpanded) => {
    setIsStreetViewExpanded(isExpanded);
  };

  return (
    <>
        <div className="containerGallery" style={classes.gallery}>
          <Gallery accessToken={accessToken} jobId={jobId} photoArray={photoArray} setUploadFileFlag={setIsUploadFile} />
        </div>
      <Accordion 
        expanded={isStreetViewExpanded}
        onChange={handleAccordionChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="street-view-content"
          id="street-view-header"
        >
          <Typography>Street View</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {isStreetViewExpanded && viewAddress.lat !== "" && viewAddress.long !== "" ? (
            <div style={{ height: '600px', width: '100%' }}>
              <StreetViewMap
                address={viewAddress.companyName}
                lat={viewAddress.lat}
                lng={viewAddress.long}
                type={addr}
                sLat={order.sLat}
                sLong={order.sLong}
                cLat={order.cLat}
                cLong={order.cLong}
                images={photoArray}
                orderStatus={order.Status}
                dtDelivered={order.dtDelivered}
                delLat={order.delLat}
                delLong={order.delLong}
                handleGPSChange={handleGPSChange}
              />
            </div>
          ) : (
            <Typography>
              {isStreetViewExpanded 
                ? "No street view available for this address."
                : "Expand to load street view."}
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default PicturesTab;