import React, { useState, useEffect } from "react";
import { Typography, Button, ButtonGroup } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import MinIcon from "@material-ui/icons/Remove";
import NoticeEmitter from "../../NoticeUtil";
import httpRequest from "../../HttpService";
import Moment from "moment";
import CalculateIcon from '@mui/icons-material/Calculate';
import DataGrid, {Column,Selection, FilterRow, Paging,Export,ColumnChooser,Pager,SearchPanel} from 'devextreme-react/data-grid';
import "devextreme/dist/css/dx.dark.css";

const TripDriverList = (props) => {
  const [tripDriverList, setTripDriverList] = useState([]);
  const [tableHeight, setTableHieght] = useState(200);
  const [selectedDriver, setSelectedDriver] = useState();

  useEffect(() => {
    getTripDrivers();
  }, []);

  NoticeEmitter.addListener("refreshJobDetail", (data) => {
    console.log("TripDriverList====NoticeEmitter refreshJobDetail========" +   data);
    setTimeout(function () {
      getTripDrivers();
    }, 500);
  });

  NoticeEmitter.addListener("refreshRating", (data) => {
    console.log("TripDriverList====NoticeEmitter refreshRating==========" +   data);
    setTimeout(function () {
      getTripDrivers();
    }, 500);
  });

  const getTripDrivers = () => {
    var driverList = [];
    httpRequest.getTripDrivers(props.accessToken, props.jobId).then((body) => {
      console.log("getTripDrivers-" + JSON.stringify(body.data));
      if (body.data.length !== 0) {
        body.data.forEach((value) => {
          value.datestampUTC = Moment(value.datestampUTC).format(
            "YY-MM-DD HH:mm"
          );
          value.id = driverList.length;
          value.specialPaid = value.specialPaid?.toFixed(2);
          value.paidOn = value.paidOn?.toFixed(2);
          value.commPaid = value.commPaid?.toFixed(2);
          value.commRate = value.commRate?.toFixed(2);
          value.fuelPaid = value.fuelPaid?.toFixed(2);
          value.TotalPaid = value.TotalPaid?.toFixed(2);
          driverList.push(value);
        });
        setTripDriverList(driverList);
        setTableHieght(100 + 36 * driverList.length);  // Changed from 100 to 180
        setSelectedDriver(driverList[0]);
      }
    });
  };

  const RecalculateAndRefresh = async () => {
    try {
        // First, wait for the recalculation to complete
        const recalcResponse = await httpRequest.doTripReCalcDriverSplit(props.accessToken, props.jobId);
        console.log("Driver split recalculation complete:", recalcResponse);
        
        // Short delay to let backend catch up
        await new Promise(resolve => setTimeout(resolve, 500));
        
        // Emit notification to trigger full refresh
        NoticeEmitter.emit('refreshJobDetail', 'recalculate');
        
        // Also refresh the local driver data
        await getTripDrivers();
        
    } catch (error) {
        console.error("Error in RecalculateAndRefresh:", error);
    }
}

  const renderSharedCountDEV = params => {   
    return(
      <Typography variant="body1">     
      <strong> {params.value} </strong> &emsp;
      <AddIcon
        onClick={(event) => {
          console.log("renderSharedCountDEV", params);
          var content = {
            jobID: parseInt(props.jobId, 10),
            drNum: params.data.drNum,
            shareCount: params.data.shareCount + 1,
          };
          console.log(" addShareCount: " + JSON.stringify(content));
          httpRequest.updateDriverShareCount(props.accessToken, content).then((body) => {
              if (body.data) {
                console.log("updateDriverShareCount- " + JSON.stringify(body.data));
              }
              setTimeout(function () {getTripDrivers();}, 1000);
            });
        }}
      />
      <MinIcon
        onClick={(event) => {
          if (params.data.shareCount === 0) return;
          var content = {
            jobID: parseInt(props.jobId, 10),
            drNum: params.data.drNum,
            shareCount: params.data.shareCount - 1,
          };

          console.log(" handleMinClick: " + JSON.stringify(content));
          httpRequest.updateDriverShareCount(props.accessToken, content).then((body) => {
              if (body.data) {
                console.log("updateDriverShareCount- " + JSON.stringify(body.data));
              }
              setTimeout(function () {getTripDrivers();}, 1000);
            });
        }}
      />
    </Typography>
    )
  }  

  const renderDrNum = (params) => {
    return (
      <a style={{ color: "#13ACB1", fontWeight: "600" }}>{params.data.drNum} </a>
    );
  };

  const onSelectionChanged = ({selectedRowsData}) =>{
    const data = selectedRowsData[0];
    console.log("onSelectionChanged --" + JSON.stringify(selectedRowsData));
    setSelectedDriver(selectedRowsData);
  }


  return (
    <div className='darkDataGrid' style={{ height: tableHeight, width: '100%' }}>    
        <ButtonGroup size="small" variant="outlined" aria-label="outlined button group" style={{ marginBottom:10 }}>
            <Button 
                onClick={RecalculateAndRefresh} 
                color="primary" 
                style={{ color: "#007E82", borderColor: "#007E82" }}
            >
                <CalculateIcon style={{ verticalAlign: "middle", transform: "scale(0.8)" }}/>
                Recalculate Splits and Refresh
            </Button>
        </ButtonGroup> 

        <DataGrid
            dataSource={tripDriverList}
            showBorders={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            keyExpr="id"
            rowAlternationEnabled={true}  
            onSelectionChanged={onSelectionChanged}         
        >                    
            <Selection mode="single"/>

            <Column dataField="drNum" caption="Driver#" alignment="center" width={120} cellRender={renderDrNum}/>
            <Column dataField="specialPaid" caption="specialPaidOn" alignment="center" width={120}/>                     
            <Column dataField="paidOn" caption="paidOn" width={100} alignment="center" /> 
            <Column dataField="commRate" caption="commRate"  alignment="center" width={120}/>
            <Column dataField="commPaid" caption="commPaid" alignment="center" width={120}/>  
            <Column dataField="fuelPaid" caption="fuelPaid"  alignment="center" width={120}/>
            <Column dataField="TotalPaid" caption="Total Paid" alignment="center" width={140}/>  
            <Column dataField="shareCount" caption="share Count" alignment="center"  width={140} cellRender={renderSharedCountDEV}/>
            <Column dataField="datestampUTC" caption="date/time" alignment="center" width={200}/> 
        </DataGrid> 
    </div>
);
};

export default TripDriverList;
