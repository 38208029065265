import React, { useState, useEffect } from 'react';
import EditIcon from "@mui/icons-material/Edit";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ClearIcon from "@mui/icons-material/Clear";
import { AppBar, Toolbar } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

export default function StatusBar(props) {

    const classes = {
        root: {
          backgroundColor: "transparent",
        },
        jobDetailLabel: {
          fontSize: 12,
          fontWeight: 500,
        },
      }    
    // const [isEdit,setIsEdit] = useState(props.isEdit);
    useEffect(() => {      
       
    }, []);

    return (
        <AppBar  position="static"  elevation={0}  style={{backgroundClor: "#0D191E", overflow: "auto", paddingLeft: "38px",marginTop: "1px"}}>
        <Toolbar  className={"subheaderJobDetail"} disableGutters={true} style={{ alignItems: "stretch", padding: "10px" }}>
          {props.order && (
            <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={0} style={{ width: "100%", backgroundColor: "#0D191E" }}>
              <Grid  container item  xs={12} sm={2} md={2} lg={1}  style={{display: "flex", alignItems: "center", justifyContent: "center",}}>
                <div style={{ padding: "10px" }}>
                  <img src={props.companyLogo} alt={props.order?.ShipperName} style={{maxWidth: "180px", width: "100%", height: "auto", display: "block", borderRadius: "8px",}}/>
                </div>
              </Grid>
              <Grid  container spacing={0} item xs={11}
                style={{
                  borderRadius: "6px",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  boxShadow: "0px 0px 10px #000000",
                  borderColor:
                    props.order.exception !== null && props.order.exception && //red
                    props.order.Status ? "#DE3562" : //purple
                    props.order.Status < 8 ? "#4331A4" : // green
                    props.order.Status >= 8 && props.order.Status < 32 ? "#007E82" : // orange
                    props.order.Status === 32 ? "#F78133" : // gray
                        "#485054",
                  // backgroundColor: "transparent",
                  backgroundColor:
                    props.order.exception !== null && props.order.exception && //red
                    props.order.Status ? "#DE3562" : //purple
                    props.order.Status < 8 ? "#4331A4" : // green
                    props.order.Status >= 8 && props.order.Status < 32 ? "#007E82" : // orange
                    props.order.Status === 32 ? "#F78133" : // gray
                      "#485054",
                  height:'80%',
                  alignContent:'space-around',
                }}
              >
                <Grid item sm={6} lg={1} style={classes.toolbarGridItem}>
                  <Typography variant="h5" color="textSecondary"style={{paddingLeft:'20px'}}> Job Id </Typography>
                  <Typography variant="h6" color="textPrimary" style={{paddingLeft:'20px'}}> {props.jobId} </Typography>
                  {/* jobId */}
                </Grid>

                <Grid item sm={6} lg={1} style={classes.toolbarGridItem}>
                  <Typography variant="h5" color="textSecondary"> Driver # </Typography>
                  <Typography variant="h6" color="textPrimary"> {props.order?.last_driv} </Typography>
                  {/* driver number */}
                </Grid>
                <Grid item sm={6} lg={3} style={classes.toolbarGridItem}>
                  <Typography variant="h5" color="textSecondary">Ready At</Typography>
                  <Typography variant="h6" color="textPrimary">{props.order?.dtCallInDisplayString}</Typography>
                  {/* ready at */}
                </Grid>
                <Grid item sm={6} lg={3} style={classes.toolbarGridItem}>
                  <Typography variant="h5" color="textSecondary">DCT</Typography>
                  <Typography variant="h6" color="textPrimary">{props.order?.dtDCTDisplayString}</Typography>
                  {/* dct */}
                </Grid>
                <Grid item sm={6} lg={3} style={classes.toolbarGridItem}>
                  <Typography variant="h5" color="textSecondary">Account</Typography>
                  <Typography variant="h6" color="textPrimary">{props.order?.ACCOUNT + " - " + props.order?.ShipperName}</Typography>
                  {/* account */}
                </Grid>
                <Grid item sm={6} lg={1} style={classes.toolbarGridItem}>
                  <div   style={{display: "flex",justifyContent: "end", alignItems: "center",flexWrap: "wrap",}}>
                    {!props.isEdit && (<IconButton  size="medium"  tooltip="Edit order"   onClick={() => {props.handlerEditClick()}}>
                        <EditIcon></EditIcon>
                      </IconButton>
                    )}
                    {props.isEdit && (<IconButton size="medium" tooltip="Save"  onClick={() =>{ props.updateOrder()}}>
                        <DoneAllIcon></DoneAllIcon>
                      </IconButton>
                    )}
                    {props.isEdit && (<IconButton size="medium"  tooltip="Cancel" onClick={() =>{props.setIsEdit(false)}}>
                        <ClearIcon></ClearIcon>
                      </IconButton>
                    )}
                  </div>
                  {/* button */}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Toolbar>
      </AppBar>       
    );
  };

  