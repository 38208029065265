// Import necessary modules and components
import React, { useEffect, useState } from 'react';
// Import Google Maps components
import { GoogleMap, LoadScript, OverlayView, Polyline } from '@react-google-maps/api';
// Import custom pin components
import DriverPin from './DriverPin';
import TripPin from './TripPin';
import ExceptionPin from './ExceptionPin';
// Import utility for event handling
import NoticeEmitter from "../../NoticeUtil";

// Define the AllMapPanel component
const AllMapPanel = (props) => {
  // State variables
  const [mapPoints, setMapPoints] = useState(props.mapPoints);
  const [selectedDriver, setSelectedDriver] = useState("000");
  const [exceptionMapPoints, setExceptionMapPoints] = useState([]); // Drivers with exceptions
  const [driverMapPoints, setDriverMapPoints] = useState([]); // Driver locations
  const [tripMapPoints, setTripMapPoints] = useState([]); // All trip points
  const [pickTripMapPoints, setPickTripMapPoints] = useState([]); // Pickup points
  const [deliveryTripMapPoints, setDeliveryTripMapPoints] = useState([]); // Delivery points
  const [tripsList, setTripsList] = useState(props.tripList);

  const [tripPolylines, setTripPolylines] = useState([]); // Polylines between pickups and deliveries

  // State variables for handling related trip pins on hover
  const [showRelatedTripPIN, setShowRelatedTripPIN] = useState(false);
  const [relatedTripPIN, setRelatedTripPIN] = useState(null);
  const [relatedOrgTripPIN, setRelatedOrgTripPIN] = useState(null);
  const [showOrgRelatedPIN, setShowOrgRelatedPIN] = useState(false); // Corrected setter name
  const [relatedTripPINColor, setRelatedTripPINColor] = useState('');

  // Reference to the Google Map instance
  const mapRef = React.useRef();

  // Callback when the map is loaded
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
    // Additional configurations can be added here
  }, []);

  // useEffect hook to process map points when props change
  useEffect(() => {
    // Initialize arrays to hold different types of points
    const drvPointsList = []; // Driver points
    const tripPointsList = []; // Trip points
    const exceptionPointsList = []; // Exception points
    const deliveryTripPointsList = []; // Delivery points
    const pickTripPointsList = []; // Pickup points

    // Update mapPoints from props
    setMapPoints(props.mapPoints);

    // Process each map point
    props.mapPoints.forEach((value) => {
      if (value.mapImage === "Car") {
        // It's a driver point
        if (value.exception !== null && value.exception === true) {
          exceptionPointsList.push(value); // Driver with an exception
        } else {
          drvPointsList.push(value); // Regular driver
        }
      } else {
        // It's a trip point
        if (props.tripList.length > 0) {
          var trip = props.tripList.find(function (obj) { return obj.jobID === value.jobID; });
          if (trip !== undefined) {
            tripPointsList.push(value);
            // Determine if the point is a pickup or delivery based on mapLabel
            if (['D', 'DD', 'SD', 'RD', 'DN'].includes(value.mapLabel.toUpperCase())) {
              deliveryTripPointsList.push(value);
            } else {
              pickTripPointsList.push(value);
            }
          }
        }
      }
    });

    // Update state with processed points
    setDriverMapPoints(drvPointsList);
    setTripMapPoints(tripPointsList);
    setExceptionMapPoints(exceptionPointsList);
    setPickTripMapPoints(pickTripPointsList);
    setDeliveryTripMapPoints(deliveryTripPointsList);

    // Create a mapping of jobID to pickup and delivery points
    const tripPointsMap = {};

    tripPointsList.forEach((point) => {
      const jobID = point.jobID;
      const mapLabel = point.mapLabel.toUpperCase();
      const lat = point.lat;
      const lng = point.long;

      if (!tripPointsMap[jobID]) {
        tripPointsMap[jobID] = {};
      }

      // Assign pickup and delivery coordinates based on the mapLabel
      if (mapLabel === 'P' || mapLabel === 'PP') {
        tripPointsMap[jobID].pickup = { lat, lng };
      } else if (['D', 'DD', 'SD', 'RD', 'DN'].includes(mapLabel)) {
        tripPointsMap[jobID].delivery = { lat, lng };
      }
    });

    // Create polylines between pickups and deliveries
    const polylines = [];

    Object.keys(tripPointsMap).forEach((jobID) => {
      const trip = tripPointsMap[jobID];
      if (trip.pickup && trip.delivery) {
        polylines.push({
          path: [trip.pickup, trip.delivery],
          options: {
            strokeColor: '#FF0000', // Line color
            strokeOpacity: 1.0,     // Line opacity
            strokeWeight: 2,        // Line thickness
          },
          jobID: jobID, // Store jobID for reference
        });
      }
    });

    // Update state with the new polylines
    setTripPolylines(polylines);
  }, [props.mapPoints, props.tripList, relatedTripPIN, showRelatedTripPIN, showOrgRelatedPIN]);

  // Handle click on driver icons
  function handleIconClick(drNum, json) {
    setSelectedDriver(drNum);
    json = JSON.parse(json);
    props.handlerDriverPinClick(drNum);
  }

  // Handle click on trip pins
  function handleTripClick(drNum, jobID, json) {
    let inxURL = "/jobdetail/" + jobID;
    window.open(inxURL, "_blank");
  }

  // Handle mouse over on trip pins to show related pins
  function handleMouseOver(pin) {
    if (relatedTripPIN !== null) return;
    var target;
    switch (pin.mapLabel.toUpperCase()) {
      case "D":
        target = "P";
        break;
      case "DD":
        target = "PP";
        break;
      case "P":
        target = "D";
        break;
      case "PP":
      default:
        target = "DD";
        break;
    }
    mapPoints.map(item => {
      if (item.jobID === pin.jobID && item.mapLabel.toUpperCase() === target) {
        setRelatedTripPINColor(item.mapColor);
        setRelatedOrgTripPIN(item);

        item.mapColor = 'light green';
        setRelatedTripPIN(item);
        localStorage.setItem('relatedPIN', JSON.stringify(item));

        setShowRelatedTripPIN(true);
        setShowOrgRelatedPIN(false); // Corrected setter usage

        let cnt = 0;
        let timerId = setInterval(() => {
          cnt++;
          if (cnt === 1) {
            setShowOrgRelatedPIN(false);
            setShowRelatedTripPIN(false);
            NoticeEmitter.emit('freshAllDriverMap', '');
          }
          if (cnt === 2) {
            setShowRelatedTripPIN(true);
            setShowOrgRelatedPIN(false);
          }
          if (cnt === 3) {
            clearInterval(timerId);
            setRelatedTripPIN(null);
            setRelatedOrgTripPIN(null);
            setShowOrgRelatedPIN(false);
            setShowRelatedTripPIN(false);
            NoticeEmitter.emit('freshAllDriverMap', '');
          }
        }, 600);
      }
    });
  }

  // Render the related trip pin when hovering
  function renderRelatedTripPin() {
    return (
      relatedTripPIN ?
        <OverlayView
          position={{ lat: relatedTripPIN.lat, lng: relatedTripPIN.long }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={(width, height) => ({
            x: -(width / 2),
            y: -height,
          })}
          
        >
          <TripPin
            onClick={() => handleTripClick(relatedTripPIN.drNum, relatedTripPIN.jobID, relatedTripPIN.json)}
            onMouseOver={() => { }}
            pin={relatedTripPIN}
            json={relatedTripPIN.json}
          />
        </OverlayView>
        : null
    );
  }

  // Render the original related trip pin
  function renderRelatedOrgTripPin() {
    return (
      <OverlayView
        position={{ lat: relatedOrgTripPIN.lat, lng: relatedOrgTripPIN.long }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={(width, height) => ({
          x: -(width / 2),
          y: -height,
        })}
        
      >
        <TripPin
          onClick={() => handleTripClick(relatedOrgTripPIN.drNum, relatedOrgTripPIN.jobID, relatedOrgTripPIN.json)}
          onMouseOver={() => handleMouseOver(relatedOrgTripPIN)}
          pin={relatedOrgTripPIN}
          json={relatedOrgTripPIN.json}
          
        />
      </OverlayView>
    );
  }

  const mapCenterRef = React.useRef(null);

    useEffect(() => {
      if (props.mapPoints && props.mapPoints.length > 0 && !mapCenterRef.current) {
        mapCenterRef.current = { lat: props.mapPoints[0].lat, lng: props.mapPoints[0].long };
      }
    }, [props.mapPoints]);


  // Render the Google Map with all markers and polylines
  return (mapPoints && mapPoints.length > 0) ? (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
      <GoogleMap
        ref={mapRef}
        onLoad={onMapLoad}
        mapContainerStyle={{ width: "100%", height: "80vh" }}
        center={mapCenterRef.current}
        zoom={10}
        options={{
          fullscreenControl: true,
          mapTypeId: 'roadmap',
        }}
      >
        {/* Render driver markers */}
        {props.IsShowDrivers && driverMapPoints.map((value) =>
          <OverlayView
            key={`driver-${value.drNum}`}
            position={{ lat: value.lat, lng: value.long }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={(width, height) => ({
              x: -(width / 2),
              y: -height,
            })}
            
          >
            <DriverPin
              onMouseOver={() => props.hoverHandler(value.drNum, value.json)}
              onClick={() => handleIconClick(value.drNum, value.json)}
              drNum={value.drNum}
              pin={value}
            />
          </OverlayView>
        )}
        {/* Render exception driver markers */}
        {props.IsShowDrivers && exceptionMapPoints.map((value) =>
          <OverlayView
            key={`exception-${value.drNum}`}
            position={{ lat: value.lat, lng: value.long }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={(width, height) => ({
              x: -(width / 2),
              y: -height,
            })}
            
          >
            <>
              <DriverPin
                onMouseOver={() => props.hoverHandler(value.drNum, value.json)}
                onClick={() => handleIconClick(value.drNum, value.json)}
                drNum={value.drNum}
                pin={value}
              />
              <ExceptionPin />
            </>
          </OverlayView>
        )}
        {/* Render pickup trip pins */}
        {props.IsShowPickup && pickTripMapPoints.map((value) =>
          <OverlayView
            key={`pickup-${value.jobID}`}
            position={{ lat: value.lat, lng: value.long }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={(width, height) => ({
              x: -(width / 2),
              y: -height,
            })}
            
          >
            <TripPin
              onClick={() => handleTripClick(value.drNum, value.jobID, value.json)}
              onMouseOver={() => handleMouseOver(value)}
              pin={value}
              json={value.json}
            />
          </OverlayView>
        )}
        {/* Render delivery trip pins */}
        {props.IsShowDelivery && deliveryTripMapPoints.map((value) =>
          <OverlayView
            key={`delivery-${value.jobID}`}
            position={{ lat: value.lat, lng: value.long }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={(width, height) => ({
              x: -(width / 2),
              y: -height,
            })}
            
          >
            <TripPin
              onClick={() => handleTripClick(value.drNum, value.jobID, value.json)}
              onMouseOver={() => handleMouseOver(value)}
              pin={value}
              json={value.json}

            />
          </OverlayView>
        )}
        {/* Render related trip pins if any */}
        {showRelatedTripPIN ?
          renderRelatedTripPin() : relatedOrgTripPIN !== null ? renderRelatedOrgTripPin() : null
        }
        {/* Render polylines between pickups and deliveries */}
        {props.IsShowLines && tripPolylines.map((polyline, index) => (
          <Polyline
            key={`polyline-${polyline.jobID || index}`}
            path={polyline.path}
            options={polyline.options}
          />
        ))}
      </GoogleMap>
    </LoadScript>
  ) : null;
};

// Export the component
export default AllMapPanel;
