import React, { useState, useEffect } from 'react';
import Copyright from '../Copyright';
import './accountList.css';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import { Container} from '@material-ui/core';
import httpRequest from '../HttpService';
import Box from '@material-ui/core/Box';
import AccountCircle from '@material-ui/icons/AccountCircle';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import EditAccountForm from './EditAccountForm';
//import 'devextreme/dist/css/dx.light.css';
import DevDataGrid, {Column,Selection, FilterRow, Paging,Export,ColumnChooser,Pager,SearchPanel} from 'devextreme-react/data-grid';
import { useHistory  } from 'react-router-dom';

export default function AccountList(props){  
    const history = useHistory();       
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [selectedAccountDetail, setSelectedAccountDetail] = useState(null);
    const [rows, setRows] = useState([]);
    const [editAccountFormOpen, setEditAccountFormOpen] = useState(false);
    const [IsUpdatedAccount, setIsUpdatedAccount] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
          document.title = "Account List";
        }, 100);
        return () => clearTimeout(timer);
      }, []);

    // useEffect(() => {
    //     let token =  localStorage.getItem('access_token');
    //     if(token === undefined && token === null)
    //        window.location.href='/';  
           
    //     if(rows.length === 0){    
    //         let newRows = props.accountList.map((account, index) => {
    //             account.id = index;
    //             account.open = account.Hours.substr(0,5);
    //             account.close = account.Hours.substr(account.Hours.indexOf('-')+2,5);
    //             return account;
    //         });           
    //         setRows(newRows);
    //     }
    // });

    useEffect(() => {

        let token =  localStorage.getItem('access_token');
        if(token === undefined && token === null)
           window.location.href='/';  

        let newRows = props.accountList.map((account, index) => {
            account.id = index;
            account.open = account.Hours.substr(0,5);
            account.close = account.Hours.substr(account.Hours.indexOf('-')+2,5);
            return account;
        });           
        setRows(newRows);
 
    }, [props.accountList]);




    const switchAccount = (account) => {
        console.log("switchAccount-" + JSON.stringify(account));  
        if(account === null ) return;
        
        httpRequest.getAccountWebToken(props.accessToken, account).then(async function(body){      
            console.log("switchAccount getAccountWebToken-" + JSON.stringify(body.data));  
            try{    
                let webURL =  body.data[0].entryURL;
                //webURL = webURL.replace('ship','webx');  
                //webURL = webURL.replace('/CreateOrder','');            
                //console.log("getAccountWebToken webURL-" + webURL);  
                localStorage.setItem('currentAccount',account); 

                if(webURL.startsWith("http://"))
                    webURL = webURL.replace("http://", "https://");
                localStorage.setItem('web5URL',webURL); 
                history.push(`/web5`);
            }
            catch(e){
                console.log("getAccountWebToken exception:" + e);
            }
        });
        
    }
   
    const onSelectionChanged = ({ selectedRowsData }) => {
        //const data = selectedRowsData[0];
        console.log(JSON.stringify(selectedRowsData[0]));
        setSelectedAccount(selectedRowsData[0].account);  
        setSelectedAccountDetail(selectedRowsData[0]);   
    }

    const onRowDblClick =(e) =>{   
      switchAccount(selectedAccount);
    }

    const renderAccount = (params) => {
        if(params.value){        
            return (      
              <span className='dataVal_account'>
                {params.value}
              </span>
           );
        }
    };

    const renderBrand = (params) => {
        if(params.value){        
            return (      
              <span className='dataVal_brand'>
                {params.value}
              </span>
           );
        }
    };

    const renderShipper = (params) => {
        if(params.value){        
            return (      
              <span className='dataVal_shipper'>
                {params.value}
              </span>
           );
        }
    };

    const renderButtons = (params) => {       
            return (      
                <Button
                  color = "primary"
                  variant = "contained" 
                  style={{marginLeft:'auto', marginTop:'3px'}}               
                  onClick = {() => {
                    //console.log(params);  
                    setIsUpdatedAccount(false);
                    setSelectedAccountDetail(params.data);                  
                    setEditAccountFormOpen(true)}}>
                   Edit
                </Button>
           );
    };

    return (
        <div className='container_account-list'>       
            <EditAccountForm open={editAccountFormOpen} setOpen={setEditAccountFormOpen} account={selectedAccountDetail} 
               setIsUpdatedAccount={setIsUpdatedAccount} setEditAccount={setEditAccountFormOpen} accessToken={props.accessToken}/>
            <AppBar className='app_bar' position='static'>
                   <Box className={'app_bar_items'}> 
                        <Button
                            color = "primary"
                            variant = "contained"  
                            className='inx_btn_red'  
                            style={{marginLeft:'auto', marginTop:'3px'}}                        
                            disabled = {selectedAccount=== null}
                            endIcon={<HowToRegIcon style={{verticalAlign: 'middle', transform: 'scale(1.25)'}}/>}
                            onClick = {() => switchAccount(selectedAccount)}
                        >
                            <span className='hide-mobile'>Use Selected Account</span>
                        </Button>
                    </Box>

             </AppBar>
             <div className='darkDataGrid datagrid_account-list'>     
                 <DevDataGrid
                    dataSource={rows}
                    showBorders={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    keyExpr="id"
                    rowAlternationEnabled={true}   
                    onRowDblClick={onRowDblClick}
                    onSelectionChanged={onSelectionChanged}
                >       
                    <Selection mode="single" />             
                     <Paging defaultPageSize={18}/>
                    <SearchPanel visible={true}
                      width={240}
                      placeholder="Search..." />  
                    <Pager
                       visible={true}
                       showInfo={true}
                       allowedPageSizes={[20,40,60]}
                       showPageSizeSelector={true}
                       infoText="Page #{0}. Total: {1} ({2} items)"  
                       displayMode="adaptive"
                     />
                    <FilterRow visible={true} />
                    <Column dataField="account" caption="Account" width={120} cellRender={renderAccount} />
                    <Column dataField="Brand" caption="Brand" width={120} cellRender={renderBrand}/>
                    <Column dataField="Shipper" caption="Shipper"  width={350} cellRender={renderShipper}/>  
                    <Column dataField="address" caption="Address"  width={350}/>  
                    {/* <Column dataField="companyID" caption="ID"  /> */}
                    <Column dataField="phone" caption="Phone" />
                    <Column dataField="Hours" caption="Hours" />  
                    <Column dataField="name" caption="Name" /> 
                    <Column dataField="" caption="" width={100} cellRender={renderButtons}/>  
                </DevDataGrid>
            </div>
            <Container>  
                <footer>
                    <Copyright/>
                </footer>
            </Container>
        </div>
    );
}