import React,  {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import  pjson from "../package.json";
import NoticeEmitter from './NoticeUtil.js';


function Copyright(props) {

  // const [textColor,setTextColor] =useState(props.textColor || 'black');
  const textColor = props.textColor || 'black';
  const [apiver, setApiver] = useState(localStorage.getItem("APIVersion", "1.8.30") !== undefined? localStorage.getItem("APIVersion", "1.8.30"): "1.8.30");
  //const apiver = localStorage.getItem("APIVersion", "1.8.30") !== undefined? localStorage.getItem("APIVersion", "1.8.30"): "1.8.30";

    NoticeEmitter.addListener('getAPIVersion', (data) => {
      setApiver(data);
    });

    return (
      <Typography variant="body2" color= {textColor === "black" ?"textSecondary": "commn.white"} align="center">
        {'Copyright © '}
        DirectIT
        {' '}
        {new Date().getFullYear()}
        {'.  ver'}
        {pjson.version}
        {'   APIs ver'}
        {apiver}

      </Typography>
    );
}

export default Copyright;