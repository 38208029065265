import React from "react";
import { useState, useEffect, useCallback} from 'react';
import { useHistory,useParams  } from 'react-router-dom';
import Popover from "@mui/material/Popover";
import Copyright from "../Copyright";
import httpRequest from "../HttpService";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
//import TextField from "@material-ui/core/TextField";
import { TextField } from '@mui/material';
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import DriverMessageList from "./components/DriverMessageList";
import MessageList from "./components/MessageList";
import DriverList from "./components/DriverList";
import TripList from "./components/TripList";
// import DriverMap from "./components/DriverMap";
import OneDriverMap from "./components/OneDriverMap";
import AllMap from "./components/AllMap";
import { Grid } from "@material-ui/core";
import mapLegend from "./components/imgs/INX Map Legends.jpg";
import NoticeEmitter from "../NoticeUtil";
import NotListedLocationIcon from "@mui/icons-material/NotListedLocation";
import RefreshIcon from "@mui/icons-material/Refresh";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import HistoryIcon from '@mui/icons-material/History';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider,} from "@material-ui/pickers";
import { letterSpacing } from "@mui/system";


export default function DriverPanel(props) { 
    const history = useHistory(); 
    const {drNum}= useParams()

    const accessToken = localStorage.getItem("access_token");
    const [showDriversList, setShowDriversList] = useState(false)
    const [showTripsList, setShowTripsList] = useState(false)
    const [showPicks, setShowPicks] = useState(true)
    const [showDelivery, setShowDelivery] = useState(true)
    const [showDrivers, setShowDrivers] = useState(true)
    const [showBreadcrumbPoints, setShowBreadcrumbPoints] = useState(false)
    const [boards, setBoards] = useState([])
    const [boardID, setBoardID] = useState(2)
    const [currentDriver, setCurrentDriver] = useState(drNum ? drNum : "") 
    const [sendMsgDriver, setSendMsgDriver] = useState(drNum ? drNum : "") 
    const IsIncludedDrvParams = drNum ? true: false
    const [messageToSend, setMessageToSend] = useState("")  
    const [drvGroups, setDrvGroups] = useState([])
    const [drvGroupID, setDrvGroupID] = useState(-1) 
    const [drivers, setDrivers] = useState([])
    const [trips, setTrips] = useState([])
    const [allMapPoints, setAllMapPoints] = useState([])
    const [hovered, setHovered] = useState(false) 
    const [breadcrumbsStartTime, setBreadcrumbsStartTime] = useState(new Date()) 
    const [breadcrumbsEndTime, setBreadcrumbsEndTime] = useState(new Date())   
    const [hoveredDriverDetail, setHoveredDriverDetail] = useState({
        hoveredDriverInfo: "",
        hoveredDriverInfoPhone: "",
        hoveredDriverInfoEmail: "",
        hoveredDriverInfoGeoMins: "",
        hoveredDriverInfoRefused: "",
        hoveredDriverInfoBurns: "",
        hoveredDriverInfoTripCount: "",
        hoveredDriverInfoTrips: ""
    }) 
    const [driverMapPoints, setDriverMapPoints] = useState([])   
    const [exceptionMapPoints, setExceptionMapPoints] = useState([])  
    const [tripMapPoints, setTripMapPoints] = useState([])  
    const [allMapPoints_drv, setAllMapPoints_drv] = useState([])
    const [anchorEI, setAnchorEI] = useState(null)
    const [open, setOpen] = useState(Boolean(null))
    // const [titleTimer, setTitleTimer] = useState(null);  
    
    useEffect(() => {   

        let token = localStorage.getItem("access_token");
        if (token === undefined || token === null) window.location.href = "/";

        init();    
        pollData();      
        const pollInterval = setInterval(() => {
            pollData();
          }, 60000);
        setTimeout(() => { updateDocumentTitle();}, 100);
       
        if(IsIncludedDrvParams){
            setTimeout(() => { console.log("IsIncludedDrvParams timeout"); pollData();}, 1000);
        }  
            
        NoticeEmitter.addListener("currentCompanyID", (data) => {
            console.log("==NoticeEmitter currentCompanyID=" + data);
            if (currentDriver === "") window.location.href = "/driver";
        });
        NoticeEmitter.addListener("freshAllDriverMap", (data) => {
            console.log("==NoticeEmitter freshAllDriverMap=" + data);
            pollData();
        });

        return () => {
            NoticeEmitter.removeAllListeners();
            // NoticeEmitter.removeListener('currentCompanyID', () => {});
            // NoticeEmitter.removeListener('freshAllDriverMap', () => {});
            clearInterval(pollInterval)           
        } 
    }, []);


    const init = useCallback(async () => {
        try {           
            httpRequest.getDriverStats(accessToken, props.companyID).then(async (body) => {
                console.log(JSON.stringify(body.data[0]));
                setDrivers(body.data);   
                setTimeout(() => { updateDocumentTitle();}, 100);
                
            });
        
            let drvGroupList = [];
            httpRequest.getDriverGroup(accessToken).then(async (body) => {
              console.log("getDriverGroup-" + JSON.stringify(body.data));
              drvGroupList = body.data;
              drvGroupList.unshift({
                driverGroupID: -1,
                descr: "Select driver group"
              })
              setDrvGroups(drvGroupList);   
            });
        
         
            httpRequest.getDispatchBoards(accessToken).then(async (body) => {
              console.log("getDispatchBoards-" + JSON.stringify(body.data));
              setBoards(body.data);
              if(body.data[0]?.boardID){
                setBoardID(body.data[0]?.boardID);
                getTripList(body.data[0]?.boardID)
              }
              else
                getTripList(boardID)            
            });  
        } catch (error) {
          console.log(error);
        }
      }, []);

    const pollData = useCallback(async () => {
        try {

            getTripList(boardID);
            getMapPointsByDriverGroup(drvGroupID);
            httpRequest.getMapPointsAll(accessToken, props.companyID).then(async (body) => {
                setAllMapPoints_drv(body.data)
            });
            if(currentDriver !== "")
                setDriverMapPin(currentDriver);   
     
        } catch (error) {
          console.log(error);
        }
      }, []);

    useEffect(() => {   
        //updateDocumentTitle()  
        if(currentDriver.length===3)      
            history.push(`/driver/${currentDriver}`)   
        else  
           history.push(`/driver`)
        setTimeout(() => {updateDocumentTitle();}, 100);  
    }, [currentDriver]);
  
    const updateDocumentTitle = () => {   
        if (currentDriver) {
            const driverInfo = drivers.find(driver => driver.drNum === currentDriver);
            if (driverInfo && driverInfo.firstName) {
                document.title = `${currentDriver} (${driverInfo.firstName})`;
            } else {
                document.title = `${currentDriver}`;
            }
        } else {
            document.title = 'Driver Management';
        }
    }

    const getMapPointsByDriverGroup = (drvGroupID) => {
        if (drvGroupID === -1) {
            httpRequest.getMapPointsAll(accessToken, props.companyID).then(async (body) => {
               console.log( drvGroupID +  "**************getMapPointsAll***********" +  body.data.length);
               setAllMapPoints(body.data);            
            });
        } else {
            httpRequest.getMapPointsByDrvGroup(accessToken,props.companyID, drvGroupID).then(async (body) => {
                console.log( drvGroupID + "=======getMapPointsByDrvGroup=====" + body.data.length);
                setAllMapPoints(body.data);
            });
        }
    };

    const handleDriverUpdate = (event) => {       
        const value = event.target.value;
        if (!isNaN(value) && Number(value) >= 0) {    
            if(value.length > 3){
                event.target.value = event.target.value.substring(0,3)
                return;
            }
            setCurrentDriver(value)
            if (value.length === 3) {            
                setSendMsgDriver(value)
                setDriverMapPin(value)
                //history.push(`/driver/${value}`)           
      
            } else if (value.length === 0) {
                setCurrentDriver("")
                setSendMsgDriver("")
                //history.push('/driver');            
            }
        }
        else{
            event.target.value = event.target.value.substring(0,event.target.value.length-1)            
        }
    };

    const updateDriverNumber = (drNum) => {  
        setCurrentDriver(drNum)
        setDriverMapPin(drNum);
        history.push(`/driver/${drNum}`);   
    };

       const handleBoardIDSelect = (event) => {
        console.log("========handleBoardIDSelect======" + event.target.value);
        setBoardID(event.target.value)
        getTripList(event.target.value)    
    };

    const handleGroupIDSelect = (event) => {
        console.log("handleGroupIDSelect--" + event.target.value);
        setDrvGroupID(event.target.value)        
        getMapPointsByDriverGroup(event.target.value);
    };

    const getTripList = (boardID) => {
        const tripList = [];
        httpRequest.getTripsDispatch(accessToken, boardID).then(async (body) => {
            if (body.data.length !== 0) {          
                await body.data.forEach((trip) => {
                    trip.SER_AREA = trip.SER_AREA + "-" + trip.SER_TIME;
                    trip.priorJobID = trip.priorJobID === null ? trip.NextJobID : trip.priorJobID;                    
                    trip.id = tripList.length;
                    trip.assignedDriver = "";
                    trip.clear = "";                     
                    tripList.push(trip);
                });        
                tripList.sort((a, b) => (a.dtDCT < b.dtDCT ? -1 : 1));
                setTrips(tripList)          
            } else setTrips([]);
        });
     };

    const handlerDriverPinClick = (driverNumber) => {
        console.log("handlerDriverPinClick-" + driverNumber);
        setCurrentDriver(driverNumber)
        setSendMsgDriver(driverNumber)
        setDriverMapPin(driverNumber);
        history.push(`/driver/${driverNumber}`);   
    };

    const setDriverMapPin = (drNum) => {
        const drvPointsList = [];
        const tripPointsList = [];
        const exceptionPointsList = [];

        allMapPoints_drv.forEach((value) => {
            if (value.mapImage === "Car" && value.drNum === drNum) {
                if (value.exception !== null && value.exception === true)
                    exceptionPointsList.push(value);
                else drvPointsList.push(value);        
                handleDriverMarkerHover(drNum, value.json);
            } else if (value.drNum === drNum) {
                tripPointsList.push(value);
            }
        });
        setTripMapPoints(tripPointsList)
        setExceptionMapPoints(exceptionPointsList)
        setDriverMapPoints(drvPointsList)
    };

   const allShowClick = () => {
      setCurrentDriver("")
      setSendMsgDriver("")
     // document.getElementById("currentDrv").value = "";      
      history.push('/driver');    
    };
  
    const handleSendDriverMsgClick = async (event) => {
        //console.log("handleSendDriverMsgClicke---------" + sendMsgDriver + "---" + messageToSend);
        const res = await httpRequest.sendDriverMessage(accessToken,sendMsgDriver,props.companyID, undefined, messageToSend);
        setSendMsgDriver('');
        setMessageToSend('');           
    };

    const handleDriverMarkerHover = (drNum, json) => {
        //console.log("driver json -" + JSON.stringify(json));
        json = JSON.parse(json);
        let msg = "";
        let msgPhone = "";
        let msgEmail = "";
        let msgGeoMins = "";
        let msgRefused = "";
        let msgBurns = "";
        let msgTripCount = "";
        let msgTrips = "";

    if (drNum !== null) {
        msg = drNum + "  " + json[0].firstName;
        msgPhone = json[0].ContactPhone;
        msgEmail = json[0].contactEmail;
        msgGeoMins = json[0].GeoMinutes;
        msgRefused = json[0].refused;
        msgBurns = json[0].burns;
        msgTripCount = json[0].tripCount;
        msgTrips = json[0].trips;

        setHoveredDriverDetail((prevState) => {
            return {
                ...prevState,
                hoveredDriverInfo: msg,
                hoveredDriverInfoPhone:msgPhone,
                hoveredDriverInfoEmail: msgEmail,
                hoveredDriverInfoGeoMins: msgGeoMins,
                hoveredDriverInfoRefused: msgRefused,
                hoveredDriverInfoBurns: msgBurns,
                hoveredDriverInfoTripCount: msgTripCount,
                hoveredDriverInfoTrips: msgTrips
            };
        });      
    }
    setHovered(true)   
  };


    const renderDriverInfoField = (style, label, value) =>{
        return(
            <div className={style}>
                <div className="titleBox">{label}</div>
                 <div className="descBox">{value}</div>
            </div> 
        )
    }
    const renderDriverInfo = () =>{
        return hovered ? (
        <div className="appBarDark">
            <div className="statsWrapper statsWrapperDriver">
                {renderDriverInfoField("statBox statBoxPrimary","driver#",hoveredDriverDetail.hoveredDriverInfo)}
                {renderDriverInfoField("statBox statBoxPrimary","phone",hoveredDriverDetail.hoveredDriverInfoPhone)}
                {renderDriverInfoField("statBox statBoxPrimary","email",hoveredDriverDetail.hoveredDriverInfoEmail)}
                {renderDriverInfoField("statBox statBoxSecondary","geo mins",hoveredDriverDetail.hoveredDriverInfoGeoMins)}
                {renderDriverInfoField("statBox statBoxDanger","refused",hoveredDriverDetail.hoveredDriverInfoRefused)}
                {renderDriverInfoField("statBox statBoxWarning","burns",hoveredDriverDetail.hoveredDriverInfoBurns)}
                {renderDriverInfoField("statBox statBoxSecondary","tripcount",hoveredDriverDetail.hoveredDriverInfoTripCount)}         
                {renderDriverInfoField("statBox statBoxSecondary","trips",hoveredDriverDetail.hoveredDriverInfoTrips)} 
            </div>
        </div>
        ) : (<div></div>);
    }

    const handlePopoverOpen = (event) => {
        setAnchorEI( event.currentTarget)
        setOpen(Boolean(event.currentTarget))       
    };

    const handlePopoverClose = () => {
        setAnchorEI( null)
        setOpen(Boolean(null)) 
    };

    return (
        <div className="root">
        <AppBar position="static" className="appBarDark" style={{ overflow: "auto", padding: "15px 0.5%" }}>
            <Grid container spacing={0}>
                <Grid item md={12} lg={6} style={{ display: "flex" }}>
                    <div style={{ paddingRight: "10px" }}>
                    <Tooltip title="Show All">
                        <IconButton className="btnPrimary" aria-label="Show All" onClick={allShowClick}>
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                    </div>
                    <TextField id="currentDrv" value={currentDriver}  type="number"  label="Driver #"  variant="outlined"  size="small"  style={{ marginRight: "10px", width: 90 }}  
                       onChange={(event)=>{ handleDriverUpdate(event)} }/>
                    <FormControl variant="outlined" size="small" style={{ marginRight: "10px", width: 140 }}>
                        <InputLabel>Board Name</InputLabel>
                        <Select value={boardID}  label="Board Name"  onChange={handleBoardIDSelect}>
                            {boards.map((item) => (
                                <MenuItem  key={item.boardID}  value={item.boardID}>
                                    {item.boardName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined"  size="small"  style={{ marginRight: "10px", width: 200 }}>
                        <InputLabel>DriverGroup</InputLabel>
                         <Select label="DriverGroup" value={drvGroupID} onChange={handleGroupIDSelect}>
                            {drvGroups.map((group) => (
                                <MenuItem key={group.driverGroupID} value={group.driverGroupID}>
                                    {group.descr}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {currentDriver !== "" && !showBreadcrumbPoints &&
                    <div style={{ paddingRight: "10px" }}>
                        <Tooltip title="Driver Breadcrumb Points">
                            <IconButton className="btnPrimary" aria-label="BreadcrumbPoints" onClick={()=>{setShowBreadcrumbPoints(true)}}>
                                <HistoryIcon />
                            </IconButton>
                        </Tooltip>
                    </div>}
                    {currentDriver !== ""  && showBreadcrumbPoints &&
                    <div style={{ paddingRight: "10px" }}>
                        <Tooltip title="Driver Breadcrumb Points">
                            <IconButton className="btnPrimary" aria-label="BreadcrumbPoints" onClick={()=>{setShowBreadcrumbPoints(false)}}>
                                <HistoryToggleOffIcon />
                            </IconButton>
                        </Tooltip>
                    </div>}
                    {currentDriver !== ""  && 
                    <div style={{ paddingRight: "10px" }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDateTimePicker
                                name="bcStart"
                                className="editableJobField"
                                format="yyyy-MM-dd HH:mm"
                                label={<Typography>Start</Typography>}
                                value={breadcrumbsStartTime}
                                onChange={(input) => {  setBreadcrumbsStartTime(input)}}
                            />
                        </MuiPickersUtilsProvider>
                    </div>}

                    {currentDriver !== ""  && 
                    <div style={{ paddingRight: "10px" }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDateTimePicker
                                name="bcEnd"
                                className="editableJobField"
                                format="yyyy-MM-dd HH:mm"
                                label={<Typography>End</Typography>}
                                value={breadcrumbsEndTime}
                                onChange={(input) => {    setBreadcrumbsEndTime(input) }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>}                 
                </Grid>
                <Grid item md={12} lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div className="mapTogglesWrapper">
                        <FormGroup style={{ display: "inline", color: "black" }}>
                            <FormControlLabel  label="Drivers List"
                                control={<Switch color="primary" size="small" onChange={(event)=>{ setShowDriversList( event.target.checked)}} checked={showDriversList}/>}/>
                            <FormControlLabel label="Trips List"
                                control={<Switch color="primary" size="small" onChange={(event)=>{setShowTripsList(event.target.checked) }} checked={showTripsList}/>}/>   
                            <FormControlLabel label="Picks"
                                control={<Switch color="primary" size="small" onChange={(event)=>{setShowPicks(event.target.checked) }} checked={showPicks}/>}/>
                            <FormControlLabel  label="Deliveries"
                                control={<Switch color="primary" size="small" onChange={(event)=>{setShowDelivery(event.target.checked) }}   checked={showDelivery}/>}/>
                            <FormControlLabel label="Drivers"
                                control={<Switch color="primary" size="small" onChange={(event)=>{setShowDrivers(event.target.checked) }}   checked={showDrivers}/> }/>
                        </FormGroup>
                        <Tooltip title="Map Legend">
                            <IconButton   className="btnPrimary"  aria-label="Map Legend"  onClick={handlePopoverOpen}>
                                <NotListedLocationIcon />
                            </IconButton>
                        </Tooltip>
                        <Popover
                            open={open}
                            className="popoverDark"
                            anchorEl={anchorEI}
                            onClose={handlePopoverClose}
                            anchorOrigin={{ vertical: "bottom",  horizontal: "right",}}
                            transformOrigin={{ vertical: "top", horizontal: "right", }}
                        >
                            <div>
                                <img src={mapLegend}  style={{ width: "577px", display: "block", height: "auto",}} alt="Map Legend"/>
                            </div>
                        </Popover>
                    </div>
                </Grid>
            </Grid>
        </AppBar>

        {showDriversList && (
            <DriverList  driverList={drivers} updateDriver={updateDriverNumber} companyID={props.companyID} accessToken={accessToken}/>
        )}
        {showTripsList && (
            <TripList  boardID={boardID} tripList={trips} companyID={props.companyID} accessToken={accessToken}/>
        )}
        {renderDriverInfo()}
        <Grid  container  direction="row"  justifyContent="center"  alignItems="start"  spacing={0}  sm={12}>
            <Grid item xs={12} md={8}>
                {currentDriver === "" && allMapPoints.length > 0 ? (
                <AllMap
                    boardID={boardID}
                    tripList={trips}
                    companyID={props.companyID}
                    mapPoints={allMapPoints}
                    accessToken={accessToken}
                    handlerDriverPinClick={handlerDriverPinClick}
                    hoverHandler={handleDriverMarkerHover}
                    IsShowDrivers={showDrivers}
                    IsShowPickup={showPicks}
                    IsShowDelivery={showDelivery}                
                />) : (<></>)}     

                {currentDriver !== "" ? (
                <OneDriverMap
                    boardID={boardID}
                    tripList={trips}
                    companyID={props.companyID}
                    tripPinsList={tripMapPoints}
                    accessToken={accessToken}
                    currentDriver={currentDriver}
                    hoverHandler={handleDriverMarkerHover}
                    IsShowPickup={showPicks}
                    IsShowDelivery={showDelivery}
                    IsShowBreadcrumbPoints={showBreadcrumbPoints}
                    startTime = {breadcrumbsStartTime}
                    endTime = {breadcrumbsEndTime}
                />) : (<></>)}     
            </Grid>
            <Grid item xs={12} md={4}>
                <Grid container direction="column"  sm={12} style={{ padding: "10px" }}>
                    <Grid item>
                        <Box display="flex" justifyContent="stretch"  border={0} className="darkForm" style={{ flexwrap: "nowrap", marginBottom: "10px" }}  sx={{ height: "auto" }}>
                            <TextField  id="msgDrv"  label="Driver#"  variant="outlined"   size="small"   style={{ marginRight: "10px", width: "20%" }}
                                value={sendMsgDriver}  onChange={(event)=>{setSendMsgDriver(event.target.value)}}  inputProps={{ maxLength: 3 }}/>
                            <TextField  id="msgText" label="Send Message"  value={messageToSend} variant="outlined"  size="small"  style={{ width: "70%" }}   onChange={(event)=>{ setMessageToSend(event.target.value ) }}/>                 
                            <div style={{ marginLeft: "5px" }}>
                                <Tooltip title="Send">
                                    <IconButton  className="btnPrimary"  aria-label="Send"  onClick={handleSendDriverMsgClick}><SendIcon /></IconButton>
                                </Tooltip>
                            </div>
                        </Box>
                    </Grid>
                    <Grid item>
                        {currentDriver === "" ? (
                            <MessageList  updateDriver={updateDriverNumber}  companyID={props.companyID} accessToken={accessToken}/>
                        ) : (<></>)}
                        {currentDriver !== "" ? (
                            <DriverMessageList  currentDriver={currentDriver} companyID={props.companyID}  accessToken={accessToken}/>
                        ) : (<></>)}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <footer><Copyright  textColor='white'/></footer>
    </div>
    )
}

