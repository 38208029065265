import React, { useState} from 'react';
import SideButtonDialog from './sideButtonDialog';
import 'date-fns';
import {Button} from '@material-ui/core';
import HttpRequest from '../HttpService.js'
import NoticeEmitter from "../NoticeUtil.js";
import { Snackbar, Alert } from "@mui/material";
import CustomDialog from "../components/dialog/Dialog.js";

export default function SideButtons(props){

  const [selectedButton, setSelectedButton] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState("");
  const [openActionDialog, setOpenActionDialog] = React.useState(false);
  const [actionDialogMessage, setActionDialogMessage] = React.useState(""); 

  const handleDialogOpen = () => {
    setIsDialogOpen(true);  
  }

  const handleDialogClose = () => {
    setIsDialogOpen(false);    
  }

  const actionFunction = () =>{
    if(selectedButton.paramaters === "{}"){
      console.log(" actionFunction button paramters is " + selectedButton.paramaters);
      setOpenActionDialog(false);
     
      var jobIDList ='';
      props.selection.forEach((item) => {
         jobIDList += item.jobID +',';
      });   
      console.log("Procedure is " + selectedButton.procedureName);
      HttpRequest.doTripListFunction(props.accessToken,jobIDList,[],selectedButton.procedureName).then(function successCallback(data){
        console.log("Procedure is done for job/jobs " + jobIDList);
        setMessageInfo("Procedure is done for job/jobs " + jobIDList);  
        setOpenSnack(true);
        NoticeEmitter.emit("resetTable", "");
      },function errorCallback (err) {
        alert('Failed to : ' + err);
      });              
    }
    else{
      setOpenActionDialog(false);
      //setSelectedButton(button);
      handleDialogOpen();
      //setTimeout(function(){ setSelectedButton(button); handleDialogOpen();  }, 500); // directly open dialog, the edit field isn't auto focus, don't know why??????
    }
  }


  
  const renderButton = (button) => {
  return(    
      <Button fullWidth
         color = 'primary'
         className='sideButton'
         variant = 'outlined'
         onClick = {() =>{
          if(props.selection.length > 0){
            var msg  = props.selection.length === 1? "1 job is selected." : props.selection.length + " jobs are selected."
            setSelectedButton(button);
            setActionDialogMessage(msg);
            setOpenActionDialog(true);            
          }
        }
      }
      >
        {button.functionText}
      </Button>
    
  )
  }

  return(
    <div className='buttonsWrap'>
        {props.buttonList.map((button) => (
          renderButton(button)
        ))}      
        {selectedButton!== null && <SideButtonDialog
          button = {selectedButton}
          open = {isDialogOpen}
          selection = {props.selection}
          accessToken = {props.accessToken}
          handleDialogClose = {handleDialogClose}/>} 

       <CustomDialog
        open={openActionDialog}
        onClose={() => {
          setOpenActionDialog(false);
        }}
        hasCloseBtn={true}
        dialogMessage={actionDialogMessage}
        dialogTitle="Warning"
        dialogAction={() => {
          actionFunction();
        }}
      />   
          
      <Snackbar
        open={openSnack}
        autoHideDuration={10000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => {
          setOpenSnack(false);
        }}
      >
        <Alert
          onClose={() => {
            setOpenSnack(false);
          }}
          severity="info"
          sx={{ width: "100%" }}
        >
          {messageInfo}
        </Alert>
      </Snackbar>  
    </div>
  )
}