import React from 'react';
import moment from "moment";
import { Stepper, Step, StepLabel, Typography } from "@material-ui/core";

const OrderSteps = ({ steps }) => {
  const renderStepContentText = (label) => {
    return (
      <div>
        <Typography>{label.bigText}</Typography>
        <Typography variant="caption">
          {label.smallText + moment(label.timestamp_utc).format("MM-DD") + " @ " + moment(label.timestamp_utc).format("LT")}
        </Typography>
        {label.img_url && (
          <span>
            <a onClick={() => {window.open(label.img_http_link, "_blank");}}>
              {" "}{label.img_text}{" "}
            </a>
            <div>
              <img 
                style={{ width: "80px", height: "60px" }} 
                alt={label.img_http_link} 
                src={label.img_url} 
                onClick={() => {window.open(label.img_http_link, "_blank");}}
              />
            </div>
          </span>
        )}
      </div>
    );
  };

  return (
    <Stepper alternativeLabel>
      {steps !== undefined && steps?.map((label) => (
        <Step key={label.bigText} completed={label.filled}>
          <StepLabel>{renderStepContentText(label)}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default OrderSteps;