import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import Copyright from "../Copyright";
import Typography from "@material-ui/core/Typography";
import httpRequest from "../HttpService";
import AddressCard from "./components/addressCard";
import CameraButton from "./components/cameraButton";
import TextInput from "../components/inputFields/TextInput";
import Gallery from "./components/gallery";
import LatticeStatusBar from './components/LatticeStatusBar';
import moment from "moment";
import InfoField from "./components/infoField";
import logo from "./images/logo_west-direct.jpg";
import { Grid, Button, ButtonGroup, InputAdornment, Box, Collapse } from "@material-ui/core";
import { Divider, Tooltip } from '@mui/material';
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import "../components/sideIcon/sideIcon.css";
import "../jobDetail/jobDetail.css";
import Card from "@material-ui/core/Card";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import TripFunctionDialog from "./components/tripFunctionDialog";
import TripDriverList from "./components/tripDriverList";
import TripHistoryList from "./components/tripHistoryList";
import TripRatingList from "./components/tripRatingList";
import TripPiecesList from './components/TripPiecesList.js';
import TagsView from './components/TagsView';
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import InputField from "./components/inputField";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import CustomDialog from "./components/dialog";
import CallerField from "./components/callerField";
import moment_tz from "moment-timezone";
import NoticeEmitter from "../NoticeUtil";
import { Snackbar, Alert } from "@mui/material";
import styles from '../components/inputFields/styles.js';
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StreetViewMap from "../components/streetViewSideBySideMap";

import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Drawer as DxDrawer } from 'devextreme-react/drawer';
import { List as DxList } from 'devextreme-react/list';
import { Toolbar as DxToolbar, Item as DxItem } from 'devextreme-react/toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Tabs } from 'devextreme-react/tabs';
import { makeStyles } from '@material-ui/core/styles';

import { Popover } from '@material-ui/core';

import OrderSteps from './components/OrderSteps.js';
import MapView from './components/MapView.js';
import OrderDetails from './components/OrderDetails.js';
import SideMenu from './components/SideMenu';
import DialogManager from './components/DialogManager';
import SnackbarManager from './components/SnackbarManager';

import PicturesTab from './components/PicturesTab.js';
import { debounce } from 'lodash';

// DevExpress CSS
import 'devextreme/dist/css/dx.light.css';

const viewMappings = {
  0: 'map',
  1: 'pictures',
  2: 'history',
  3: 'drivers',
  4: 'tags',
  5: 'Pieces',
};

const LatticeJobDetail = (props) => {
  const reverseViewMappings = useMemo(() => 
    Object.fromEntries(Object.entries(viewMappings).map(([key, value]) => [value, parseInt(key)]))
  , []);

  const classes = {
    root: {
      backgroundColor: "transparent",
    },
    gallery: {   
    },
    drawerList: {
      width: 360,
      padding: 0,
    },
    drawerWrapper: {
      backgroundColor: "transparent",
      top: "64px",
      left: "38px",
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: 1,
      justifyContent: "space-between",
    },
    toolbarGridItem: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      padding: "15px",
    },
    jobDetailLabel: {
      fontSize: 12,
      fontWeight: 500,
    },
  }

  const accessToken = localStorage.getItem("access_token");
  const { jobId, view: urlView } = useParams();
  const history = useHistory();
  const [companyLogo, setCompanyLog] = useState(logo);
  const [galleryVisible, setGalleryVisible] = useState(false);
  const [photoArray, setPhotoArray] = useState([]);      
  const [view, setView] = useState("map");
  const [addr, setAddr] = useState("delivery");
  const [GPSUpdateButtonText, setGPSUpdateButtonText] = useState("Update Delivery GPS");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [viewAddress, setViewAddress] = useState({
    companyName: "",
    lat: "",
    long: "",
    address: "",
  });
  
  const [viewFunctions, setViewFunctions] = useState([]);
  const [dispatchFunctions, setDispatchFunctions] = useState([]);
  const [serviceFunctions, setServiceFunctions] = useState([]);
  const [ratingFunctions, setRatingFunctions] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [TripServiceNoteList, setTripServiceNoteList] = useState([]);     
  const [callerList, setCallerList] = useState([]);    
  const [steps, setSteps] = useState([]);
  const [isUploadFile, setIsUploadFile] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [openUpdatingDialog, setOpenUpdatingDialog] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [updatingItmeStr, setUpdatingItmeStr] = useState("");  
  const [messageInfo, setMessageInfo] = useState("");  
  const [TripServiceAccountNotes, setTripServiceAccountNotes] = useState("");  
  const [gpsData, setGpsData] = useState({});
  const [currentAccount, setCurrentAccount] = useState({});
  const [currentRate, setCurrentRate] = useState({});
  const [updatingReferences, setUpdatingReferences] = useState({});
  const [updatingItem, setUpdatingItem] = useState({});
  const [tripFunction, setTripFunction] = useState({ parameters: [] });
  const [latestUpdatedPostion, setLatestUpdatedPostion] = useState(null);
  const [updateGPSSource, setUpdateGPSSource] = useState(null);
  const [interval, setInterval] = useState(null);
  const [order, setOrder] = useState({});
  const [orderDTCallIn, setOrderDTCallIn] = useState(null);
  const [orderDTDCT, setOrderDTDCT] = useState(null);
  const [orderSCT, setOrderSCT] = useState(null);
  const [dtPickupBy, setDTPickupBy] = useState(null);
  const [dtDontDeliveryBefore, setDTDontDeliveryBefore] = useState(null);
  const [adjustRate, setAdjustRate] = useState(null);
  const [locationUpdateDialog, setLocationUpdateDialog] = useState(false);  
  const [isLattice, setIsLattice] = useState(false);  
  const [tagList, setTagList] = useState([]); 
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const [referenceList, setReferenceList] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState('View');
  const [showMapView, setShowMapView] = useState(true);      
  const [category, setCategory] = useState("View"); 
  const [rateChoices, setRateChoices] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);

  const [expanded, setExpanded] = useState(false);
  const [isStreetViewExpanded, setIsStreetViewExpanded] = useState(false);

  const hoverTimeoutRef = useRef(null);

  const [rateAdjustmentDialog, setRateAdjustmentDialog] = useState({
    isOpen: false,
    changeRate: false
  });

  const [overrideOpenDialog, setOverrideOpenDialog] = useState(false);
  const [overrideDialogMessage, setOverrideDialogMessage] = useState("");
  
  const handleRateAdjustment = () => {
    setAdjustRate(true);
    updateOrderFields(jobId);
    setRateAdjustmentDialog({ isOpen: false, changeRate: false, field: null });
  };

  const [availableDescriptions, setAvailableDescriptions] = useState([]);

  const handleDescriptionUpdate = debounce((descriptionID, serviceSettingsID) => {
    httpRequest.updateTripDescripion(accessToken, jobId, descriptionID, serviceSettingsID)
      .then((response) => {
        console.log("Description update response:", response);
        loadTripDetails(jobId);
      })
      .catch((error) => {
        console.error("Error updating description:", error);
      });
  }, 300);  // 300ms debounce

  const handleDescriptionSelect = (descriptionID, serviceSettingsID) => {
    handleDescriptionUpdate(descriptionID, serviceSettingsID);
  };

  const loadAvailableDescriptions = useCallback(() => {
    if (!isEdit || !isLattice) return;
  
    httpRequest.getTripAvailableDescriptions(accessToken, jobId)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setAvailableDescriptions(response.data);
        } else {
          console.error("Unexpected response format:", response.data);
          setAvailableDescriptions([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching available descriptions:", error);
        setAvailableDescriptions([]);
      });
  }, [accessToken, jobId, isEdit, isLattice]);
  
  useEffect(() => {
    if (isEdit && isLattice) {
      loadAvailableDescriptions();
    }
  }, [isEdit, isLattice, loadAvailableDescriptions]);

  useEffect(() => {
    return () => {
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
      }
    };
  }, []);

  const handleChange = (event, isExpanded) => {
    console.log("Accordion clicked, isExpanded:", isExpanded);
    setExpanded(isExpanded);
  };

  useEffect(() => {
    if (order) {
      const billingReferenceField = {
        refID: 'CUST_REF',
        label: 'Billing Reference',
        typeDesc: 'Edit',
        currentValue: order.CUST_REF || ''
      };
      
      setReferenceList(prevList => {
        const existingIndex = prevList.findIndex(item => item.refID === 'CUST_REF');
        if (existingIndex >= 0) {
          return prevList.map(item => item.refID === 'CUST_REF' ? billingReferenceField : item);
        } else {
          return [...prevList, billingReferenceField];
        }
      });
    }
  }, [order]);

  const toggleDrawer = () => {
    setIsDrawerOpen(prev => !prev);
  };

  const navigation = [
    { 
      id: 'Dispatch', 
      text: 'Dispatch', 
      icon: 'fa fa-truck',
      options: dispatchFunctions
    },
    { 
      id: 'Service', 
      text: 'Service', 
      icon: 'fa fa-wrench',
      options: serviceFunctions
    },
    { 
      id: 'Rating', 
      text: 'Rating', 
      icon: 'fa fa-sliders',
      options: ratingFunctions
    }
  ];

  const viewTabs = [
    { id: 0, text: 'Map' },
    { id: 1, text: 'Pictures' },
    { id: 2, text: 'History' },
    { id: 3, text: 'Drivers & Rating' },
    { id: 4, text: 'Tags' },
    { id: 5, text: 'Pieces' }
  ];

  const handleTabClick = (e) => {
    setSelectedTab(e.itemData.id);
    updateView(e.itemData.id);
  };

  const [pageTitle, setPageTitle] = useState('DirectIt INX');     

  const getInitialTab = () => {
    const savedView = localStorage.getItem(`jobDetailView_${jobId}`);
    if (urlView && reverseViewMappings.hasOwnProperty(urlView)) {
      return reverseViewMappings[urlView];
    } else if (savedView && viewMappings.hasOwnProperty(savedView)) {
      return parseInt(savedView);
    } else {
      return 0; // default to map view
    }
  };

  const [showContent, setShowContent] = useState(() => {
    const savedView = localStorage.getItem(`jobDetailView_${jobId}`);
    if (urlView) {
      return reverseViewMappings[urlView] || 0;
    } else if (savedView) {
      return parseInt(savedView);
    } else {
      return 0; // default to map view
    }
  });
  
  const updateView = useCallback((newView) => {
    if (viewMappings.hasOwnProperty(newView)) {
      setSelectedTab(newView);
      localStorage.setItem(`jobDetailView_${jobId}`, newView.toString());
      history.push(`/jobDetail/${jobId}/${viewMappings[newView]}`);
    } else {
      console.error(`Invalid view: ${newView}`);
      setSelectedTab(0);
      localStorage.setItem(`jobDetailView_${jobId}`, '0');
      history.push(`/jobDetail/${jobId}/map`);
    }
  }, [jobId, history]);

  useEffect(() => {
    const savedView = localStorage.getItem(`jobDetailView_${jobId}`);
    let initialView;
    let token = localStorage.getItem("access_token");
    if (token === undefined || token === null) window.location.href = "/";    
    
    const titleTimer = setTimeout(() => {
      if (jobId) {
        const shortId = jobId.slice(-5);
        document.title = `${shortId} - ${viewMappings[showContent]}`;
      }
    }, 100);

    loadTripDetails(jobId);
    getTripFunctions(jobId);

    NoticeEmitter.addListener("refreshJobDetail", (data) => {
      console.log("==NoticeEmitter refreshJobDetail==" + data);
      loadTripDetails(jobId);         
    });

    Object.keys(localStorage).forEach(key => {
      if (key.startsWith('jobDetailView_') && !key.endsWith(jobId)) {
        localStorage.removeItem(key);}
      });
  
      if (urlView && reverseViewMappings.hasOwnProperty(urlView)) {
        initialView = reverseViewMappings[urlView];
      } else if (savedView && viewMappings.hasOwnProperty(savedView)) {
        initialView = parseInt(savedView);
      } else {
        initialView = 0; // default to map view
      }
  
      if (initialView !== selectedTab) {
        updateView(initialView);
      }
  
      return () => clearTimeout(titleTimer);
    }, [jobId, urlView, selectedTab, updateView]);
  
    const updateDocumentTitle = (id) => {
      if (id) {
        const shortId = jobId.slice(-5);
        document.title = shortId;
      } else {
        document.title = 'DirectIt INX';
      }
    };
  
    const handleMenu = (val) => {
      setIsMenuOpen(true);
      setCategory(val);        
    };
  
    const loadTripDetails = useCallback((jobId) => {  
      setTimeout(() => {
        if (jobId) {
          const shortId = jobId.slice(-5);
          document.title = `${shortId} - ${viewMappings[showContent]}`;
        }
      }, 100);
    
      getTripServiceNotes(jobId);    
      httpRequest.getTrip(accessToken, jobId).then((response) => {
        var data = response.data;
        try {        
          // Preserve existing timeIconURL if it exists and new data doesn't have it
          if (!data.timeIconURL && order?.timeIconURL) {
            data.timeIconURL = order.timeIconURL;
          }
    
          data.dtCallIn_display = data.dtCallIn?.toString().substring(0, 19).replace("T", " ");
          data.dtDCT_display = data.dtDCT?.toString().substring(0, 19).replace("T", " ");
    
          if (data.dtDelivered) 
            data.dtDelivered_display = data.dtDelivered.toString().substring(0, 19).replace("T", " ");
          if (data.eta)
            data.eta_display = data.eta.toString().substring(0, 19).replace("T", " ");
          if (data.URL)
            data.FedexTrackNum = data.URL.substring(data.URL.length - 12);
    
          setIsLattice(data.isLattice);
          var images = JSON.parse(data.ImageURLs);
          if (images) {
            var imageList = [];
            images.forEach((value) => {
              let fileExt = value.url.split(".").pop();
              value.savedTimeUTC = new Date(moment(value.savedTimeUTC).toDate() - new Date().getTimezoneOffset() * 60000);
              value.savedTime = moment(value.savedTimeUTC).format("YY-MM-DD HH:mm");
              value.id = imageList.length;   
              value.IsImageOrPDF = !(value.url.includes(".doc") || value.url.includes(".docx") || value.url.includes(".xls") || value.url.includes(".xlsx") ||value.url.includes(".csv"));         
              value.IsImage = !(value.url.includes(".doc") || value.url.includes(".docx") ||value.url.includes(".pdf") || value.url.includes(".xls") || value.url.includes(".xlsx") || value.url.includes(".csv"));
             
              value.fileType = (value.url.includes(".doc") || value.url.includes(".docx")) ? "doc" :(value.url.includes(".xls") || value.url.includes(".xlsx")) ? "xls"
                :value.url.includes(".pdf") ? "pdf": value.url.includes(".csv") ? "csv" :"image";                
              value.fileExt = value.url.split(".").pop().includes("net/") ? "png" : fileExt;
              imageList.push(value);
            });
            setPhotoArray(imageList);             
          }
          data.serviceMinutes = data.serviceMinutes || 0;
          data.serviceDays = data.serviceDays || 0;
          data.billMinutes = data.billMinutes || 0;
          data.drivDist = data.drivDist || 0;
    
          // Updated order state setting logic to preserve timeIconURL
          if (data.timeIconURL || order?.timeIconURL) {
            setOrder(prevOrder => ({
              ...data,
              timeIconURL: data.timeIconURL || prevOrder.timeIconURL
            }));
          } else {
            setOrder(data);
          }
    
          setViewAddress({
            companyName: data.cCompanyName,
            lat: data.cLat,
            long: data.cLong,
            address: data.cAddress,
          });
          setAddr("delivery");
          setGPSUpdateButtonText("Update Delivery GPS");         
    
          setOrderDTCallIn(moment(moment_tz.tz(data.dtCallIn_display, "Canada/Mountain").toDate()).format());            
          setOrderDTDCT(moment(moment_tz.tz(data.dtDCT_display, "Canada/Mountain").toDate()).format());
          if (data.eta) 
            setOrderSCT(moment_tz.tz(data.eta_display, "Canada/Mountain").toDate());  
    
          if (data.logo?.data) {
            var binary = "";
            var bytes = new Uint8Array(data.logo?.data);
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
              binary += String.fromCharCode(bytes[i]);
            }
            const imageSrc = "data:image/png;base64," + btoa(binary);
            setCompanyLog(imageSrc);             
          }
    
          getGPSTracking();    
          getTripAccountList(data.jobID, data.ACCOUNT);
          httpRequest.getOrderTrack(jobId).then((response) => {
            try {
              const tempTrackList = response.data?.map((option,index) => {
                option.id=index;
                return option;
              });
              setSteps(tempTrackList);                
            } catch (e) {
              console.log("getOrderTrack exception:" + e);
            }
          });
          setIsUploadFile(false);  
          
          loadTripTags();
    
          if (data.Status < 8) {
            if (interval === null) {
              let _interval = setInterval(() => {getGPSTracking();}, 30000);
              setInterval(_interval);              
            }
          } else {
            if (interval !== null) {
              clearInterval(interval);
              setInterval(null); 
            }
          }    
          getRateChoices(data.SER_AREA, data.SER_TIME);
        } catch (err) {
          console.log("Order not found. Please try again-" + err);
        }
      });
    }, [order, showContent]);
  
    const loadTripTags = () => {
      httpRequest.getTripTags(accessToken,jobId,'TripDetail').then((response) => {
        console.log('getTripTags',JSON.stringify(response.data));                   
        setTagList(response.data);
      });
    }
  
    const getRateChoices = (ser_area, ser_time) => {
      httpRequest.getRateChoice(accessToken,jobId).then((response) => {
        try {               
          let newRows = response.data.map((item,i) => {
            return {
              ...item,
              id:i,
              service: item.ser_area + item.ser_time,
            };
          }); 
          setRateChoices(newRows);     
          var _currentRate = newRows.find((obj) => {return (obj.ser_area === ser_area && obj.ser_time === ser_time);});
          if (_currentRate !== undefined){
            console.log("getRateChoices currentRate is " + JSON.stringify(_currentRate));
            setCurrentRate(_currentRate);
          }
        } catch (e) { console.log("getRateChoices exception", e); }
      });
    };
  
    const getTripServiceNotes = (jobId) => {
      httpRequest.getTripServiceNotes(accessToken, jobId).then((body) => {
        try {
          console.log("getTripServiceNotes", JSON.stringify(body.data))
          let tripServiceNoteList = [];
          if (body.data.length > 0) {
            let tripServiceAccountNotes = "";
            body.data.forEach((row) => {
              if (row.source?.toLowerCase().includes("account") && row.notes)
                tripServiceAccountNotes += "AccountInfo:" + row.notes + ";";
              else if (row.notes !== null && row.notes !== "")
                tripServiceNoteList.push(row);
            });
            setTripServiceNoteList(tripServiceNoteList);
            setTripServiceAccountNotes(tripServiceAccountNotes);                
          }
        } catch (e) {console.log("getTripServiceNotes exception", e);}
      });
    };
  
    const getPackageList = () => {
      httpRequest.getTripPackageDescriptions(accessToken, jobId).then((response) => {
        const updatedPackageList = response.data.map(pkg => ({
          ...pkg,
          icon_url: pkg.icon_url || ''
        }));
        setPackageList(updatedPackageList);
      });
    };
  
    const getGPSTracking = () => {
      httpRequest.getJobGPS(jobId).then((response) => {
        setGpsData(response.data);  
        if (response.data.TripLocation.includes("Delivered")) {
          if (interval !== null) {
            clearInterval(interval);
            setInterval(null);
          }
        }
      });
    };
  
    const getTripAccountList = (jobId, account) => {
      console.log("getTripAccountList called with jobId:", jobId, "account:", account);
      httpRequest.getTripAccountList(accessToken, jobId).then((body) => {
        console.log("getTripAccountList response:", body);
        try {
          const accountData = Array.isArray(body.data) ? body.data : [];
          setAccountList(accountData);
          const currentAcct = accountData.find(obj => obj.account === account?.trim());
          if(currentAcct) {
            setCurrentAccount(currentAcct);
          }
        } catch (e) {
          console.error("getTripAccountList exception", e);
          setAccountList([]);
        }
      }).catch(error => {
        console.error("Error in getTripAccountList:", error);
        setAccountList([]);
      });
    };
  
    const getReferenceList = () => {
      httpRequest.getReferenceFields(accessToken, jobId).then((body) => {
        console.log("getReferenceFields", JSON.stringify(body.data));
        setReferenceList(body.data);
        body.data.forEach((item) => {
          if (item.typeDesc === "DropDown")
            getReferenceDropDown(item.refID);
        });
      });
    };
  
    const getReferenceDropDown = (id) => {
      httpRequest.getReferenceDropDown(accessToken, id).then((response) => {
        console.log("getReferenceDropDown", JSON.stringify(response.data));
        let refList = referenceList;
        var objIndex = refList.findIndex((obj) => obj.refID === id);
        refList[objIndex].ddValues = response.data;
        setReferenceList(refList);
      });
    };   
  
    const getUserEmails = () => {
      httpRequest.getUserEmails(order.ACCOUNT).then((response) => {
        setCallerList(response.data);
      });
    };
  
    const removeCallerFromList = (caller) => {
      let nameList = callerList.filter(function (obj) {return obj.username !== caller;});
      setCallerList(nameList);
    };
  
    const rendAccountServiceNotes = () => {
      return TripServiceNoteList.map((item) => (
        <Alert variant="filled"  severity="info"  className={"fieldJobNote"}  icon={false}>
          <div className={"speechBubble"}>{item.source}</div>
          <div className={"notes"}>{item.notes}</div>
        </Alert>
      ));
    };
  
    const updateOrderFields = (jobId) => {
      try {
        let updateObj = { ...updatingItem, ...updatingReferences, jobID: jobId };
        
        // Handle date fields
        const dateFields = ['dtCallIn', 'dtDCT', 'sct'];
        dateFields.forEach(field => {
          if (updateObj[field]) {
            // Convert to UTC
            updateObj[field] = new Date(updateObj[field]).getTime() - new Date().getTimezoneOffset() * 60000;
          }
        });
    
        // Handle empty delivery instructions
        if (updateObj.hasOwnProperty("del_inst") && updateObj.del_inst.length === 0) {
          updateObj.del_inst = "  "; 
        }
        
        console.log("updateOrderFields - Full updateObj:", JSON.stringify(updateObj));
        console.log("updateOrderFields - CUST_REF value:", updateObj.CUST_REF);
    
        httpRequest.updateJobFields(accessToken, updateObj)
          .then((body) => {
            console.log("updateJobFields response:", JSON.stringify(body.data));
            return httpRequest.updateJobRateFields(accessToken, updateObj);
          })
          .then((body) => {
            console.log("updateJobRateFields response:", JSON.stringify(body.data));
            setOpenUpdatingDialog(true);
            loadTripDetails(jobId);
          })
          .catch(err => {
            console.error("Failed to update job field:", err);
            setUpdatingItmeStr(`Error updating job: ${err.message}`);
            setOpenUpdatingDialog(true);
          });
      } catch (e) {
        console.error("updateOrderFields exception:", e);
        setUpdatingItmeStr(`Error: ${e.message}`);
        setOpenUpdatingDialog(true);
      }
    };
  
    const postUpdatedReferences = (jobID) => {
      try {
        if (Object.keys(updatingReferences).length > 0) {
          Object.entries(updatingReferences).forEach(([key, value]) => {
            const reference = {
              refID: key,
              value: value,
            };
            console.log("postUpdatedReferences- " + JSON.stringify(reference));
            httpRequest.postReferenceFields(accessToken, jobID, reference)
              .then((body) => {
                console.log("postReferenceFields", JSON.stringify(body.data));
              })
              .catch(err => {
                console.error("Failed to update reference field:", err);
              });
          });
        }
      } catch (e) {
        console.error("postUpdatedReferences exception", e);
      }
    };
  
    const getTripFunctions = (jobId) => {
      httpRequest.getTripFunctions(accessToken, jobId).then((response) => {
        try {
          let viewFuncs = [];
          let dispatchFuncs = [];
          let serviceFuncs = [];
          let ratingFuncs = [];    
          response = response.data;
          for (const x in response[1][0]) {
            let row = { functionText: x };
            if (x !== "rating") {
              if (x === "Drivers") 
                row.functionText = "Drivers&rating";                  
              viewFuncs.push(row);
            }
          }
          response[2].map((row) => {
            if (row.section.includes("Dispatch")) 
              dispatchFuncs.push(row);                
            if (row.section.includes("Service")) 
              serviceFuncs.push(row);                
            if (row.section.includes("Rating")) 
              ratingFuncs.push(row);                 
          });
          setViewFunctions(viewFuncs);
          setDispatchFunctions(dispatchFuncs);
          setServiceFunctions(serviceFuncs);
          setRatingFunctions(ratingFuncs);    
        } catch (e) {console.log("getTripFunctions exception", e);}
      });
    };
  
    const toggleGallery = (visible) => {
      console.log("toggleGallery - " + visible);
      setGalleryVisible(visible);
      if (!visible && isUploadFile)
        loadTripDetails(jobId);
    };
  
    const currentDateTime = () => {
      var current = new Date();
      return new Date(current.getFullYear(), current.getMonth(), current.getDate(), current.getHours(),current.getMinutes());
    };
  
    const performTripFunction = (action, category) => {
      try {
        if (category === "View") {
          setSelectedTab(
            action.functionText === "map" ? 0 : 
            action.functionText === "pictures" ? 1 : 
            action.functionText === "History" ? 2 :
            action.functionText === "Tags" ? 4 : 
            action.functionText === "Drivers&rating" ? 3 : 0
          );
          setIsMenuOpen(false);
        } else {
          const menuOptions = 
            category === "Dispatch" ? dispatchFunctions : 
            category === "Service" ? serviceFunctions : 
            ratingFunctions;
          
          const selectedFunction = menuOptions.find(row => row.functionText === action.functionText);
          if (selectedFunction) {
            processFunction(selectedFunction, category);
          }
        }
      } catch (e) {
        console.log("performTripFunction exception:", e);
      }
    };
  
    const processFunction = (row, category) => {
      console.log("Entering processFunction with row:", row, "and category:", category);
      if (row.paramaters !== null && row.paramaters.length !== 0) {
        try {
          row.paramaters = row.paramaters.split("[").join("{").split("]").join("}");         
          row.paramaters = row.paramaters.replace('"values":{', '"values":[');
          row.paramaters = row.paramaters.replace('},"default"', '],"default"');
          const obj = JSON.parse(row.paramaters);
          const functionParamsList = Object.keys(obj).map(key => {
            const funcObj = obj[key];
            return {
              label: key,
              prompt: funcObj.prompt,
              type: funcObj.type,
              length: funcObj.length,
              mo: funcObj.mo,
              default: funcObj.default,
              list: funcObj.values,
              value: "",
            };
          });
    
          setTripFunction({
            parameters: functionParamsList,
            procedureName: row.procedureName,
            hasOverride: row.hasOverride,
            refreshAfter: row.RefreshAfter,
            jobId: jobId,
            accessToken: accessToken,
            functionText: row.functionText
          });
          setDialogIsOpen(true);
        } catch (e) {
          console.log("Error parsing parameters:", e);
        }
      } else {
        httpRequest.doTripFunction(accessToken, jobId, [], row.procedureName).then(
          async (body) => {
            console.log("procedure is done", body.data);
            if (body.data && body.data.length > 0) {
              const res = body.data[0];
              console.log(res.ResultCode + "---" + res.Result);
              if (res.ResultCode === 10) {
                setOverrideDialogMessage(res.Result);
                setOverrideOpenDialog(true);
              } else {
                setMessageInfo(`${row.procedureName} procedure is done!`);
                setOpenSnack(true);
                if (row.RefreshAfter) {
                  loadTripDetails(jobId);
                  if (category === "Rating") {
                    NoticeEmitter.emit("refreshRating", "");
                  }
                } else {
                  getTripFunctions(jobId);
                }
              }
            } else {
              setMessageInfo(`${row.procedureName} procedure is done!`);
              setOpenSnack(true);
              if (row.RefreshAfter) {
                loadTripDetails(jobId);
                if (category === "Rating") {
                  NoticeEmitter.emit("refreshRating", "");
                }
              } else {
                getTripFunctions(jobId);
              }
            }
          },
          function errorCallback(err) {
            alert("Failed to finish procedure " + row.procedureName + ". Error is: " + err);
          }
        );
      }
    };
  
    const postFunctionParameters = (paramsList, procedureName, isOverride, refreshAfter, functionText) => {
      setTripFunction({
        parameters: paramsList,
        procedureName: procedureName,
        isOverride: isOverride,
        refreshAfter: refreshAfter,
        jobId: jobId,
        accessToken: accessToken,
        functionText: functionText
      });
      setDialogIsOpen(true);       
    };
  
    const handleDialogClose = () => {
      setDialogIsOpen(false);
    };
  
    const getAdornmentStyle = (position) => {
      return position === "start" 
        ? { paddingBottom: '13px' }
        : { paddingTop: '' };
    };
  
    const renderFieldWithInputAdornment = (val, sign, position) => {
      return (
        <InfoField 
          value={val}
          InputProps={{
            [position === "start" ? "startAdornment" : "endAdornment"]: (
              <InputAdornment position={position} style={getAdornmentStyle(position)}>
                {sign}
              </InputAdornment>
            )
          }}
        />
      );
    };
  
    const renderEditFieldWithInputAdornment = (name, type, val, sign, position, onChangeHandler, onBlurHandler) => {
      return (
        <TextField 
          name={name} 
          type={type}
          defaultValue={val}
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
          fullWidth
          InputProps={{
            [position === "start" ? "startAdornment" : "endAdornment"]: (
              <InputAdornment position={position} style={getAdornmentStyle(position)}>
                {sign}
              </InputAdornment>
            )
          }}
        />
      );
    };
  
    const renderDropdown = (name, value, options, keyName, labelName, size) => {
      console.log("renderDropdown called with:", { name, value, options, keyName, labelName, size });
      
      if (!Array.isArray(options) || options.length === 0) {
        console.warn(`No options provided for dropdown: ${name}`);
        return <InfoField value={value || 'N/A'} />;
      }
      
      return (
        <TextField
          name={name}
          variant="outlined"
          select
          size="small"
          className="editableJobField"
          value={value || ''}
          style={{ width: size }}
          onChange={(event) => {
            handleValueChange({
              target: {
                name: name,
                value: event.target.value
              }
            });
          }}
        >
          {options.map((item, index) => {
            if (!item || typeof item !== 'object') {
              console.warn(`Invalid item at index ${index} for dropdown: ${name}`);
              return null;
            }
            return (
              <MenuItem key={item[keyName] || index} value={item[keyName] || ''}>
                {item[labelName] || ''}
              </MenuItem>
            );
          })}
        </TextField>
      );
    };
     
    const handleValueChange = useCallback((event) => {
      const { name, value } = event.target;
      console.log(`handleValueChange called - name: ${name}, value: ${value}`);
      
      setUpdatingItem(prevState => {
        const newState = { ...prevState, [name]: value };
        console.log("updatingItem after setUpdatingItem:", newState);
        return newState;
      });
      
      if (name === "CUST_REF") {
        setUpdatingReferences(prevState => {
          const newState = { ...prevState, [name]: value };
          console.log("updatingReferences after setUpdatingReferences:", newState);
          return newState;
        });
        setOrder(prevOrder => {
          const newOrder = { ...prevOrder, [name]: value };
          console.log("order after setOrder:", newOrder);
          return newOrder;
        });
      }
    }, []);
    
    const handleGPSChange = (lat, lng, source) => {
      // Clean and parse the coordinates
      const cleanLat = typeof lat === 'string' ? parseFloat(lat.replace(',', '')) : lat;
      const cleanLng = typeof lng === 'string' ? parseFloat(lng.replace(',', '')) : lng;
      
      if (!isNaN(cleanLat) && !isNaN(cleanLng)) {
        setLatestUpdatedPostion({
          lat: cleanLat,
          lng: cleanLng
        });
        setUpdateGPSSource(source);
      }
    };
    
    const updateGPS = () => {
      if (!latestUpdatedPostion || isNaN(latestUpdatedPostion.lat) || isNaN(latestUpdatedPostion.lng)) {
        console.error('Invalid GPS coordinates');
        return;
      }
    
      const paramsList = [
        {
          label: "lat",
          prompt: "Latitude",
          type: "decimal",
          mo: "Latitude",
          precision: "6",
          value: latestUpdatedPostion.lat.toFixed(6),
        },
        {
          label: "long",
          prompt: "Longitude",
          type: "decimal",
          mo: "Longitude",
          precision: "6",
          value: latestUpdatedPostion.lng.toFixed(6),
        }
      ];
    
      const spName = addr.includes("delivery") ? "in5.trip_updateConGPS" : "in5.trip_updateShipGPS";
      
      httpRequest.doTripFunction(accessToken, jobId, paramsList, spName)
        .then(response => {
          console.log("GPS Update response:", response.data);
          let _order = {...order};
          if (addr.includes("delivery")) {
            _order.cLat = latestUpdatedPostion.lat.toFixed(6);
            _order.cLong = latestUpdatedPostion.lng.toFixed(6);
          } else {
            _order.sLat = latestUpdatedPostion.lat.toFixed(6);
            _order.sLong = latestUpdatedPostion.lng.toFixed(6);
          }
          setOrder(_order);
        })
        .catch(error => {
          console.error("Failed to update GPS:", error);
        });
    };
  
    const updateOrder = () => {
      try {
        console.log("======updateOrder- " + JSON.stringify(updatingItem));
        if (updatingItem.hasOwnProperty("caller") && updatingItem.caller === '') {
          setUpdatingItmeStr("Please input caller!");
          setOpenUpdatingDialog(true);
          return;
        }
        setIsEdit(false);         
        let keyArry = Object.keys(updatingItem);
        let info = "";  
        keyArry.forEach((item) => {
          switch(item) {
            case "del_inst": info += "Instruction,"; break;
            case "pieces": info += "Piece number,"; break;
            case "emailConf": info += "Email,"; break;
            case "sigReq": info += "No Signature Required,"; break;
            case "dtCallIn": 
            case "readyAt": info += "Ready At,"; break;
            case "dtDCT": info += "DCT,"; break;
            case "Desc": info += "Package Type,"; break;
            default: info += item + ",";
          }
        });         
        if (Object.keys(updatingReferences).length > 0) {
          info += "Reference";
        }
        setUpdatingItmeStr(`Updated items: ${info}`);
    
        if (updatingItem.readyAt) {
          updatingItem.dtCallIn = updatingItem.readyAt;
          delete updatingItem.readyAt;
        }
    
        console.log("Sending update with:", updatingItem);
        
        const rateAdjustmentFields = ['dtCallIn', 'pickupBy', 'dontDeliveryBefore', 'dtDCT'];
        const needsRateAdjustment = rateAdjustmentFields.some(field => updatingItem.hasOwnProperty(field));
    
        if (needsRateAdjustment) {
          setRateAdjustmentDialog({
            isOpen: true,
            changeRate: true,
            field: rateAdjustmentFields.find(field => updatingItem.hasOwnProperty(field))
          });
        } else {
          updateOrderFields(jobId);
        }
      } catch (e) {
        console.log(`updateOrder exception- ${e}`);
      }
    };
  
    const handlePiecesChange = (event) => {
      if (event.target.value < 0) 
        event.target.value = 0;      
      if (event.target.value.toString().length > 3) {
        let temp = event.target.value.toString().substring(0, 3);
        event.target.value = temp;
      }
    };
  
    const handleMaxLengthFiveChange = (event) => {
      if (event.target.value < 0) 
        event.target.value = 0;        
      if (event.target.value.toString().length > 5) {
        let temp = event.target.value.toString().substring(0, 5);
        event.target.value = temp;
      }
    };
  
    const setUpdatedRefValue = useCallback((name, value) => {
      console.log(`setUpdatedRefValue name is ${name} value is ${value}`);
      setUpdatingReferences(prevState => ({
        ...prevState,
        [name]: value,
      }));
      
      if (name === 'CUST_REF') {
        setOrder(prevOrder => ({
          ...prevOrder,
          CUST_REF: value
        }));
      }
    }, []);
  
    const handlerEditClick = () => {
      console.log("handlerEditClick currentRate is " + JSON.stringify(currentRate));
      setIsEdit(true);
      getUserEmails();
      getPackageList();
      getReferenceList();
      setUpdatingItem({});
      setUpdatingReferences({});       
    };
  
    const updateOrderPieces = useCallback((newPieces, newWeight) => {
      setOrder(prevOrder => ({
        ...prevOrder,
        Pieces: Math.max(prevOrder.Pieces || 0, newPieces),
        weight: Math.max(prevOrder.weight || 0, newWeight)
      }));
    }, []);
  
    const handleOverrideFunction = () => {
      console.log("handleOverrideFunction called");
      const paramsList = [
        {
          label: "override",
          prompt: "",
          type: "bit",
          length: 1,
          mo: "override",
          value: 1,
        }
      ];
      
      httpRequest.doTripFunction(accessToken, jobId, paramsList, tripFunction.procedureName)
        .then(async (body) => {
          console.log("Override function response:", body);
          setMessageInfo(`${tripFunction.procedureName} procedure is done for job ${jobId}`);       
          setOpenSnack(true);
          NoticeEmitter.emit("resetTable", "");
          setOverrideOpenDialog(false);
          if (tripFunction.refreshAfter) {
            loadTripDetails(jobId);
          }
        },
        function errorCallback(err) {
          console.error("Failed to finish override procedure:", err);
          alert("Failed to finish procedure. Error is : " + err);
        }
      );
    };
  
    return (
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        height: `calc(100%-64px)`, 
        overflow: 'hidden',
        position: 'fixed',
        top: '64px',
        left: 0,
        right: 0,
        bottom: 0
      }}>
        <div style={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
          <SideMenu
            isDrawerOpen={isDrawerOpen}
            toggleDrawer={toggleDrawer}
            navigation={navigation}
            performTripFunction={performTripFunction}
        />
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
          <LatticeStatusBar
            order={order}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            updateOrder={updateOrder}
            jobId={jobId}
            companyLogo={companyLogo}
            handlerEditClick={handlerEditClick}
            orderSCT={orderSCT}
            setOrderSCT={setOrderSCT}
            setUpdatingItem={setUpdatingItem}
            orderDTDCT={orderDTDCT}
            setOrderDTDCT={setOrderDTDCT}
            orderDTCallIn={orderDTCallIn}
            setOrderDTCallIn={setOrderDTCallIn}
            dtPickupBy={dtPickupBy}
            setDTPickupBy={setDTPickupBy}
            dtDontDeliveryBefore={dtDontDeliveryBefore}
            setDTDontDeliveryBefore={setDTDontDeliveryBefore}
            setAdjustRate={setAdjustRate}
            isLattice={isLattice}
            setRateAdjustmentDialog={setRateAdjustmentDialog}
          />
          <div style={{ flex: 1, overflowY: 'auto', overflowX: 'hidden' }}>
            <div style={{ padding: '20px' }}>
              <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{ width: "100%", height: 'auto', overflow: 'visible' }} spacing={1}>
              <Grid item container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2} style={{ margin: "0", width: "100%" }} sm={7} lg={9}>
                  <Grid item xs={12}>
                    {TripServiceNoteList.length > 0 && rendAccountServiceNotes()}
                  </Grid>
                  <Grid item xs={11} lg={6} className="shipperCardBlock darkCardBlock">
                    {order && (
                      <AddressCard
                        jobId={jobId}
                        type="shipper"
                        accessToken={accessToken}
                        accountNumber={order?.ACCOUNT}
                        coord={{ lat: order?.sLat, long: order?.sLong }}
                        data={{
                          companyName: order?.sCompanyName,
                          address: order?.sAddress,
                          suite: order?.sSuite,
                          quad: order?.sQuad,
                          city: order?.sCity,
                          prov: order?.sProv,
                          country: order?.sCountry,
                          postal: order?.sPostal,
                          contactName: order?.sContact,
                          contactPhone: order?.sPhone,
                          contactID: order?.shipContactID,
                          addressID: order?.shipAddressID,
                          lat: order?.sLat,
                          long: order?.sLong,
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={11} lg={6} className="consigneeCardBlock darkCardBlock">
                    {order && (
                      <AddressCard
                        jobId={jobId}
                        type="consignee"
                        accessToken={accessToken}
                        accountNumber={order?.ACCOUNT}
                        coord={{ lat: order?.cLat, long: order?.cLong }}
                        data={{
                          companyName: order?.cCompanyName,
                          address: order?.cAddress,
                          suite: order?.cSuite,
                          quad: order?.cQuad,
                          city: order?.cCity,
                          prov: order?.cProv,
                          country: order?.cCountry,
                          postal: order?.cPostal,
                          contactName: order?.cContact,
                          contactPhone: order?.cPhone,
                          contactID: order?.conContactID,
                          addressID: order?.conAddressID,
                          lat: order?.cLat,
                          long: order?.cLong,
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} className="tab-container" style={{ overflow: 'visible' }}>
                    <Tabs
                      dataSource={viewTabs.map(tab => ({
                        ...tab,
                        disabled: !isLattice && (tab.id === 4 || tab.id === 5)
                      }))}
                      selectedIndex={selectedTab}
                      onItemClick={(e) => {
                        if (!e.itemData.disabled) {
                          updateView(e.itemData.id);
                        }
                      }}
                      itemRender={(item, index) => (
                        <Tooltip
                          title={item.disabled ? "Only Available on Lattice Trip" : ""}
                          arrow
                          placement="top"
                        >
                          <div className={`tab ${selectedTab === index ? 'tab-selected' : ''} ${item.disabled ? 'tab-disabled' : ''}`}>
                            {item.text}
                          </div>
                        </Tooltip>
                      )}
                    />
                  </Grid>
    
                  <Grid item xs={12}>
                    {selectedTab === 0 && (
                      <>
                        <MapView
                          view={view}
                          setView={setView}
                          viewAddress={viewAddress}
                          order={order}
                          addr={addr}
                          setAddr={setAddr}
                          photoArray={photoArray}
                          handleGPSChange={handleGPSChange}
                          gpsData={gpsData}
                          GPSUpdateButtonText={GPSUpdateButtonText}
                          setLocationUpdateDialog={setLocationUpdateDialog}
                        />
                        <OrderSteps steps={steps} />
                      </>
                    )}
                    {selectedTab === 1 && (
                      <PicturesTab 
                        viewAddress={viewAddress}
                        addr={addr}
                        order={order}
                        photoArray={photoArray}
                        isStreetViewExpanded={isStreetViewExpanded}
                        setIsStreetViewExpanded={setIsStreetViewExpanded}
                        handleGPSChange={handleGPSChange}
                        toggleGallery={toggleGallery}
                        galleryVisible={galleryVisible}
                        accessToken={accessToken}
                        jobId={jobId}
                        setIsUploadFile={setIsUploadFile}
                        classes={classes}
                      />
                    )}
                    {selectedTab === 2 && <TripHistoryList jobId={jobId} accessToken={accessToken} />}
                    {selectedTab === 3 && (
                      <>
                        <Grid item md={12}><TripDriverList jobId={jobId} accessToken={accessToken} /></Grid>
                        <Grid item md={12}><TripRatingList jobId={jobId} accessToken={accessToken} isLattice={isLattice} RateSummaryLine={order.RateSummaryLine} /></Grid>
                      </>
                    )}
                    {isLattice && selectedTab === 4 && <TagsView jobId={jobId} accessToken={accessToken} loadTripTags={loadTripTags} />}
                    {isLattice && selectedTab === 5 && (
                      <TripPiecesList 
                        jobId={jobId} 
                        accessToken={accessToken} 
                        updateOrderPieces={updateOrderPieces}
                      />
                    )}
                  </Grid>
                </Grid>  
                          
                <OrderDetails 
                  order={order}
                  isEdit={isEdit}
                  currentAccount={currentAccount}
                  accountList={accountList}
                  callerList={callerList}
                  handleValueChange={handleValueChange}
                  renderDropdown={renderDropdown}
                  renderFieldWithInputAdornment={renderFieldWithInputAdornment}
                  renderEditFieldWithInputAdornment={renderEditFieldWithInputAdornment}
                  packageList={packageList}
                  rateChoices={rateChoices}
                  currentRate={currentRate}
                  setCurrentRate={setCurrentRate}
                  removeCallerFromList={removeCallerFromList}
                  getUserEmails={getUserEmails}
                  handlePiecesChange={handlePiecesChange}
                  handleMaxLengthFiveChange={handleMaxLengthFiveChange}
                  tagList={tagList}
                  isLattice={isLattice}
                  jobDetailLabelStyle={classes.jobDetailLabel}
                  setRateAdjustmentDialog={setRateAdjustmentDialog}
                  referenceList={referenceList}
                  setUpdatedRefValue={setUpdatedRefValue}
                  availableDescriptions={availableDescriptions}
                  loadAvailableDescriptions={loadAvailableDescriptions}
                  handleDescriptionUpdate={handleDescriptionUpdate}
                  TripServiceAccountNotes={TripServiceAccountNotes}
                />
                
                <Grid item xs={12} style={{ marginTop: "16px", textAlign: "center" }}>
                  {!isLattice && jobId && (
                    <Button
                      component={Link}
                      to={`/legacyJobDetail/${jobId}`}
                      variant="contained"
                      color="primary"
                      style={{ textTransform: 'none' }}
                    >
                      View in Legacy Job Details Page
                    </Button>
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>

      <DialogManager 
        openUpdatingDialog={openUpdatingDialog}
        locationUpdateDialog={locationUpdateDialog}
        updatingItemStr={updatingItmeStr}
        updateGPSSource={updateGPSSource}
        latestUpdatedPostion={latestUpdatedPostion}
        setOpenUpdatingDialog={setOpenUpdatingDialog}
        setLocationUpdateDialog={setLocationUpdateDialog}
        loadTripDetails={loadTripDetails}
        updateGPS={updateGPS}
        jobId={jobId}
        dialogIsOpen={dialogIsOpen}
        handleDialogClose={handleDialogClose}
        rateAdjustmentDialog={rateAdjustmentDialog}
        setRateAdjustmentDialog={setRateAdjustmentDialog}
        handleRateAdjustment={handleRateAdjustment}
        setMessageInfo={setMessageInfo} 
        setOpenSnack={setOpenSnack} 
        updateOrderFields={updateOrderFields}
        tripFunction={{
          ...tripFunction,
          onOperationComplete: () => {
            loadTripDetails(jobId);
          }
        }}
      />

      <SnackbarManager 
        openSnack={openSnack}
        setOpenSnack={setOpenSnack}
        messageInfo={messageInfo}
      />

      <CustomDialog
        open={overrideOpenDialog}
        onClose={() => setOverrideOpenDialog(false)}
        hasCloseBtn={true}
        dialogMessage={overrideDialogMessage}
        dialogTitle="Warning"
        dialogAction={() => {
          httpRequest.doTripFunction(accessToken, jobId, [{ label: "override", value: 1 }], tripFunction.procedureName)
            .then(() => {
              setMessageInfo(`${tripFunction.procedureName} procedure is done!`);
              setOpenSnack(true);
              if (tripFunction.refreshAfter) {
                loadTripDetails(jobId);
              }
              setOverrideOpenDialog(false);
            })
            .catch((err) => {
              alert("Failed to finish override procedure. Error is: " + err);
            });
        }}
      />

      <TripFunctionDialog
        open={dialogIsOpen}
        handleDialogClose={handleDialogClose}
        tripFunction={tripFunction}
        onOperationComplete={(message) => {
          console.log("Operation complete with message:", message);  // Add this
          setMessageInfo(message);
          console.log("Set message info to:", message);  // Add this
          setOpenSnack(true);
          console.log("Set snack to open");  // Add this
          loadTripDetails(jobId);
        }}
      />

      <footer style={{ flexShrink: 0 }}>
        <Copyright textColor='white'/>
      </footer>
    </div>
  );
}

export default LatticeJobDetail;