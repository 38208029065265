import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import {Tooltip} from '@mui/material';
import "animate.css/animate.css";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';

const TagDatetimeField = (props) => {

  let d = new Date(props.value);
  d.setTime(d.getTime() - new Date().getTimezoneOffset() * 60000);
  const [value, setValue] = useState(dayjs(d.toLocaleString()));

  //const [value, setValue] = useState(dayjs(props.value));
  ////const [value, setValue] = useState(dayjs(Date.now()));
  const tipText =  props.prompt?props.prompt:''; 
  useEffect(() => {
    // console.log("------TagDatetimeField-----" + props.value);    
    // console.log("------TagDatetimeField---toLocaleTimeString--" + new Date().getTimezoneOffset());   
    // console.log("------TagDatetimeField-----" + d.toLocaleString());
  },[props.value]);   


  const handleChange = (newValue) => {
    console.log("TagDatetimeField handleChange-" + JSON.stringify(newValue));
    props.onChange({
      name: props.param,
      value: newValue,
    });
    setValue(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <Tooltip title={tipText} placement="top" arrow>
           <div className="tagField">
           <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker 
                    fullWidth
                    size='small'
                    format="YYYY/MM/DD HH:mm"  
                    label={props.label}
                    value={value}
                    sx={{ width: 280}}                  
                    onChange={handleChange}                    
                />
            </LocalizationProvider>
            </div>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default TagDatetimeField;
