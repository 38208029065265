import React from 'react';
import { useState, useEffect, useCallback} from 'react';
import { useHistory  } from 'react-router-dom';
import Copyright from "../Copyright";
import DashboardBar from './DashboardBar';
import DashboardDatagrid from './DashboardDatagrid';
import DashboardMessageDialog from './DashboardSendMessageDialog';
import "date-fns";
import HttpRequest from "../HttpService";
import Moment from "moment";
import CustomDialog from "../components/dialog/Dialog";

export default function Dashboard(props) { 
    const history = useHistory(); 

    const accessToken = localStorage.getItem("access_token");
    const [companyID, setCompanyID] = useState(props.companyID);
    const [tripStats, setTripStates] = useState({});
    const [messages, setMessages] =  useState([]);
    const [orgMessages, setOrgMesages] = useState([]);
    const [msgBoxOpen, setMsgBoxOpen] = useState(false);
    const [msgToDriver, setMsgToDriver] = useState("");
    const [selectedDriver, setSelectedDriver] = useState("");
    const [serviceChecked, setServiceChecked] = useState(false);
    const [dispatchChecked, setDispatchChecked] = useState(false);
    const [openWarningDialog, setOpenWarningDialog] = useState(false); 
 
    useEffect(() => {       
        pollData();      
        const interval = setInterval(() => {
            pollData();
          }, 60000);
          return () => clearInterval(interval);  
    }, []);

    useEffect(() => {
        setCompanyID(props.companyID);
    }, [props.companyID]);

    const pollData = useCallback(async () => {
        try {

            const resDrv = await HttpRequest.getDriverStats(accessToken, companyID);
            if(resDrv.data){
                console.log("getDriverStats:" + JSON.stringify(resDrv.data));
                setTripStates((prevState) => {
                    return {
                        ...prevState,
                        DriversLoggedIn: resDrv.data.length,
                    };
                });
                
            }  
            const statsRes =  await HttpRequest.getTripStats(accessToken, companyID);
            if(statsRes.data){
                console.log("getTripStats:" + JSON.stringify(statsRes.data[0]));
                setTripStates((prevState) => {
                    return {
                        ...prevState,
                        ...statsRes.data[0]
                    };
                }); 
            }    
                   
            const serviceMsgRes =  await HttpRequest.getServiceMessages(accessToken);
            if(serviceMsgRes.data){
                console.log("getTripStats:" + JSON.stringify(serviceMsgRes.data));
                let assignTripsCount = 0;
                let mList =  serviceMsgRes.data.map((obj,index) => {
                    if(obj.messageText.includes("Trip Requires Dispatch (no Auto)"))
                        assignTripsCount++;   
                    return{
                        ...obj,
                        datestamp :Moment(obj.datestamp).format("YYYY-MM-DD HH:mm"),
                        id:index
                    }
                });
                setOrgMesages(mList);
                setTripStates((prevState) => {
                    return {
                        ...prevState,
                        ServiceAlerts: mList.length,
                        tripAssignStats:assignTripsCount
                    };
                });

                if ((serviceChecked && dispatchChecked) || (!serviceChecked && !dispatchChecked)) {
                    mList.sort((a, b) =>
                      a.priority > b.priority
                        ? -1
                        : a.priority === b.priority && a.datestamp < b.datestamp
                        ? -1
                        : 1
                    );
                    setMessages(mList)                    
                } else {
                    //console.log("getServiceMessages:-" + this.state.serviceChecked + "---" + this.state.dispatchChecked);
                    if (serviceChecked && !dispatchChecked)
                      mList = mList.filter((x) => x.messageType === 1);
                    else if (!serviceChecked && dispatchChecked)
                      mList = mList.filter((x) => x.messageType === 2);
                    mList.sort((a, b) =>
                      a.priority > b.priority
                        ? -1
                        : a.priority === b.priority && a.datestamp < b.datestamp
                        ? -1
                        : 1
                    );
                    setMessages(mList) 
                }
            }           
        } catch (error) {
          console.log(error);
        }
      }, []);


    useEffect(() => {
        const msgList = [];
        orgMessages.forEach((data) => {
            if ((serviceChecked && dispatchChecked) || (!serviceChecked && !dispatchChecked)) {
                msgList.push(data);
            } else if (serviceChecked && !dispatchChecked &&  data.messageType === 1) {
                msgList.push(data);
            } else if (!serviceChecked && dispatchChecked && data.messageType === 2) {
                msgList.push(data);
            }
        })
            msgList.sort((a, b) =>
                a.priority > b.priority
                ? -1
                : a.priority === b.priority && a.datestamp > b.datestamp
                ? -1
                : 1
            );
            setMessages(msgList)

    }, [serviceChecked,dispatchChecked]);

    
    const removeMessage = (serviceQueID) => {
        const tempList = messages.filter((object) => {
          return object.serviceQueID !== serviceQueID;
        });
        setMessages(tempList)       
    };


    const messageSubmit = () => {
        console.log("sendDriverMessage -" + selectedDriver);
        console.log("sendDriverMessage msg is -" + msgToDriver);
        setMsgBoxOpen(false);
    
        HttpRequest.sendDriverMessage(accessToken,selectedDriver,companyID, undefined,msgToDriver).then(async (body) => {
            console.log("sendDriverMessage -" + JSON.stringify(body.data));
          },
          function errorCallback(err) {
            alert("Failed to sendDriverMessage. Error is : " + err);
          }
        );
    };
   
    
    return (
        <div className="root">
        <DashboardBar 
           tripStats={tripStats} 
           accessToken={accessToken} 
           companyID={companyID}
           serviceChecked={serviceChecked}
           dispatchChecked={dispatchChecked}
           setServiceChecked ={setServiceChecked}
           setDispatchChecked= {setDispatchChecked}           
        />
        <DashboardDatagrid 
            messages={messages}  
            accessToken={accessToken} 
            pollData = {pollData}
            setSelectedDriver={setSelectedDriver}
            removeMessage={removeMessage}
            setMsgBoxOpen={setMsgBoxOpen}
            setOpenWarningDialog={setOpenWarningDialog} />
        <DashboardMessageDialog  msgBoxOpen={msgBoxOpen}  setMsgBoxOpen={setMsgBoxOpen} setMsgToDriver={setMsgToDriver} messageSubmit={messageSubmit}/>
        <CustomDialog
            open={openWarningDialog}
            onClose={() => {setOpenWarningDialog(false)}}
            hasCloseBtn={false}
            dialogMessage="No JobID Attached"
            dialogTitle="Warning"
            dialogAction={() => {
                setOpenWarningDialog(false)
            }}
          />       
        <footer>
          <Copyright textColor='white'/>
        </footer>
        </div>
    )
}