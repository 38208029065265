import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import {Tooltip,Typography} from '@mui/material';
import "animate.css/animate.css";
import MemoInput from "./MemoInput";

const TagMemoFields = (props) => {
  const [textValue, setTextValue] = useState("");
  const tipText =  props.prompt?props.prompt:'';

  useEffect(() => {
    //console.log("========TagTextFielsEditable============" + props.value);
    setTextValue(props.value)
  },[props.value]);

  
  const handleChange = (event) => {
    if (props.maxLength === undefined || event.target.value.length <= props.maxLength) {
      setTextValue(event.target.value);
      props.onChange({
        name: props.param,
        value: event.target.value,
      });
    }
  };


  const createHelperText = () => {
    if (props.maxLength === undefined) {
      return '';
    }
    return `There are ${props.maxLength - textValue.length} characters available to enter.`
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <Typography variant="caption" style={{  display: 'block' }}>
          {props.label}
        </Typography>
        <Tooltip title={tipText} placement="top" arrow>
        <MemoInput
          type="textarea"
          iconOne="ClearIcon"
          placeholder={props.prompt}
          inputType="text"
          inputLength={props.maxLength===undefined? 200: props.maxLength}
          minRows={2}
          label= {''}
          name= {props.param}
          iconOneTooltip="Clear"
          onIconOneClick={(event) => {
            handleChange({target:{
                name: props.param,
                value: ''
            }});
            props.onAlert.removeAlert(props.label);
            setTextValue('');
          }}
          value={textValue}
          onChange={handleChange}
          labelSize={0}
          hideLabel={true}
          //onBlur={handleChange}
        />   
        </Tooltip>        
      </Grid>
    </Grid>
  );
};

export default TagMemoFields;
