import "./App.css";
import 'devextreme/dist/css/dx.light.css';



import React, { useState, useEffect } from "react";
import Service from "./service";
import Search from "./search";
import DriverGoogle from "./driverGoogle";
import Driver from "./driver/DriverPanel";
import DashBoard from "./dashboard/Dashboard";
import OrdersMap from "./search/ordersMap";
import JobDetail from "./jobDetail/JobDetail";
import LatticeJobDetail from "./jobDetail/LatticeJobDetail";
import MessageHistory from "./messageHistory";
import AccountList from "./accountList";
import Login from "./login";
import TestPage from './testPage/TestPage';


import packageJson from '../package.json'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Redirect 
} from "react-router-dom";

import HttpRequest from "./HttpService";
import { theme } from "./theme";
import { ThemeProvider } from "@material-ui/styles";
import NavigationBar from "./NavigationBar";
import WDWebview from "./WDWebview";

import NoticeEmitter from "./NoticeUtil";
import { Helmet, HelmetProvider } from "react-helmet-async";

import ReHelmet from "./Helmet";

import { LicenseInfo } from "@mui/x-license-pro";

//const accessToken = "3A908632-21A0-437C-99DA-735AC86500D0";
function App() {
  const [title, setTitle] = useState("DirectIt INX");
  const [accessToken, setAccessToken] = useState("");
  const [loginUser, setLoginUser] = useState({});
  const [companyList, setCompanyList] = useState([]);

  const [accountList, setAccountList] = useState([]);
  const [accountNavList, setAccountNavList] = useState([]);

  const [companyID, setCompanyID] = useState(
    localStorage.getItem("companyID")
      ? parseInt(localStorage.getItem("companyID"))
      : 1
  );
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [jobSearchInput, setJobSearchInput] = useState("");
  const [accountInput, setAccountInput] = useState("");
  const [jobSearchChoice, setJobSearchChoice] = useState("");
  const [accountChoice, setAccountChoice] = useState("");

  // const [trips, setTrips] = useState([]);

  const [searchParams, setSearchParams] = useState({
    account: "",
    order: "",
    billing: "",
    caller: "",
    address: "",
    serviceArea: "",
    serviceTime: "",
    number: "",
    shipperToFrom: "",
    phone: "",
    name: "",
    date: new Date(),
    startDate: new Date(),
    endDate: new Date(),
    searchByRange: false,
    driverSearchType: "any",
    location: -1,
    status: -1,
    activeTrip: false,
    exception: false,
    company: 0,
  });

  //set the following element to fetch messages from the backend
  // const navBarMessages = [
  //   "what if this message was really really really really long",
  //   "world",
  // ];
  const navBarMessages = [];

  NoticeEmitter.addListener("changeTitle", (data) => {
     setTitle("DirectIt INX " + data);
  });

  LicenseInfo.setLicenseKey(
    "afe4d7ca83409babbf306d5aa3e71ddaTz00OTE2OCxFPTE2OTIyMjQ4MzA2ODUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
  );

  useEffect(() => {

    let token = "";
    token = localStorage.getItem("access_token");
    console.log("token", token);
    if (token === null) {
      setIsAuthenticated(false);
    
    } else {
      let account = localStorage.getItem("currentAccount", "9910");  
      let webxURL = localStorage.getItem("web5URL", "");
      console.log("App webxURL-" + webxURL);
      
      const accounts = [];
      const accountsNav = [];
      HttpRequest.getAccountList(token).then(async function (body) {
        console.log("getAccountList", body);
        if (body.data==='' || body.data[0].ResultCode === -1) {
          localStorage.setItem("access_token", null);
          setIsAuthenticated(false);
          window.location.href = "/";
          return;
        }
        setIsAuthenticated(true);
        setAccessToken(token);
        await body.data.forEach((account) => {
          account.name = account.Shipper + "-" + account.account;
          account.value = account.account;
          accounts.push(account);
          if (account.Cnt > 0) accountsNav.push(account);
        });
        setAccountList(accounts);
        setAccountNavList(accountsNav);
       
        HttpRequest.getUserCompanyList(token).then(async function (body) {
          //console.log(body.data);
          setCompanyList(body.data);
          setCompanyID(
            localStorage.getItem("companyID")
              ? parseInt(localStorage.getItem("companyID"))
              : 1
          );
        });
      });


      if(webxURL === ""){
        HttpRequest.getAccountWebToken(token, account).then(async function (body) {
          try {
            console.log("App getAccountWebToken-" + JSON.stringify(body.data));
            let webURL = body.data[0].entryURL;
            //webURL = webURL.replace("ship", "webx");
  
            localStorage.setItem("web5URL", webURL);
            //localStorage.setItem('currentAccount','9910');
            localStorage.setItem("currentAccount", account);
          } catch (e) {
            console.log("getAccountWebToken exception:" + e);
          }
        });  
       }
    }   
    HttpRequest.getAPIVersion().then(async function (body) {
      console.log(body.data);
      localStorage.setItem("APIVersion", body.data.message.substring(body.data.message.indexOf("is") + 2));
      NoticeEmitter.emit('getAPIVersion', body.data.message.substring(body.data.message.indexOf("is") + 2));
    });
    
  }, []);



  useEffect(() => {
    const accounts = [];
    const accountsNav = [];
    HttpRequest.getAccountList(localStorage.getItem("access_token")).then(async function (body) {
      console.log("getAccountList", body);       
      await body.data.forEach((account) => {
        account.name = account.Shipper + "-" + account.account;
        account.value = account.account;
        accounts.push(account);
        if (account.Cnt > 0) accountsNav.push(account);
      });
      setAccountList(accounts);
      setAccountNavList(accountsNav);       
   
    });  
  }, [companyID]);


  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem("access_token");
    localStorage.removeItem("seviceSearchParameter");
    localStorage.removeItem("searchParameter");
    localStorage.removeItem("serviceMsg");
    localStorage.removeItem("dispatchMsg");
  };

  return (
    <HelmetProvider>
      {/* <Helmet>
        <title>{title ? title : "DirectIt INX"}</title>       
      </Helmet> */}
      <ReHelmet title={title ? title : "DirectIt INX"} />

      <ThemeProvider theme={theme}>
        <React.Fragment>
          <Router>
            <NavigationBar
              isLoggedIn={isAuthenticated}
              messages={navBarMessages}
              setAccount={setAccountInput}
              setJobSearch={setJobSearchInput}
              setAccountChoice={setAccountChoice}
              //setJobSearchChoice = {setJobSearchChoice}
              setCompanyID={setCompanyID}
              companyList={companyList}
              // accountList = {accountList}
              accountList={accountNavList}
              accessToken={accessToken}
              companyID={companyID}
              handlerLogout={handleLogout}
            />
            <Switch>
              <Route exact path="/">
                {!isAuthenticated && <Login />}
                {isAuthenticated && <WDWebview />}
              </Route>
              <Route exact path="/accountList">
                <AccountList
                  accountList={accountList}
                  accessToken={accessToken}
                  companyID={companyID}
                />
              </Route>
              <Route exact path="/driver">
                <Driver accessToken={accessToken} companyID={companyID} />
              </Route>
              <Route exact path="/driver/:drNum">
                <Driver accessToken={accessToken} companyID={companyID} />
              </Route>
              <Route exact path="/legacyJobDetail/:jobId">
                <JobDetail accessToken={accessToken} companyID={companyID} />
              </Route>
              <Route exact path="/jobDetail/:jobId/:view?">
                <LatticeJobDetail accessToken={accessToken} companyID={companyID} />
              </Route>
              <Route path="/LatticeJobDetail/:jobId/:view?">
                {({ match }) => {
                  const { jobId, view } = match.params;
                  return <Redirect to={`/jobDetail/${jobId}/${view}`} />;
                }}
              </Route>
              <Route exact path="/search">
                <Search
                  accessToken={accessToken}
                  searchParams={searchParams}
                  companyList={companyList}
                />
              </Route>
              <Route exact path="/orderListMap">
                <OrdersMap accessToken={accessToken} companyID={companyID} />
              </Route>
              <Route exact path="/web5">
                <WDWebview />
              </Route>
              <Route exact path="/messageHistory">
                <MessageHistory
                  accessToken={accessToken}
                  companyID={companyID}
                />
              </Route>
              <Route exact path="/service">
                <Service
                  accessToken={accessToken}
                  searchParams={searchParams}
                  companyList={companyList}
                />
              </Route>
              <Route exact path="/test">
                <TestPage accessToken={accessToken} companyID={companyID} />
              </Route>
              <Route exact path="/driverGoogle">
                <DriverGoogle accessToken={accessToken} companyID={companyID} />
              </Route>
              <Route exact path="/dashBoard">
                <DashBoard accessToken={accessToken} companyID={companyID} />
              </Route>
              <Route exact path="/healthcheck">
                <div>DirectIt INX  version: {packageJson.version}</div>
              </Route>
              <Route>
                <div>Page not found!</div>
              </Route>
            </Switch>
          </Router>
        </React.Fragment>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;

//https://www.kindacode.com/article/ways-to-set-page-title-dynamically-in-react/