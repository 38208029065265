import React, { useState, useEffect, useCallback } from 'react';
import { 
  Grid, 
  Typography, 
  Divider, 
  Tooltip, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
  Box,
  InputAdornment,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Menu,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';
import InfoField from "./infoField";
import CallerField from "./callerField";
import TextInput from '../../components/inputFields/TextInput';
import styles from '../../components/inputFields/styles';
import InputField from './inputField';
import ReferenceFields from './referenceFields';
import DescriptionIconSection from './DescriptionIconSection';

export default function OrderDetails(props) {
  const {
    order = {},
    isEdit = false,
    currentAccount = {},
    accountList = [],
    callerList = [],
    isLattice = false,
    TripServiceAccountNotes = "",
    renderDropdown,
    renderFieldWithInputAdornment,
    renderEditFieldWithInputAdornment,
    handleValueChange,
    handlePiecesChange,
    handleMaxLengthFiveChange,
    jobDetailLabelStyle,
    packageList = [],
    rateChoices = [],
    currentRate = {},
    setCurrentRate,
    tagList = [],
    referenceList,
    setUpdatedRefValue,
    availableDescriptions,
    handleDescriptionUpdate,
    loadAvailableDescriptions,
  } = props;

  const [tripPiecesList, setTripPiecesList] = useState([]);
  const [displayPieces, setDisplayPieces] = useState(order?.Pieces || 0);
  const [displayWeight, setDisplayWeight] = useState(order?.weight || 0);
  const [descriptionMenuAnchorEl, setDescriptionMenuAnchorEl] = useState(null);
  const [loadedIcons, setLoadedIcons] = useState({});
  const [loadingIcons, setLoadingIcons] = useState({});
  const [iconErrors, setIconErrors] = useState({});

  useEffect(() => {
    if (isEdit && isLattice) {
      loadAvailableDescriptions();
    }
  }, [isEdit, isLattice, loadAvailableDescriptions]);

  const loadIcon = useCallback((description) => {
    // Skip if there's no icon URL or already loading
    if (!description?.iconUrl || loadingIcons[description.descriptionID]) {
      return;
    }

    setLoadingIcons(prev => ({ ...prev, [description.descriptionID]: true }));

    const img = new Image();
    img.crossOrigin = "anonymous";
    img.onload = () => {
      setLoadedIcons(prev => ({
        ...prev,
        [description.descriptionID]: img.src
      }));
      setLoadingIcons(prev => ({ ...prev, [description.descriptionID]: false }));
      setIconErrors(prev => ({ ...prev, [description.descriptionID]: null }));
    };
    img.onerror = (error) => {
      console.log(`Failed to load icon for ${description.iconTitleText}: ${error}`);
      setLoadingIcons(prev => ({ ...prev, [description.descriptionID]: false }));
      setIconErrors(prev => ({ ...prev, [description.descriptionID]: error.message }));
    };
    img.src = description.iconUrl;

    console.log(`Attempting to load icon: ${description.iconUrl} for ${description.iconTitleText}`);
  }, [loadingIcons]);

  // Only load icons when descriptions change
  useEffect(() => {
    if (availableDescriptions?.length > 0) {
      availableDescriptions.forEach(desc => {
        if (desc?.iconUrl && !loadedIcons[desc.descriptionID]) {
          loadIcon(desc);
        }
      });
    }
  }, [availableDescriptions, loadIcon]);

  useEffect(() => {
    setDisplayPieces(order?.Pieces || 0);
    setDisplayWeight(order?.weight || 0);
  }, [order]);

  const handleDescriptionMenuItemClick = (event, descriptionID, serviceSettingsID) => {
    event.preventDefault(); // Prevent event bubbling
    event.stopPropagation(); // Stop event propagation
    handleDescriptionSelect(descriptionID, serviceSettingsID);
    handleDescriptionMenuClose();
  };

  const updateDisplayValues = useCallback(() => {
    let totalPieces = 0;
    let totalWeight = 0;

    tripPiecesList.forEach(piece => {
      totalPieces += piece.pieceNumber || 0;
      totalWeight += piece.weight || 0;
    });

    setDisplayPieces(Math.max(totalPieces, order?.Pieces || 0));
    setDisplayWeight(Math.max(totalWeight, order?.weight || 0));
  }, [tripPiecesList, order]);

  useEffect(() => {
    updateDisplayValues();
  }, [order, tripPiecesList, updateDisplayValues]);

  const renderLabel = (text) => (
    <Typography variant="button" color="primary" style={jobDetailLabelStyle}>
      {text}
    </Typography>
  );

  const renderRateField = () => {
    let rateValue = 'N/A';
    
    if (currentRate && currentRate.Rate) {
      rateValue = `$${parseFloat(currentRate.Rate).toFixed(2)}`;
    } else if (order && order.RATE) {
      rateValue = `$${parseFloat(order.RATE).toFixed(2)}`;
    }

    return (
      <Grid item xs={4} style={{ paddingLeft: "8px" }}>
        {renderLabel("Rate")}
        <InfoField value={rateValue} />
      </Grid>
    );
  };

  const renderMultilineText = (name, val, backgroundColor, border, textColor, opacity = 1) => {
    return (
      <TextInput
        inputType="text"
        name={name}
        multiline
        minRows={1}
        hideLabel={true}
        value={val}
        disabled
        style={{
          backgroundColor: backgroundColor,
          borderRadius: "4px",
          color: textColor ? textColor : "#fff",
          border: border,
          opacity: opacity,
        }}
      />
    );
  };

  const renderTag = (tag) => {
    return (
      <Grid item xs={12} container space={12} direction="row" 
        style={{
          justifyContent: 'center',
          alignItems: 'center',
        }}>        
        <Grid item xs={2} className='sidebar-tags'>
          <Box style={{ ...styles.labelWrapperNoColor, backgroundColor: tag.tagColor || 'white' }}>
            {tag.icon_url && 
              (tag.tagColor ? 
                <img src={tag.icon_url} style={{height: '2.6em', width:'2.6em'}} alt="Tag icon"/> :
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '2.6em',
                  height: '2.6em',
                }}>
                  <img src={tag.icon_url} style={{ width:'2.4em' }} alt="Tag icon"/>
                </div>
              )
            }
          </Box>
        </Grid>
        <Grid item xs={9} xl={10}>
          {renderLabel(tag.clientLabel)}
          {renderMultilineText(tag.clientLabel, tag.variableDisplayValue !== null ? tag.variableDisplayValue : tag.clientLabel, "", "", tag.variableTextColor)}
        </Grid> 
      </Grid>  
    )
  };

  const extractPhoneNumber = (shipperString) => {
    const match = shipperString.match(/:\s*([\d\s-]{10,14})/);
    return match ? match[1].replace(/\s/g, '-') : 'N/A';
  };

  const clientPhone = extractPhoneNumber(order?.ShipperName || '');

  const renderStatusString = (statusString) => {
    const driverMatch = statusString?.match(/Driver (\d+)/);
    if (driverMatch) {
      const [fullMatch, driverNumber] = driverMatch;
      const parts = statusString.split(fullMatch);
      return (
        <Typography variant="button" color="textPrimary" style={{ fontSize: 22, fontWeight: 550, marginLeft: 10 }}>
          {parts[0]}
          <Tooltip title="View Driver Details">
            <Link
              to={`/driver/${driverNumber}`}
              style={{
                color: '#3BA264',
                textDecoration: 'underline',
                display: 'inline-flex',
                alignItems: 'center'
              }}
            >
              {fullMatch}
            </Link>
          </Tooltip>
          {parts[1]}
        </Typography>
      );
    }
    return (
      <Typography variant="button" color="textPrimary" style={{ fontSize: 22, fontWeight: 550, marginLeft: 10 }}>
        {statusString}
      </Typography>
    );
  };

  const renderAccountField = () => {
    if (!isEdit) {
      return <InfoField value={currentAccount?.AccountName || order?.ACCOUNT || 'N/A'} />;
    }
    
    if (Array.isArray(accountList) && accountList.length > 0) {
      return renderDropdown(
        "account", 
        currentAccount?.account, 
        accountList, 
        "account", 
        "AccountName", 
        '100%'
      );
    }
    
    return <InfoField value={currentAccount?.AccountName || order?.ACCOUNT || 'N/A'} />;
  };

  const renderInstructions = () => {
    const instructions = order?.DEL_INST?.trim() || '';
    const isEmpty = instructions === '';

    if (!isEdit) {
      return renderMultilineText(
        "notes", 
        isEmpty ? "N/A" : instructions, 
        "#DE3562", 
        "1px solid #DE3562",
        "#fff",
        isEmpty ? 0.5 : 1
      );
    } else {
      return (
        <TextField
          multiline
          rows={4}
          variant="filled"
          fullWidth
          name="del_inst"
          defaultValue={instructions}
          inputProps={{
            maxLength: 200,
            style: { color: '#fff' }
          }}
          onChange={(event) => {
            const value = event.target.value;
            event.target.value = value.replace(/[^a-zA-Z0-9-?+$%@&<>|#?,.!<>()/*'\\;: ]/g, "");
          }}
          onBlur={handleValueChange}
          style={{
            backgroundColor: "#DE3562",
            borderRadius: "4px",
            border: "1px solid #DE3562",
          }}
        />
      );
    }
  };

  const renderAccountInfo = () => {
    const accountInfo = TripServiceAccountNotes.trim();
    const isEmpty = accountInfo === "";
    
    return (
      <Grid item xs={12} style={{ marginBottom: "16px" }}>
        {renderLabel("Account Info")}
        <TextInput
          inputType="text"
          name="account info"
          multiline
          minRows={1}
          hideLabel={true}
          value={isEmpty ? "N/A" : accountInfo}
          disabled
          style={{
            backgroundColor: "#F78133",
            borderRadius: "4px",
            color: "#fff",
            border: "1px solid #F78133",
            opacity: isEmpty ? 0.5 : 1,
          }}
        />
      </Grid>
    );
  };

  const handleDescriptionIconClick = (event) => {
    if (isEdit && isLattice) {
      setDescriptionMenuAnchorEl(event.currentTarget);
      availableDescriptions.forEach(loadIcon);
    }
  };

  const handleDescriptionMenuClose = () => {
    setDescriptionMenuAnchorEl(null);
  };

  const handleDescriptionSelect = (descriptionID, serviceSettingsID) => {
    handleDescriptionUpdate(descriptionID, serviceSettingsID);
    handleDescriptionMenuClose();
  };

  const handleReferenceChange = useCallback((name, value) => {
    setUpdatedRefValue(name, value);
    if (name === 'CUST_REF') {
      handleValueChange({
        target: {
          name: 'CUST_REF',
          value: value
        }
      });
    }
  }, [setUpdatedRefValue, handleValueChange]);

  return (
    <Grid item container direction="column" justifyContent="flex-start" alignItems="stretch"
      xs={5} lg={3} 
      style={{ 
        margin: "0", 
        width: "100%", 
        height: "100%",
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: "16px", 
        boxSizing: 'border-box'
      }} 
      className="darkForm editJobForm">
      <Grid item xs={12} style={{ marginBottom: "16px" }}>
        {renderLabel("Account")}
        {renderAccountField()}
      </Grid>
  
      <Grid item xs={12} style={{ marginBottom: "16px" }}>
        {renderLabel("Client Phone")}
        <InfoField value={clientPhone} />
      </Grid>
  
      {renderAccountInfo()}
  
      <Grid item container xs={12} style={{ marginBottom: "16px" }}>
      <Grid item xs={6} style={{ paddingRight: "8px" }}>
          {renderLabel("Caller")}
          {!isEdit ? (
            <InfoField value={order?.CALLER} />
          ) : (
            <CallerField
              caller={order?.CALLER}
              callers={callerList}
              account={order?.ACCOUNT}
              removeCallerFromList={props.removeCallerFromList}
              refreshCallList={props.getUserEmails}
              handleValueChange={handleValueChange}
            />
          )}
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: "8px" }}>
          {!isLattice ? (
            <>
              {renderLabel("Notification Email")}
              {!isEdit ? (
                <InfoField value={order?.emailConf} />
              ) : (
                <InputField
                  inputType="text"
                  name="emailConf"
                  defaultValue={order?.emailConf}
                  onBlur={handleValueChange}
                />
              )}
            </>
          ) : (
            <>
              {renderLabel("ID User")}
              <InfoField value={order?.ID_USER} />
            </>
          )}
        </Grid>
      </Grid>
  
      {!isLattice && (
        <>
          <Grid item container xs={12} style={{ marginBottom: "16px" }}>
            <Grid item xs={6} style={{ paddingRight: "8px" }}>
              {renderLabel("Package Type")}
              {!isEdit 
                ? <InfoField name="package type" value={order?.DESC}/>
                : renderDropdown(
                    "Desc",
                    order?.DESC,
                    packageList,
                    "description",
                    "description",
                    '100%'
                  )
              }
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: "8px" }}>
              <div>
                {renderLabel("Value")}
                {!isEdit
                  ? renderFieldWithInputAdornment(order?.DECLARED || 0, "$", "start")
                  : renderEditFieldWithInputAdornment("declared", "number", order?.DECLARED || 0, "$", "start", handleMaxLengthFiveChange, handleValueChange)
                }
              </div>
            </Grid>
          </Grid>
  
          <Grid item container xs={12} style={{ marginBottom: "16px" }}>
            <Grid item xs={6} style={{ paddingRight: "8px" }}>
            {renderLabel("Delivery Service")}
              {!isEdit ? (
                <InfoField value={`${order?.SER_AREA} - ${order?.SER_TIME} [ ${order?.RATE} ]`} />
              ) : (
                renderDropdown(
                  "RATE",
                  currentRate?.service || '',
                  rateChoices,
                  "service",
                  "descr",
                  '100%'
                )
              )}
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: "8px" }}>
              {renderLabel("Billing Reference")}
              {!isEdit ? (
                <InfoField name="service" value={order?.CUST_REF}/>
              ) : (
                <InputField
                  inputType="text"
                  name="CUST_REF"
                  defaultValue={order?.CUST_REF}
                  onBlur={handleValueChange}
                />
              )}
            </Grid>
          </Grid>
  
          <Grid item container xs={12} style={{ marginBottom: "16px" }}>
            <Grid item xs={6} style={{ paddingRight: "8px" }}>
              {renderLabel("Pieces")}
              {!isEdit 
                ? renderFieldWithInputAdornment(order?.Pieces, "#", "start")
                : renderEditFieldWithInputAdornment("pieces", "number", order?.Pieces, "#", "start", handlePiecesChange, handleValueChange)
              }
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: "8px" }}>
              <div>
                {renderLabel("Weight")}
                {!isEdit
                  ? renderFieldWithInputAdornment(order?.weight, "lbs", "end")
                  : renderEditFieldWithInputAdornment("weight", "number", order?.weight, "lbs", "end", handleMaxLengthFiveChange, handleValueChange)
                }
              </div>
            </Grid>
          </Grid>
  
          <Grid item xs={12} style={{ marginBottom: "16px" }}>
            {renderLabel("Instructions")}
            {renderInstructions()}
          </Grid>
  
          <Grid item xs={12} style={{ marginBottom: "16px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  name="sigReq"
                  color="primary"
                  checked={!order?.sigReq}
                  disabled={!isEdit}
                  onChange={(event) => {
                    handleValueChange({
                      target: {
                        name: "sigReq",
                        value: !event.target.checked
                      }
                    });
                  }}
                />
              }
              label={renderLabel("No Signature Required")}
            />
          </Grid>
        </>
      )}
  
      <Grid item xs={12} style={{ marginBottom: "16px" }}>
        <Divider style={{ backgroundColor: "#4e9182", height: 3 }} />
      </Grid>
  
      <Grid item xs={12} style={{ marginBottom: "16px" }}>
        {order?.StatusString && renderStatusString(order.StatusString)}
      </Grid>
      <Grid item xs={12} style={{ marginBottom: "16px" }}>
        <Typography variant="button" color="textPrimary" style={{ fontSize: 22, fontWeight: 550, marginLeft: 10 }}> {order?.locationString} </Typography>
      </Grid>
  
      {(order?.dtDelivered_display || order?.PROOF_DEL) && (
        <Grid item container xs={12} style={{ marginBottom: "16px" }}>
          {order?.dtDelivered_display && (
            <Grid item xs={6} style={{ paddingRight: "8px" }}>
              {renderLabel("Delivered Time")}
              <InfoField name="delivered time" value={order.dtDelivered_display}/>
            </Grid>
          )}
          {order?.PROOF_DEL && (
            <Grid item xs={6} style={{ paddingLeft: "8px" }}>
              {renderLabel("Proof of Delivery")}
              <InfoField name="proof of delivery" value={order.PROOF_DEL}/>
            </Grid>
          )}
        </Grid>
      )}
  
      {order?.signatureURL && (
        <Grid item container xs={12} spacing={2} style={{ marginBottom: "16px" }}>
          <Grid item xs={6}>{renderLabel("Signature")}</Grid>
          <Grid item xs={6}>
            <img src={order.signatureURL} alt="Signature" style={{ width: "190px" }}/>
          </Grid>
        </Grid>
      )}
      
      <Grid item xs={12} style={{ marginBottom: "16px" }}>
        <Divider style={{ backgroundColor: "#4e9182", height: 3 }} />
      </Grid>
  
      {isLattice && (
      <>
        <Grid item container xs={12} style={{ marginBottom: "16px" }}>
        <DescriptionIconSection
          isEdit={isEdit}
          order={order}
          handleDescriptionIconClick={handleDescriptionIconClick}
          descriptionMenuAnchorEl={descriptionMenuAnchorEl}
          handleDescriptionMenuClose={handleDescriptionMenuClose}
          handleDescriptionSelect={handleDescriptionSelect}
          availableDescriptions={availableDescriptions}
          loadedIcons={loadedIcons}
          iconErrors={iconErrors}
        />
          <Grid item xs={10} style={{ paddingLeft: "8px" }}>
            {renderLabel("Description/Additional Description")}
            {!isEdit && (<InfoField name="package type"  value={order?.DESC}/>)}
            {isEdit && (
              <InputField
                inputType="text"
                name="Desc"
                defaultValue={order?.DESC}
                onBlur={handleValueChange}
              />
            )}               
          </Grid>
        </Grid>
        <Grid item container xs={12} style={{ marginBottom: "16px" }}>
          <Grid item xs={4} style={{ paddingRight: "8px" }}>
            {renderLabel("Pieces")}
            {!isEdit && (renderFieldWithInputAdornment(order?.Pieces, "#", "start"))}
            {isEdit && (renderEditFieldWithInputAdornment("pieces", "number", order?.Pieces, "#", "start", handlePiecesChange, handleValueChange))}
          </Grid>
          <Grid item xs={4} style={{ paddingLeft: "4px", paddingRight: "4px" }}>
            {renderLabel("Weight")}
            {!isEdit && (renderFieldWithInputAdornment(order?.weight, "lbs", "end"))}
            {isEdit && (renderEditFieldWithInputAdornment("weight", "number", order?.weight, "lbs", "end", handleMaxLengthFiveChange, handleValueChange))}
          </Grid>
          {renderRateField()}
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "16px" }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="Additional Info"
              id="Additional Info"
            >
              <Typography>Additional Info</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {renderLabel("serviceMinutes")}                
                  {renderFieldWithInputAdornment(order?.serviceMinutes, "min", "end")}
                </Grid>
                <Grid item xs={6}>
                  {renderLabel("serviceDays")}                
                  {renderFieldWithInputAdornment(order?.serviceDays, "days", "end")}
                </Grid>
                <Grid item xs={6}>
                  {renderLabel("billMinutes")}                
                  {renderFieldWithInputAdornment(order?.billMinutes, "min", "end")}
                </Grid>
                <Grid item xs={6}>
                  {renderLabel("drivDist")}                
                  {renderFieldWithInputAdornment(order?.drivDist, "km", "end")}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </>
      )}
      {isLattice && tagList && tagList.length > 0 && tagList.map((tag, index) => (
        <React.Fragment key={index}>
          {renderTag(tag)}
        </React.Fragment>
      ))}
    </Grid>
  );
}