import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import AddressTab from './addressTab.js';
import { Popover, IconButton } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

class MasterDetailView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
    console.log(props.data.data);
    this.renderAddressTab = this.renderAddressTab.bind(this);
  }

  renderFromAddress = () => {
    let suite = "";
    if(this.props.data.data.sSuite){
        suite = this.props.data.data.sSuite+", ";
    }
    return suite + this.props.data.data.sAddress + " " + this.props.data.data.sQuad
            + ", " + this.props.data.data.sCity+", " + this.props.data.data.sProv + ", " + this.props.data.data.sPostal
            +", "+ this.props.data.data.sCountry        
  }

  renderToAddress = () => {
    let suite = "";
    if(this.props.data.data.cSuite){
        suite = this.props.data.data.cSuite+", ";
    }
    return suite+ this.props.data.data.cAddress + " " + this.props.data.data.cQuad
        + ", " + this.props.data.data.cCity + ", " + this.props.data.data.cProv+ ", " + this.props.data.data.cPostal
        + ", " + this.props.data.data.cCountry;
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  renderTags = () => {
    const { tagIcons } = this.props.data.data;
    const open = Boolean(this.state.anchorEl);

    if (!tagIcons || tagIcons.length === 0) {
      return <Typography>No tags</Typography>;
    }

    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {tagIcons.map((tag, index) => (
            tag.icon_url && (
              <img 
                key={index}
                src={tag.icon_url} 
                alt={tag.clientLabel || `Tag ${index + 1}`} 
                style={{ width: '24px', height: '24px', marginRight: '4px' }}
                onError={(e) => {
                  console.error(`Failed to load icon: ${tag.icon_url}`);
                  e.target.style.display = 'none';
                }}
              />
            )
          ))}
          <IconButton size="small" onClick={this.handleClick}>
            <ArrowDropDownIcon />
          </IconButton>
        </div>
        <Popover
          open={open}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {tagIcons.map((tag, index) => (
            <div key={index} style={{ padding: '8px', display: 'flex', alignItems: 'center' }}>
              {tag.icon_url && (
                <img 
                  src={tag.icon_url} 
                  alt={tag.clientLabel || `Tag ${index + 1}`} 
                  style={{ width: '24px', height: '24px', marginRight: '8px' }}
                />
              )}
              <Typography>{tag.clientLabel || `Tag ${index + 1}`}</Typography>
            </div>
          ))}
        </Popover>
      </div>
    );
  }

  render() {
    return (
      <Grid container sm={12}>
        <Grid item sm={4}>
          <Typography style={{ fontWeight: 500, fontSize: 15 }}> Shipper </Typography> 
          <Typography style={{ fontWeight: 450 }}>{this.props.data.data.sCompanyName}</Typography>  
          <Typography>{this.renderFromAddress()}</Typography>     
          {(this.props.data.data.sContact !== null && this.props.data.data.sContact !=='') &&  <Typography> Contact: {this.props.data.data.sContact}</Typography> }   
          {(this.props.data.data.sPhone !== null && this.props.data.data.sPhone!=='') && <Typography> Phone: {this.props.data.data.sPhone}</Typography> }                                                                 
        </Grid>
        <Grid item sm={7}>
          <Typography style={{ fontWeight: 500, fontSize: 15 }}> Consignee </Typography> 
          <Typography style={{ fontWeight: 450 }}>{this.props.data.data.cCompanyName}</Typography> 
          <Typography style={{ fontWeight: 400}}>{this.renderToAddress()}</Typography>
          { (this.props.data.data.cContact !== null && this.props.data.data.cContact!=='') &&  <Typography> Contact: {this.props.data.data.cContact}</Typography> }   
          {(this.props.data.data.cPhone !== null && this.props.data.data.cPhone!=='') &&  <Typography> Phone: {this.props.data.data.cPhone}</Typography> }  
        </Grid>
        <Grid item sm={1}>
          <Typography style={{ fontWeight: 500, fontSize: 15 }}> Tags </Typography>
          {this.renderTags()}
        </Grid>
      </Grid>
    );
  }

  renderAddressTab() {
    return <AddressTab data={this.props.data} />;
  }
}

export default MasterDetailView;