import React, { useState, useRef, useEffect } from 'react';
import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Drawer as DxDrawer } from 'devextreme-react/drawer';

const SideMenu = ({
  navigation,
  performTripFunction
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(() => {
    const storedState = localStorage.getItem('sideMenuDrawerState');
    return storedState ? JSON.parse(storedState) : true;
  });

  const [sectionVisibility, setSectionVisibility] = useState(() => {
    const storedVisibility = localStorage.getItem('sideMenuSectionVisibility');
    return storedVisibility ? JSON.parse(storedVisibility) : {
      Dispatch: true,
      Service: true,
      Rating: true
    };
  });

  const [hoveredSection, setHoveredSection] = useState(null);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });
  const hoverTimeoutRef = useRef(null);

  useEffect(() => {
    localStorage.setItem('sideMenuDrawerState', JSON.stringify(isDrawerOpen));
  }, [isDrawerOpen]);

  useEffect(() => {
    localStorage.setItem('sideMenuSectionVisibility', JSON.stringify(sectionVisibility));
  }, [sectionVisibility]);

  const toggleDrawer = () => {
    setIsDrawerOpen(prevState => !prevState);
  };

  const getColorForSection = (sectionId, isPopover = false) => {
    const colors = {
      Dispatch: ['#715DDC66', '#4331A4'],
      Service: ['#DE356266', '#820023'],
      Rating: ['#13ACB166', '#007E82'],
    };

    const [transparent, opaque] = colors[sectionId] || ['transparent', 'transparent'];
    return isPopover ? opaque : transparent;
  };

  const toggleSectionVisibility = (sectionId) => {
    setSectionVisibility(prev => ({
      ...prev,
      [sectionId]: !prev[sectionId]
    }));
  };

  const handleSectionHover = (sectionId) => {
    clearTimeout(hoverTimeoutRef.current);
    const element = document.getElementById(sectionId);
    if (element) {
      const rect = element.getBoundingClientRect();
      setPopoverPosition({
        top: rect.top,
        left: rect.right + 5,
      });
    }
    setHoveredSection(sectionId);
    setIsPopoverVisible(true);
  };

  const handleSectionLeave = () => {
    hoverTimeoutRef.current = setTimeout(() => {
      setIsPopoverVisible(false);
    }, 300);
  };

  const handlePopoverEnter = () => {
    clearTimeout(hoverTimeoutRef.current);
    setIsPopoverVisible(true);
  };
  
  const handlePopoverLeave = () => {
    setIsPopoverVisible(false);
  };

  const renderSectionContent = (sectionId) => {
    let menuOptions = [];
    switch (sectionId) {
      case 'Dispatch':
      case 'Service':
      case 'Rating':
        menuOptions = navigation.find(nav => nav.id === sectionId)?.options || [];
        break;
      default:
        menuOptions = [];
    }
  
    return (
      <div>
        {menuOptions.map((item, index) => (
          <div 
            key={index}
            style={{ 
              padding: '10px', 
              color: '#fff', 
              cursor: 'pointer',
              margin: '5px 0',
              borderTop: '2px solid #00000033'
            }}
            onClick={() => performTripFunction(item, sectionId)}
          >
            {item.functionText}
          </div>
        ))}
      </div>
    );
  };

  const renderDrawerContent = () => (
    <div style={{ height: '100%', backgroundColor: '#1a1a1a', overflowY: 'auto', width: isDrawerOpen ? '200px' : '60px' }}>
      <IconButton 
        onClick={toggleDrawer}
        style={{ 
          width: '100%', 
          color: '#fff', 
          justifyContent: isDrawerOpen ? 'flex-end' : 'center',
          padding: '10px'
        }}
      >
        {isDrawerOpen ? <ChevronLeftIcon /> : <MenuIcon />}
      </IconButton>
      {isDrawerOpen ? (
        <>
          {navigation.map((section) => (
            <div key={section.id}>
              <div 
                style={{ 
                  padding: '10px', 
                  color: '#fff', 
                  backgroundColor: getColorForSection(section.id),
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
                onClick={() => toggleSectionVisibility(section.id)}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <i className={section.icon} aria-hidden="true"></i>
                  <span style={{ marginLeft: '10px'}}>{section.text}</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '5px', fontWeight: 'bold' }}>
                    ({section.options ? section.options.length : 0})
                  </span>
                  {sectionVisibility[section.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>
              </div>
              {sectionVisibility[section.id] && renderSectionContent(section.id)}
            </div>
          ))}
        </>
      ) : (
        <>
          {navigation.map((section) => (
            <div 
              key={section.id}
              id={section.id}
              style={{ 
                padding: '10px', 
                color: '#fff', 
                backgroundColor: getColorForSection(section.id),
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              onMouseEnter={() => handleSectionHover(section.id)}
              onMouseLeave={handleSectionLeave}
            >
              <i className={section.icon} aria-hidden="true"></i>
            </div>
          ))}
        </>
      )}
    </div>
  );

  return (
    <>
      <div style={{ width: isDrawerOpen ? '200px' : '60px', transition: 'width 0.3s'}}>
        <DxDrawer
          opened={isDrawerOpen}
          openedStateMode="shrink"
          position="left"
          revealMode="expand"
          height="100%"
          minSize={60}
          maxSize={200}
          render={renderDrawerContent}
        />
      </div>
      {!isDrawerOpen && isPopoverVisible && hoveredSection && (
        <div
          style={{
            position: 'fixed',
            top: `${popoverPosition.top}px`,
            left: `${popoverPosition.left}px`,
            zIndex: 9999,
            backgroundColor: getColorForSection(hoveredSection, true),
            padding: '20px',
            minWidth: '200px',
            boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
            borderRadius: '4px',
            transition: 'opacity 0.3s',
            opacity: isPopoverVisible ? 1 : 0,
          }}
          onMouseEnter={handlePopoverEnter}
          onMouseLeave={handlePopoverLeave}
        >
          <h3 style={{ color: 'white', margin: 0 }}>{hoveredSection}</h3>
          {renderSectionContent(hoveredSection)}
        </div>
      )}
    </>
  );
};

export default SideMenu;