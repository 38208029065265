import React, { useState, useEffect } from "react";
import "date-fns";
import { Button, Box, TextField, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormControl, Checkbox, Typography, Snackbar, Alert, Tooltip } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import HttpRequest from "../../../HttpService.js";
import styles from '../../../components/inputFields/styles.js';
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import '../../../index.css';

import { isValidDecimal } from "../../../lib/Utils.js";

export default function TagAddTagDialog(props) {
  console.log("TagAddTagDialog rendering with props:", props);

  const [inputs, setInputs] = useState({});
  const [openSnack, setOpenSnack] = useState(false);
  const [messageInfo, setMessageInfo] = useState("");
  const [selectedTag, setSelectedTag] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log("TagAddTagDialog effect - selectedTag:", selectedTag);
  }, [selectedTag]);

  const renderTagSelect = () => {
    console.log("Rendering tag select with options:", props.tagsList);
    return (
      <TextField 
        variant="outlined"
        fullWidth            
        margin="dense"
        label="Select Tag"
        select
        value={selectedTag ? selectedTag.tagLinkID : ''}
        onChange={(event) => {
          console.log("Tag selected:", event.target.value);
          const newSelectedTag = props.tagsList.find(obj => obj.tagLinkID === event.target.value);
          console.log("New selected tag:", newSelectedTag);
          setSelectedTag(newSelectedTag);
        }}
      >
        {Array.isArray(props.tagsList) && props.tagsList.map((option) => (
          <MenuItem key={option.tagLinkID} value={option.tagLinkID}>
            {renderTagOption(option)}
          </MenuItem>
        ))}
      </TextField>
    )
  }

  const renderTagOption = (option) => {
    console.log("Rendering tag option:", option);
    if (!option) return null;
    return (
      <Box style={{
        ...styles.labelWrapperNoColor,
        borderColor: option.tagColor || '#007E82',
        backgroundColor: option.tagColor || '#13ACB1',
        height: 30,        
        width: 200    
      }}>
        {option.icon_url && <img 
          src={option.icon_url}
          alt={option.label}
          style={{height: '1.0em',}}
        />}
        <Typography variant='subtitle1' style={{
          ...styles.labelNoColor,
          marginLeft: 10,
          color: option.tagTextColor
        }}>{option.label}</Typography>
      </Box>
    )    
  }
  
  const renderElements = (item, index) => {
    console.log("Rendering element:", item, "at index:", index);
    if (!item) {
      console.warn("Attempted to render undefined item");
      return null;
    }
    
    const setTagsValue = (key, value) => {
      console.log("Setting tag value:", key, value);
      setSelectedTag(prevTag => {
        if (!prevTag) return null;
        const newParams = prevTag.params.map(param => 
          param.param === key ? {...param, value} : param
        );
        return {...prevTag, params: newParams};
      });
    }

    const params = item;

    switch(params.type) {
      case "integer":
      case "varchar":
      case "memo":
      case "decimal":
        return (
          <Tooltip key={params.param} title={params.mo || ''} placement="top-end" arrow>
            <TextField
              autoFocus={index === 0}
              variant="outlined"
              fullWidth
              margin="dense"
              label={params.param}
              type={params.type === "integer" || params.type === "decimal" ? "number" : "text"}
              inputProps={params.type === "decimal" ? { step: "0.01" } : {}}
              defaultValue={params.default || ''}
              multiline={params.type === "memo"}
              minRows={params.type === "memo" ? 3 : 1}
              onChange={(input) => {
                let value = input.target.value;
                if (params.type === "integer") value = parseInt(value, 10);
                if (params.type === "decimal") value = parseFloat(value);
                setInputs(prev => ({...prev, [params.param]: value}));
                setTagsValue(params.param, value);
              }}
            />
          </Tooltip>
        );
      case "dropdown":
        return (
          <Tooltip key={params.param} title={params.mo || ''} placement="top-end" arrow>
            <TextField
              variant="outlined"
              fullWidth
              margin="dense"
              label={params.param}
              select
              defaultValue={params.default || ''}
              onChange={(input) => {
                setInputs(prev => ({...prev, [params.param]: input.target.value}));
                setTagsValue(params.param, input.target.value);
              }}
            >
              {Array.isArray(params.list) && params.list.map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
          </Tooltip>
        );
      case "datetime":
        return (      
          <Tooltip key={params.param} title={params.mo || ''} placement="top-end" arrow>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                label={params.param}
                format="yyyy/MM/dd HH:mm"
                value={inputs[params.param] || null}
                InputAdornmentProps={{ position: "start" }}
                onChange={(date) => {
                  setInputs(prev => ({...prev, [params.param]: date}));
                  setTagsValue(params.param, date);
                }}
              />
            </LocalizationProvider>
          </Tooltip>
        );
      case "checkbox":
        return (
          <Tooltip key={params.param} title={params.mo || ''} placement="top-end" arrow>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={params.default || false}
                  color="primary"
                  onChange={(event) => {
                    setInputs(prev => ({...prev, [params.param]: event.target.checked}));
                    setTagsValue(params.param, event.target.checked);
                  }}
                />
              }
              label={params.param}
            />
          </Tooltip>
        );
      default:
        console.warn("Unknown param type:", params.type);
        return null;
    }
  };

  console.log("Rendering TagAddTagDialog component");

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <Dialog
        fullWidth
        className="darkDialog"
        open={props.open}
        PaperProps={{
          style: {
            backgroundColor: '#010C11',
          },
        }}
      >
        <DialogTitle>Add Tag</DialogTitle>
        <DialogContent>
          {renderTagSelect()}
          {selectedTag && Array.isArray(selectedTag.params) ? (
            selectedTag.params.map((item, index) => renderElements(item, index))
          ) : (
            <p>No tag selected or no parameters available.</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              console.log("Confirm button clicked");
              if (!selectedTag) {
                console.error("No tag selected");
                return;
              }
              let postTag = {checked: "1"};
              if (Array.isArray(selectedTag.params)) {
                selectedTag.params.forEach((item) => {             
                  postTag[item.param] = item.value;
                });
              }
              const content = {
                tagLinkID: selectedTag.tagLinkID,
                varJSON: JSON.stringify(postTag)
              }
              console.log("Posting trip added tag:", content);
              HttpRequest.postTripAddedTag(props.accessToken, props.jobId, content)
                .then((body) => {
                  console.log("postTripAddedTag response:", body.data);
                  setSelectedTag(null)
                  props.handleDialogClose();
                })
                .catch((error) => {
                  console.error("Error posting trip added tag:", error);
                  setMessageInfo("Error adding tag. Please try again.");
                  setOpenSnack(true);
                });
            }}
          >
            <CheckIcon /> Confirm
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              console.log("Cancel button clicked");
              setInputs({});
              setSelectedTag(null)
              props.handleDialogClose();
            }}
          >
            <ClearIcon /> Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnack}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => setOpenSnack(false)}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setOpenSnack(false)}
          severity="info"
        >
          {messageInfo}
        </Alert>
      </Snackbar>
    </div>
  );
}