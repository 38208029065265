import React, { useState, useEffect } from 'react';
import { Switch, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DEDataGrid from './deDataGrid';
import DEDataGrid2 from './deDataGrid2';

const useStyles = makeStyles((theme) => ({
  whiteText: {
    color: 'white',
  },
  switch: {
    '& .MuiSwitch-thumb': {
      color: 'white',
    },
    '& .MuiSwitch-track': {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
  },
}));

const ToggleDataGrid = (props) => {
  const classes = useStyles();
  const [useSecondGrid, setUseSecondGrid] = useState(() => {
    const saved = localStorage.getItem('useSecondGrid');
    return saved !== null ? JSON.parse(saved) : false;
  });

  useEffect(() => {
    localStorage.setItem('useSecondGrid', JSON.stringify(useSecondGrid));
  }, [useSecondGrid]);

  const handleToggle = () => {
    setUseSecondGrid(prevState => !prevState);
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="flex-end" mb={2}>
        <Typography variant="body1" className={classes.whiteText} style={{ marginRight: '10px' }}>
          Legacy
        </Typography>
        <Switch 
          checked={useSecondGrid} 
          onChange={handleToggle}
          color="default"
          className={classes.switch}
        />
        <Typography variant="body1" className={classes.whiteText} style={{ marginLeft: '10px' }}>
          Lattice
        </Typography>
      </Box>
      {useSecondGrid ? (
        <DEDataGrid2 {...props} />
      ) : (
        <DEDataGrid {...props} />
      )}
    </Box>
  );
};

export default ToggleDataGrid;