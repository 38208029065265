import React, { useRef, useState, useEffect } from "react";
import { format, parseISO } from "date-fns";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import "devextreme/dist/css/dx.dark.css";
import DataGrid, {
  Column,
  Selection,
  FilterRow,
  Paging,
  Export,
  ColumnChooser,
  Pager,
  SearchPanel,
  GroupPanel,
  Grouping,
  GroupItem,
  SortByGroupSummaryInfo,
  Summary,
  MasterDetail,
} from "devextreme-react/data-grid";
import {
  HeaderFilter,
  FilterPanel,
  FilterBuilderPopup,
  Scrolling,
} from "devextreme-react/data-grid";
//import { jsPDF } from "jspdf";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
// Our demo infrastructure requires us to use 'file-saver-es'.
// We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from "devextreme/excel_exporter";
import NoticeEmitter from "../NoticeUtil.js";

import "../search/search.css";
import MasterDetailView from "../search/MaterDetailView";

const useStyles = makeStyles((theme) => ({
  finishedRow: {
    backgroundColor: "#485054", 
    borderRadius: '6px', 
    color: '#fff',
    fontSize: '13px',
    fontWeight: '600',
    textAlign: 'center',
    padding: '0 6px',
  },
  oddRow: {
    backgroundColor: "#0D191E", 
    borderRadius: '6px', 
    color: '#fff',
    fontSize: '13px',
    fontWeight: '600',
    textAlign: 'center',
    padding: '0 6px',
  },
  evenRow: {
    backgroundColor: "#010C11", 
    borderRadius: '6px', 
    color: '#fff',
    fontSize: '13px',
    fontWeight: '600',
    textAlign: 'center',
    padding: '0 6px',
  },
  redRow: {
    backgroundColor: '#DE3562', 
    borderRadius: '6px', 
    color: '#fff',
    fontSize: '13px',
    fontWeight: '600',
    textAlign: 'center',
    padding: '0 6px',
  },
  yellowRow: {
    backgroundColor: '#C24D00', 
    borderRadius: '6px', 
    color: '#fff',
    fontSize: '13px',
    fontWeight: '600',
    textAlign: 'center',
    padding: '0 6px',
  },
  greenRow: {
    backgroundColor: "#007E82", 
    borderRadius: '6px', 
    color: '#fff',
    fontSize: '13px',
    fontWeight: '600',
    textAlign: 'center',
    padding: '0 6px',
  },
  blueRow: {
    backgroundColor: "#4331A4", 
    borderRadius: '6px', 
    color: '#fff',
    fontSize: '13px',
    fontWeight: '600',
    textAlign: 'center',
    padding: '0 6px',
  },
}));

export default function DEDataGrid(props) {
  const classes = useStyles();
  const [trips, setTrips] = useState([]);
  const orderDataTable = useRef(null);
  NoticeEmitter.addListener("resetTable", (data) => {
    console.log("==========NoticeEmitter resetTable===============");
    try {
      if(orderDataTable !== null && orderDataTable.current!== null && orderDataTable.current.instance !== undefined && orderDataTable.current.instance !== null){
        //orderDataTable.current.instance.deselectAll();
        orderDataTable.current.instance.clearSelection();  
      }
    } catch (e) {
     // console.log(e);
    }
  });

  useEffect(() => {
    console.log("search trip length-" + props.trips.length);
    const listWithID = [];
    var currentID = 0;
    props.trips.map((trip) => {
      listWithID.push({
        ...trip,
        id: currentID,
        StatusCode: trip.Status,
        Status: statusToString.get(trip.Status),
        location: locationToString.get(trip.location),
        dtDCT_UTC: !trip.dtDCT_UTC
          ? null
          : format(parseISO(trip.dtDCT_UTC), dateFormat),
        dtCallIn_UTC: !trip.dtCallIn_UTC
          ? null
          : format(parseISO(trip.dtCallIn_UTC), dateFormat),
        dtDelivered_UTC: !trip.dtDelivered_UTC
          ? null
          : format(parseISO(trip.dtDelivered_UTC), dateFormat),
      });
      currentID = currentID + 1;
    });
    setTrips(listWithID);
  }, [props.trips]);

  //const dateFormat = 'yy-MM-dd HH:mm';
  const dateFormat = "MM/dd/yyyy HH:mm";

  const locationToString = new Map([
    [-1, "All"],
    [1, "At Client"],
    [2, "On Driver"],
    [4, "In Sort"],
    [5, "Rotation Sort"],
    [8, "Delivered"],
  ]);
  const statusToString = new Map([
    [-1, "All"],
    [1, "New/Review"],
    [2, "Avail for Dispatch"],
    [3, "Driver Pending"],
    [4, "Driver Responsbility"],
    [8, "Delivered"],
    [16, "Verified"],
    [32, "Invoiced"],
  ]);

  const renderDueBy = (params) => {
    return (
      <Typography
        className={
          params.data["diff"] < 0 && params.data["StatusCode"] < 8
            ? classes.redRow
            : params.data["diff"] >= 0 &&
              params.data["diff"] <= 10 * 60 * 1000 &&
              params.data["StatusCode"] < 8
            ? classes.yellowRow
            : params.data["blueStar"] && params.data["StatusCode"] < 8
            ? classes.blueRow
            : params.data["diff"] > 10 * 60 * 1000 &&
              params.data["StatusCode"] < 8
            ? classes.greenRow
            : classes.evenRow
        }
      >
        {params.value}
      </Typography>
    );
  };

  const renderJobID = (params) => {
    return (
      <a
        style={{ color: "#13ACB1" }}
        onClick={() => {
          let inxURL = "/jobdetail/" + params.value;
          window.open(inxURL, "_blank");
        }}
      >
        {params.value}{" "}
      </a>
    );
  };

  //    const handleOnCellClick = (params) => {
  //      console.log(params.field + '---' + params.row.jobID);
  //      if(params.field === "jobID"){
  //           //window.location.href='/jobDetail/' + params.value;
  //           let inxURL ="/jobdetail/"+params.value;
  //           window.open(inxURL, "_blank");
  //      }
  //   };

  const onSelectionChanged = ({ selectedRowsData }) => {
    //const data = selectedRowsData[0];
    //console.log("onSelectionChanged --" + JSON.stringify(selectedRowsData));
    localStorage.setItem("seviceSelectedTripNum", selectedRowsData.length.toString());
    props.setSelection(selectedRowsData);
  };

  const onExporting = (e) => {
    console.log("onExporting-" + e.format);

    if (e.format === "xlsx") {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Companies");
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "inx-orders.xlsx"
          );
        });
      });
      e.cancel = true;
    } 
    // else if (e.format === "pdf") {
    //   const doc = new jsPDF();
    //   exportDataGridToPdf({
    //     jsPDFDocument: doc,
    //     component: e.component,
    //   }).then(() => {
    //     doc.save("inx-orders.pdf");
    //   });
    // } 
    else if (e.format === "csv") {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Employees");
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
      }).then(function () {
        // https://github.com/exceljs/exceljs#writing-csv
        // https://github.com/exceljs/exceljs#reading-csv
        workbook.csv.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "inx-orders.csv"
          );
        });
      });
    }
  };

  //const exportFormats = ["pdf", "xlsx", "csv"];
  const exportFormats = ["xlsx", "csv"];

  const filterBuilderPopupPosition = {
    of: window,
    at: "top",
    my: "top",
    offset: { y: 10 },
  };

  return (
    <DataGrid
      ref={orderDataTable}
      dataSource={trips}
      showBorders={true}
      allowColumnReordering={true}
      allowColumnResizing={true}
      keyExpr="id"
      onExporting={onExporting}
      onSelectionChanged={onSelectionChanged}
      //rowAlternationEnabled={true}
    >
      <GroupPanel visible={true} />
      <Grouping autoExpandAll={false} />
      
      <Selection
        mode="multiple"
        selectAllMode="page"
        showCheckBoxesMode="always"
      />

      <FilterRow visible={true} />

      <FilterPanel visible={true} />
      <FilterBuilderPopup position={filterBuilderPopupPosition} />
      <HeaderFilter visible={true} />

      <Paging defaultPageSize={50} />
      <Pager
        visible={true}
        allowedPageSizes="auto" // 'all' | 'auto'
        showInfo={true}
        showPageSizeSelector={true}
        infoText="Page {0} of {1} ({2} items)"
        displayMode="adaptive"
      />

      <SearchPanel visible={true} width={240} placeholder="Search..." />
      
      <Column
        dataField="jobID"
        caption="OrderID"
        width={100}
        cellRender={renderJobID}
      />
      <Column dataField="ACCOUNT" caption="Account" width={110} />
      <Column dataField="Status" caption="Status" width={140} />
      <Column dataField="location" caption="Location" width={100} />
      <Column dataField="SER_AREA" caption="Ser. Area" width={80} />
      <Column dataField="SER_TIME" caption="Ser. Time" width={80} />
      {/* <Column dataField="dtCallIn_UTC" caption="Call Time" width={120}/> */}
      <Column
        dataField="dtCallIn_UTC"
        caption="Call Time"
        width={140}
        dataType="datetime"
      />
      <Column dataField="CALLER" caption="Caller" width={120} />
      <Column dataField="rate" caption="Rate" width={80} />
      {/* <Column dataField="dtDCT_UTC" caption="Due By" width={150} dataType="datetime"
      //  cssClass= { (params)=>  {return (
      //   (params.data['diff'] <0) ? classes.redRow : (params.data['diff'] >=0 && params.data['diff']<= 10*60*1000)? classes.yellowRow : params.data['blueStar']? classes.blueRow : (params.data['diff'] > 10*60*1000)? classes.greenRow: classes.evenRow
      //  )}}
       cssClass= {(rowData)=>  {return (
        (rowData.diff <0) ? classes.redRow : (rowData.diff >=0 && rowData.diff<= 10*60*1000)? classes.yellowRow : rowData.diff? classes.blueRow : (rowData.diff > 10*60*1000)? classes.greenRow: classes.evenRow
       )}}
    />   */}
      <Column
        dataField="dtDCT_UTC"
        caption="Due By"
        width={150}
        cellRender={renderDueBy}
      />
      <Column dataField="CUST_REF" caption="Reference" width={130} />

      <Column dataField="sCompanyName" caption="From" width={280} />
      <Column dataField="cCompanyName" caption="To" width={280} />
      <Column dataField="pickDriver" caption="1st Drv." width={80} />
      <Column dataField="last_driv" caption="Last Drv." width={80} />
      <Column dataField="sAddress" caption="Sh. Address" width={120} />
      <Column dataField="sSuite" caption="sh. Suite" width={100} />
      <Column dataField="sQuad" caption="sh. Quad" width={100} />
      <Column dataField="sCity" caption="sh. City" width={100} />
      <Column dataField="sProv" caption="sh. Province" width={100} />
      <Column dataField="sCountry" caption="sh. Country" width={100} />
      <Column dataField="sPostal" caption="Sh. Postal" width={80} />
      <Column dataField="sContact" caption="Sh. Contact" width={100} />
      <Column dataField="sPhone" caption="sh. Phone" width={120} />
      <Column dataField="cAddress" caption="Co. Address" width={120} />
      <Column dataField="cSuite" caption="Co. Suite" width={100} />
      <Column dataField="cQuad" caption="Co. Quad" width={100} />
      <Column dataField="cCity" caption="Co. City" width={100} />
      <Column dataField="cProv" caption="Co. Province" width={100} />
      <Column dataField="cCountry" caption="Co. Country" width={100} />
      <Column dataField="cPostal" caption="Co. Postal" width={80} />
      <Column dataField="cContact" caption="Co. Contact" width={100} />
      <Column dataField="cPhone" caption="Co. Phone" width={120} />
      <Column dataField="creator" caption="Creator" width={100} />
      <Column dataField="DESC" caption="PackageType" width={100} />
      <Column dataField="PIECES" caption="Pieces" width={100} />
      <Column dataField="WEIGHT" caption="Weight" width={100} />
      <Column dataField="DEL_INST" caption="Instructions" width={120} />
      <Column dataField="sLat" caption="sh. Lat" width={100} visible={false} />
      <Column
        dataField="sLong"
        caption="sh. Long"
        width={100}
        visible={false}
      />
      <Column dataField="cLat" caption="Co. Lat" width={100} visible={false} />
      <Column
        dataField="cLong"
        caption="Co. Long"
        width={100}
        visible={false}
      />
      <ColumnChooser enabled={true} mode="select" />

      <MasterDetail enabled={true} component={MasterDetailView} />

      <Export
        enabled={true}
        formats={exportFormats}
        allowExportSelectedData={true}
      />
    </DataGrid>
  );
}

//https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/ExcelJSOverview/React/Light/
//https://js.devexpress.com/Documentation/Guide/UI_Components/DataGrid/Columns/Customize_Cells/#Customize_the_Appearance
