import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Badge, Card, CardContent, CardHeader } from '@material-ui/core';
import { Typography,Grid,IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ContactsOutlinedIcon from '@material-ui/icons/ContactsOutlined';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import GpsFixedOutlinedIcon from '@material-ui/icons/GpsFixedOutlined';
import { palette } from '../../palette';
import {ListItem, List, Tooltip} from '@material-ui/core';
import AddressForm from '../../components/addressForm';
import PanelItem from '../../components/addressPanel/PanelItem'
import copy from "copy-to-clipboard";
import CheckIcon from '@material-ui/icons/Check';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import LocationIcon from '@material-ui/icons/LocationOn'
import TextInput from '../../components/inputFields/TextInput'
import httpRequest from '../../HttpService';
import '../jobDetail.css'
import NoticeEmitter from '../../NoticeUtil';
import {Snackbar, Alert } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    root: {
    //   maxWidth: 345,
        margin:0,
    },
    header: {
        verticalAlign: 'center',
        paddingBottom: 8,
        paddingTop: 10,
    },
    action: {
        margin:0,
        },
    icon: {
        color: palette.primary.main,
        marginLeft:20,
    }
  }));

export default function AddressCard(props) {
    const classes = useStyles();

    const [showEditAddressForm, setShowEditAddressForm] = useState(false);
    const [addressData, setAddressData] = useState(props.data);
    const [selectedAddress, setSelectedAddresst] = useState(null);   //selected Address
    
    const [addrList, setAddrList] = useState([]);   //addressList
    const [addrState, setAddrState] = useState(false);  //addrState is loaded
    const [query, setQuery] = useState("");
    const handleSearch = (event) => {
        console.log('=========handleSearch - query ='+ event.target.value );
        setQuery(event.target.value);  
        if(event.target.value.length == 0){
            setAddrList([]);
            handleCancelClick();
        }
    }   

    //Handle address selection
    const [selectedAddrIndex, setSelectedAddrIndex] = useState(null);
    const handleListItemClick = (index) => {
        setSelectedAddrIndex(index);
        console.log('Address selected - index', index);
        setSelectedAddresst(addrList[index]);
        console.log('handleListItemClick-' + JSON.stringify(addrList[index]));
    };

    const[openSnack,setOpenSnack] = React.useState(false);
    const[messageInfo, setMessageInfo] = React.useState("");

    const handleCancelClick = (event) => {
        setShowEditAddressForm(!showEditAddressForm);  
        setAddrState(false); 
    };
    const handleEditClick = (event) => {
        setShowEditAddressForm(!showEditAddressForm);
    }
    useEffect(() => {
        //console.log('booped, props changed-' + JSON.stringify(props.data))
        setAddressData(props.data);
    }, [props.data]);

    useEffect(() => {
        console.log('AddressCard search query-' + query);
        if(query !== "")
          getAddrList();
    }, [query]);

    const getAddrList =() => {
        const addressList=[];
       //httpRequest.getTopAddress(props.accountNumber, {$: query}).then(async (body)=>{   
        httpRequest.getTopAddressByType(props.accountNumber, {$: query}, props.type).then(async (body) =>{    
            console.log('getTopAddress-' + JSON.stringify(body.data));
            if(body.data.length !== 0){
                body.data.forEach((value) => {                    
                    value.id = addressList.length;
                    addressList.push(value);                    
                });    
                setAddrList(addressList);                
                setAddrState(true);   
            }
            else{
                expandSearchAddr();
            }
        });
    }

   const expandSearchAddr = () => {   
        const expandedSearchAddressList=[];
        httpRequest.getExpandAddress(props.accountNumber,{$: query}, 0).then(async (body)=>{
            console.log('getExpandAddress-' + JSON.stringify(body.data));
            await body.data.forEach((value) => {
                value.id = expandedSearchAddressList.length;
                expandedSearchAddressList.push(value);
            });
            if(expandedSearchAddressList.length > 0) {
                setAddrList(expandedSearchAddressList);
                setAddrState(true);
            }else {
                setAddrList([]);
                setAddrState(false);
            }            
        });
    }

    const updateJobAddressID = (addressID) =>{
        let updateRateObj = {};
        if(props.type==="consignee"){
            updateRateObj ={  
               jobID: props.jobId,            
               conAddressID:addressID               
            }
        }
       else{
            updateRateObj = {
                jobID: props.jobId,              
                shipAddressID:addressID
            }
        }
        console.log('updateJobAddressID--', JSON.stringify(updateRateObj));
        httpRequest.updateJobRateFields(props.accessToken, updateRateObj).then(function (data){            
            setTimeout(function(){ NoticeEmitter.emit('refreshJobDetail', '');  }, 1000);
        },function errorCallback (err) {
           console.log('Failed to update Job rate field: ' + err);
        });
    }

    const updateJobContactID = (contactID) =>{
        let updateRateObj = {};
        if(props.type==="consignee"){
            updateRateObj ={  
               jobID: props.jobId,            
               conContactID:contactID               
            }
        }
       else{
            updateRateObj = {
                jobID: props.jobId,              
                shipContactID:contactID
            }                   
        }
        console.log('updateJobContactID--', JSON.stringify(updateRateObj));
        httpRequest.updateJobFields(props.accessToken, updateRateObj).then(function (data){
        },function errorCallback (err) {
           console.log('Failed to update Job rate field: ' + err);
        });
    }

    const listItems = data => {
        const result = data.map((item, index) => (
            <ListItem 
                button 
                className="panel-list-item" 
                divider="true"                
                selected={selectedAddrIndex === index}
                autoFocus={selectedAddrIndex === index ? true: false}
                onClick={() => {
                    handleListItemClick(index);                     
                }}
                key={`address-list-item-${index}`}                                 
            > 
                <PanelItem                    
                    selected={selectedAddrIndex === index} 
                    data={item} 
                    index={index}
                    key={`address-panel-item-${index}`}                                                    
                /> 
            </ListItem>
        ));
        return result;
    };

    const handleAddressClick = (event) => {
        copy(props.data?.addressID);
        setMessageInfo("addressID:" + props.data?.addressID + " was copied.");
        setOpenSnack(true);
    }

    const handleGPSClick = (event) => {
        copy(props.coord?.lat + ', ' + props.coord?.long);
        setMessageInfo("GPS coordinates:" + props.coord?.lat + ', ' + props.coord?.long + " was copied.");
        setOpenSnack(true);
    }
  
    return (
        <div>       
            <Grid container>
                <Grid item xs={12}>
        <Card className={classes.root} elevation={1} style={{marginBottom:'0', }}>              
            <CardHeader
                classes={{root:classes.header, action:classes.action}}
                disableTypography
                title={<Typography paragraph style={{margin: 0}}>{props.data?.companyName}</Typography>}
                action={<>
                    <Tooltip title={props.data?.addressID} placement='top'>
                    <LocationIcon className={classes.icon} aria-label={props.data?.addressID}  onClick={handleAddressClick}/>
                    </Tooltip>
                    <EditIcon className={classes.icon} aria-label='Edit Address'  onClick={handleEditClick}/>
                    <Tooltip title={props.data?.contactID} placement='top'>
                    <AccountBoxIcon className={classes.icon} aria-label={props.data?.contactID}/> 
                    </Tooltip>
                    <Tooltip title={props.coord?.lat + ', ' + props.coord?.long} placement='top'>
                    <GpsFixedOutlinedIcon className={classes.icon} aria-label={props.coord?.lat + ', ' + props.coord?.long} onClick={handleGPSClick}/>
                    </Tooltip>
                    </>
                }
            />
            <CardContent style={{minHeight: '72px' }}>
                <Typography variant='body1' style={{margin: 0, }}> {(props.data?.suite? props.data?.suite + ', ' : '') + props.data?.address + (props.data?.quad? ', ' + props.data?.quad : '')} </Typography>
                <Typography variant='body1' style={{margin: 0}}> {props.data?.city + ', ' + props.data?.prov + ', ' + props.data?.postal + ' ' + props.data?.country} 
                     {/* &emsp;&emsp; &emsp;&emsp; <Badge color='primary' badgeContent={props.data?.addressID} max={9999999} style={{zIndex:0}}/>  */}
                </Typography>
                <Typography variant='body1' style={{margin: 0}}> {props.data?.contactName}  {props.data?.contactPhone}
                  {/* &emsp;&emsp; <Badge color='primary' badgeContent={props.data?.contactID} max={99999999} style={{zIndex:0}}/> */}                 
                </Typography>
            </CardContent>
        </Card>
        </Grid>
        {showEditAddressForm &&<Tooltip title="Select the delivery address or type" placement="top" arrow >          
                <TextInput                    
                    type="text"
                    label={props.type === "consignee" ? "Delivery Location:":"Pickup Location"}
                    labelSize={4}
                    variant="outlined"
                    size="large"                   
                    onChange = {handleSearch}
                    {...props}/>             
          
        </Tooltip> }
        {showEditAddressForm && !addrState &&(
            <AddressForm 
                title='Edit Address' 
                cancelButtonLabel='Cancel' 
                saveButtonLabel='Save Address' 
                variant='edit'
                type = {props.type}
                cancelButtonOnClick={handleCancelClick}
                data={addressData}
                lat={props.coord?.lat}
                long={props.coord?.long}
                jobId={props.jobId} 
                accessToken={props.accessToken}  
                accountNumber = {props.accountNumber}              
            />
        )}

        {addrState && addrList.length>0 &&
        <div>
            <div className="panel-list" id="addr-form">
                <List className="panel-list-container" disablePadding={true} key="addr-list"  alignItems="flex-start">
                    {listItems(addrList)}
                </List>
            </div>
             <Grid container style={{ padding: '5% 1%' }} justifyContent="center" sm={12} spacing={1}>
                <Grid item>
                <Button
                  variant="contained"
                  style={{ color: palette.greys.white, backgroundColor: palette.greys.medium}}
                  startIcon={<ClearIcon style={{verticalAlign: 'middle', transform: 'scale(1.5)', color: palette.greys.white }}/>}
                  disableRipple 
                  onClick={()=>{handleCancelClick()}}
                >
                 Cancel
                </Button>
                </Grid>
                <Grid item>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CheckIcon style={{verticalAlign: 'middle', transform: 'scale(1.5)', color: palette.greys.white }}/>}
                    disableRipple 
                    onClick={()=>{
                        if(selectedAddress.contactID!== null)
                            updateJobContactID(selectedAddress.contactID);
                        updateJobAddressID(selectedAddress.addressID);                        
                        handleCancelClick();   
                    }}
                    type="submit"
                >
                    Save
                </Button>
                </Grid>
            </Grid>
        </div>    
        }    

        <Snackbar open={openSnack} 
            autoHideDuration={1500} 
            anchorOrigin ={{ vertical:'bottom', horizontal: 'left' }}  
            onClose={()=>{setOpenSnack(false)}}>
            <Alert onClose={()=>{setOpenSnack(false)}} severity="info" sx={{ width: '100%' }}>
                {messageInfo}
            </Alert> 
        </Snackbar>      
    </Grid> 
    </div>
    );
}