import React, {useState, useEffect} from "react";
import "date-fns";
import { Button,Box } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Snackbar, Alert } from "@mui/material";
import Tooltip from '@material-ui/core/Tooltip';
import { isValidDecimal,isValidInterger} from "../../lib/Utils.js";

export default function EditItemDialog(props) {
 
  console.log("EditRateItemDialog============" + JSON.stringify(props.variableDefinition));
  const [inputs, setInputs] = useState({}); 
  const [openSnack, setOpenSnack] = useState(false);
  const [messageInfo, setMessageInfo] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [variableDefinition, setVariableDefinition] = useState([]);

  useEffect(() => {  
    if(props.variableDefinition){
      let varDefines = JSON.parse(props.variableDefinition);
      varDefines.forEach((item) =>{             
        item.value=item.default;
      });  
      setVariableDefinition(varDefines)
    }      
    else
     setVariableDefinition([]);
  },[props.variableDefinition, props.isNew]);

 const renderElements = (item, index) => {
    const setItemValue =(key,value) =>{
        let items = variableDefinition;       
        items.forEach((param) =>{
            if(param.param === key){
                param.value = value;
            }
        });
        setVariableDefinition(items);       
    }

    const params = item;    
    console.log("EditRateItemDialog renderElements --------" + JSON.stringify(item));
    if (params.type === "integer") {
      return (
        <Tooltip title={params.prompt} placement="top-end" arrow>
        <TextField
          autoFocus={index === 0 ? true : false}
          variant="outlined"
          fullWidth
          margin="dense"
          key={params.param}
          label={params.prompt}
          defaultValue={params.default}
          onChange={(input) => {
            //if(Number.isInteger(Number(input.target.value))){
            if(isValidInterger(input.target.value)){  
              setInputs((prevState) => {
                return {
                  ...prevState,
                  [params.param]: input.target.value,
                };
              }); 
             setItemValue(params.param,input.target.value);  
            }  
            else{
              input.target.value = input.target.value.substring(0,input.target.value.length-1)
            }             
          }}
          onBlur={(input) => {
            if(!Number.isInteger(Number(input.target.value))){
              input.target.value=''
              setMessageInfo("please input integer value!")
              setOpenSnack(true)
              setAlertSeverity("warning")
              setTimeout(() => {setOpenSnack(false)}, 3000)
            }  
          }}
        />
        </Tooltip>
      );
    } else if (params.type.includes("varchar")) {
      return (
        <Tooltip title={params.prompt} placement="top-end" arrow>
        <TextField
          autoFocus={index === 0 ? true : false}
          variant="outlined"
          fullWidth
          key={params.param}
          margin="dense"
          label={params.prompt}
          defaultValue={params.default}
          onChange={(input) => {
            setInputs((prevState) => {
              return {
                ...prevState,
                [params.param]: input.target.value,
              };
            }); 
            setItemValue(params.param,input.target.value);             
          }}
        />
        </Tooltip>
      );    
    } else if (params.type === "dropdown") {
      return (
        <Tooltip title={params.prompt} placement="top-end" arrow>
        <TextField
          variant="outlined"
          fullWidth
          key={params.param}
          margin="dense"
          label={params.prompt}
          select
          defaultValue={params.default}
          onChange={(input) => {
            setInputs((prevState) => {
              return {
                ...prevState,
                [params.param]: input.target.value,
              };
            }); 
            setItemValue(params.param,input.target.value);              
          }}
        >
          {params.list.map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        </Tooltip>
      );
    } else if (params.type === "datetime") {
      return (      
        <Tooltip title={params.prompt} placement="top-end" arrow>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            renderInput={(props) => (
              <TextField autoFocus={index === 0 ? true : false} {...props} />
            )}
            label={params.param}
            ampm={false}
            onChange={(input) => {
              setInputs((prevState) => {
                return {
                  ...prevState,
                  [params.param]: input,
                };
              }); 
              setItemValue(params.param,input);                
            }}
          />
        </LocalizationProvider>
        </Tooltip>
      );
    } else if (params.type === "decimal") {
      return (
        <Tooltip title={params.prompt} placement="top-end" arrow>
        <TextField
          autoFocus={index === 0 ? true : false}
          variant="outlined"
          fullWidth
          key={params.param}
          margin="dense"
          label={params.prompt}
          defaultValue={params.default}
          onChange={(input) => {
            if(isValidDecimal(input.target.value)){
              setInputs((prevState) => {
                return {
                  ...prevState,
                  [params.param]: input.target.value,
                };
              }); 
             setItemValue(params.param,input.target.value);  
            }   
            else{ 
              if(!input.target.value.endsWith('.'))             
               input.target.value = input.target.value.substring(0,input.target.value.length-1)
            }      
          }}
          onBlur={(input) => {
            if(!isValidDecimal(input.target.value)){
              input.target.value=''
              setMessageInfo("please input decimal value!")
              setOpenSnack(true)
              setAlertSeverity("warning")
              setTimeout(() => {setOpenSnack(false)}, 3000)
            }  
          }}
        ></TextField>
        </Tooltip>
      );
    } else if (params.type === "checkbox") {
      return (
        <Tooltip title={params.prompt} placement="top-end" arrow>
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                label={params.prompt}
                defaultChecked={params.default}
                //defaultChecked ={true}
                color="primary"
                onChange={(event) => {
                  setInputs((prevState) => {
                    return {
                      ...prevState,
                      [params.param]: event.target.checked,
                    };
                  }); 
                  setItemValue(params.param,event.target.checked);                  
                }}
              />
            }
            label={
              <Typography style={{ color: "black" }}>
                {params.param}
              </Typography>
            }
          />
        </FormControl>
        </Tooltip>
      );
    }
  };

  return (
    <div>
      <Dialog
        fullWidth
        className="darkDialog"
        open={props.open}
      >
        <DialogTitle>{props.description}</DialogTitle>
        <DialogContent>
          {variableDefinition && variableDefinition.map((item, index) => renderElements(item, index))} 
        </DialogContent>
        <DialogActions>
            <Button
              onClick={() => {
                console.log("eidtRateDialog====variableDefinition========" + JSON.stringify(variableDefinition));             
                console.log("eidtRateDialog====input========" + JSON.stringify(inputs));
                let postItem ={}; 
                variableDefinition.forEach((item) =>{             
                    postItem[item.param]=item.value;
                });    
                props.submitRequest(JSON.stringify(postItem));                
            }}
          >
            <CheckIcon></CheckIcon> Confirm
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              setInputs({});
              props.handleDialogClose();
            }}
          >
            <ClearIcon></ClearIcon> Cancel
          </Button>
        </DialogActions>
      </Dialog>   
      <Snackbar
        open={openSnack}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        //onClose={() => {setOpenSnack(false)}}
      >
        <Alert
          onClose={() => {
            setOpenSnack(false);
          }}       
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {messageInfo}
        </Alert>
      </Snackbar>
    </div>
  );
}
