import React, { useState, useEffect } from "react";
import {Tooltip,TextField, Grid} from '@mui/material';
import "animate.css/animate.css";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker} from '@mui/x-date-pickers/TimePicker';
import dayjs, { Dayjs } from 'dayjs';


const TagTimePicker = (props) => {

  const [value, setValue] = useState(dayjs(props.value));
  ////const [value, setValue] = useState(dayjs(Date.now()));
  const tipText =  props.prompt?props.prompt:''; 

  useEffect(() => {
    console.log("------TagDatePicker-----" + props.value);    
  },[props.value]);   


  const handleChange = (newValue) => {
    console.log("TagDatePicker handleChange-" + JSON.stringify(newValue));
    props.onChange({
      name: props.param,
      value: newValue,
    });
    setValue(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <Tooltip title={tipText} placement="top" arrow>
           <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker 
                    fullWidth
                    size='small'
                    label={props.label}
                    value={value}
                    //sx={{ width: 280 }}
                    onChange={handleChange} 
                    sx={{
                      width: 280,
                      // bgcolor: "rgb(51, 51, 51)",
                      "& .MuiInputBase-root": {
                        color: "white",
                        "& > fieldset": {
                          borderColor: "rgb(171, 171, 171)",
                        },
                        "&:hover fieldset": {
                          borderColor: "white",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "white",
                        },                  
                      },
                      '& .MuiInputLabel-root': {
                        color: 'white',  
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: 'white',  
                      },
                    }}                                
                />
            </LocalizationProvider>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default TagTimePicker;
