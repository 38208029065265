import React from 'react';
import { DataGridPro } from "@mui/x-data-grid-pro";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AttachmentIcon from "@mui/icons-material/Attachment";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import ReplyIcon from "@material-ui/icons/Reply";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import CounterUpTimer from "./counterUpTimer";
import HttpRequest from "../HttpService";




const DashboardDatagrid = props => { 
   
    
    const  renderJobID = (params) => {
        return <a className="jobIDLink">{params.value}</a>;
    };

    const renderDrNum = (num) => {
        return (
          <div>          
            <a
              className="driverNumLink"
              onClick={(e) => {
                window.location.href = "/driver/" + num.value;
              }}
            >
              {num.value}
            </a>
            {num.value && (
              <IconButton
                onClick={(e) => {
                    props.setSelectedDriver(num.value)                  
                    setTimeout(() => {                     
                      props.setMsgBoxOpen(true);
                    }, 500);
                  
                }}
              >
                <ReplyIcon />
              </IconButton>
            )}
          </div>
        );
      };
    
    
    const renderClaimButton = (params) => {
        return (
            <div
                className="claimButtonWrapper"
                onClick={(e) => {
                    HttpRequest.claimServiceQueue(props.accessToken,params.row.serviceQueID).then( async (body) => {
                        try {
                            console.log("claimServiceQueue -" + JSON.stringify(body.data));
                            console.log("claimServiceQueue -job is " + params.row.jobid);
                            if (body.data.ResultCode === 1 || body.data.ResultCode === 2) {
                                if ( params.row.jobid !== null &&   params.row.jobid !== "" &&  params.row.jobid !== "0") {
                                    window.open(`/jobdetail/${params.row.jobid}`, "_blank");
                                } else {                  
                                    setTimeout(() => {props.pollData();}, 2000);
                                }
                             }
                        } catch (e) {
                            console.log("claimServiceQueue exception:" + e);
                        }
                    },
                    function errorCallback(err) {
                        alert("Failed to claimServiceQueue. Error is : " + err);
                    }
                );}}
            >
                <div className="claimButtonValue"> {params.row.priority}</div>
                <svg
                    className={
                        params.row.priority > 200
                        ? "claimButtonIcon priorityTop"
                        : params.row.priority >= 150 && params.row.priority < 199
                        ? "claimButtonIcon priorityHigh"
                        : params.row.priority >= 101 && params.row.priority < 149
                        ? "claimButtonIcon priorityMedium"
                        : "claimButtonIcon priorityLow"
                    }
                    width="auto"
                    height="48px"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g>
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M21 3a1 1 0 0 1 1 1v5.5a2.5 2.5 0 1 0 0 5V20a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-5.5a2.5 2.5 0 1 0 0-5V4a1 1 0 0 1 1-1h18z" />
                    </g>
                </svg>
            </div>
        );
    };

    const renderClearButton = (params) => {
        return (
            <IconButton
                className="iconButtonClear"
                size={"small"}
                onClick={(e) => {
                    HttpRequest.clearServiceQueue(
                        props.accessToken,
                        params.row.serviceQueID
                    ).then(async (body) => {
                        console.log("clearServiceQueue -" + JSON.stringify(body.data));
                        props.removeMessage(params.row.serviceQueID);                 
                    },
                    function errorCallback(err) {
                        alert("Failed to clearServiceQueue. Error is : " + err);
                    }
                )}}
            >
                <DeleteIcon />
            </IconButton>
        );
    };    
    
    const renderMediaButton = (params) => {
        return (
          <div>
            <IconButton className="iconButtonMedia" size={"small"}>
              <AttachmentIcon />
            </IconButton>
            <IconButton className="iconButtonMedia" size={"small"}>
              <PlayCircleOutlineIcon />
            </IconButton>
            <IconButton className="iconButtonMedia" size={"small"}>
              <HeadphonesIcon />
            </IconButton>
          </div>
        );
    };

    const renderDataTime = (params) => {
        return <CounterUpTimer dateTimeStamp={params.value} />;
    };


    const handleOnCellClick = (params) => {
        console.log(params.field + "---" + params.row.jobid);
        if (params.field === "jobid") {
          if (
            params.row.jobid !== null &&
            params.row.jobid !== "" &&
            params.row.jobid != "0"
          ) {
            window.open(`/jobdetail/${params.row.jobid}`, "_blank");
          } else {
            props.setOpenWarningDialog(true)            
          }
        }
      };
    

    const columns = [
        {
          field: "jobid",
          headerName: "Job ID",
          width: 100,
          renderCell: renderJobID,
        },
        {
          field: "processingUser",
          headerName: "User",
          width: 120,
        },
        {
          field: "clear",
          headerName: "Clear",
          width: 100,
          renderCell: renderClearButton,
        },
        {
          field: "claim",
          headerName: "Claim",
          width: 100,
          renderCell: renderClaimButton,
        },    
        {
          field: "messageText",
          headerName: "Message Text",
          width: 500,
        },
        {
          field: "drNum",
          headerName: "Driver #",
          width: 150,
          renderCell: renderDrNum,
        },
        {
          field: "descr",
          headerName: "Description",
          width: 180,
        },
        {
          field: "datestamp",
          headerName: "DateTime",
          width: 150,
          renderCell: renderDataTime,
        },   
        {
          field: "companyID",
          headerName: "CoID",
          width: 160,
        },
        {
          field: "media",
          headerName: "Media",
          width: 100,
          renderCell: renderMediaButton,
        },
    ];


    return (
        <>   
        <div className="darkDataGrid">
          <DataGridPro
            autoHeight={true}
            pageSize={100}
            rows={props.messages}
            columns={columns}
            onCellClick={handleOnCellClick}
            onSelectionModelChange={(ids) => {
              try {
                const selectedIDs = new Set(ids);
                const selectedRowData = props.messages.filter((row) =>
                  selectedIDs.has(row.id)
                );
                props.setSelectedDriver(selectedRowData[0].drNum);               
              } catch (e) {
                console.log(e);
              }
            }}
          />
        </div>         
        </>
    );

};

export default DashboardDatagrid;