import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  OverlayView,
  DirectionsService,
  DirectionsRenderer,
  TrafficLayer,
} from "@react-google-maps/api";
import ExceptionPin from "./ExceptionPin";
import DriverPin from "./DriverPin";
import TripPin from "./TripPin";
import BreadcrumbPin from "./BreadcrumbPin";
import NoticeEmitter from "../../NoticeUtil";
import httpRequest from "../../HttpService";

/**
 * DriversMapPanel Component
 * Displays a map with driver and trip pins, and routes the driver through trip pins from closest to furthest.
 */
const DriversMapPanel = (props) => {
  // State variables for map points and driver/trip pins
  const [mapPoints, setMapPoints] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(props.currentDriver);
  const [exceptionMapPoints, setExceptionMapPoints] = useState([]);
  const [driverMapPoints, setDriverMapPoints] = useState([]);
  const [tripMapPoints, setTripMapPoints] = useState([]);
  const [pickTripMapPoints, setPickTripMapPoints] = useState([]);
  const [deliveryTripMapPoints, setDeliveryTripMapPoints] = useState([]);

  // State variables for breadcrumb points
  const [breadCrumbPoints, setBreadCrumbPoints] = useState([]);
  const [breadCrumbPoints_location, setBreadCrumbPoints_location] = useState(
    []
  );
  const [breadCrumbPoints_order, setBreadCrumbPoints_order] = useState([]);

  // State variables for related trip pins
  const [showRelatedTripPIN, setShowRelatedTripPIN] = useState(false);
  const [relatedTripPIN, setRelatedTripPIN] = useState(null);
  const [relatedOrgTripPIN, setRelatedOrgTripPIN] = useState(null);
  const [showOrgRelatedPIN, setShowOrgRelatedPIN] = useState(false);
  const [relatedTripPINColor, setRelatedTripPINColor] = useState("");

  // State variables for routing
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [directionsError, setDirectionsError] = useState(null);
  const [routeRequest, setRouteRequest] = useState(null); // Holds the route request object

  // References for map center and map instance
  const mapCenterRef = React.useRef(null); // Stores the initial map center
  const mapRef = React.useRef(); // Reference to the map instance

  // State Variables for traffic:
  //const [showTrafficLayer, setShowTrafficLayer] = useState(false);



  /**
   * useEffect hook to update state variables when props change
   */
  useEffect(() => {
    // Update state with props
    setMapPoints(props.mapPoints);
    setSelectedDriver(props.currentDriver);
    setDriverMapPoints(props.driverPinsList);
    setTripMapPoints(props.tripPinsList);
    setExceptionMapPoints(props.exceptionPinsList);

    // Separate trip pins into pickup and delivery lists
    const deliveryTripPointsList = [];
    const pickTripPointsList = [];
    props.tripPinsList.forEach((value) => {
      if (["D", "DD", "SD", "RD", "DN"].includes(value.mapLabel.toUpperCase()))
        deliveryTripPointsList.push(value);
      else pickTripPointsList.push(value);
    });
    setDeliveryTripMapPoints(deliveryTripPointsList);
    setPickTripMapPoints(pickTripPointsList);

    // Calculate and set the route request for DirectionsService
    if (driverMapPoints.length > 0 && tripMapPoints.length > 0) {
      // Driver's location
      const driverLocation = {
        lat: driverMapPoints[0].lat,
        lng: driverMapPoints[0].long,
      };

      // Function to calculate distance between two points using Haversine formula
      const rad = (x) => (x * Math.PI) / 180;
      const calculateDistance = (point1, point2) => {
        const R = 6378137; // Earth's mean radius in meters
        const dLat = rad(point2.lat - point1.lat);
        const dLng = rad(point2.lng - point1.lng);
        const a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(rad(point1.lat)) *
            Math.cos(rad(point2.lat)) *
            Math.sin(dLng / 2) *
            Math.sin(dLng / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c; // Distance in meters
      };

      // Add distance from driver to each trip pin
      const tripPinsWithDistance = tripMapPoints.map((pin) => {
        const distance = calculateDistance(driverLocation, {
          lat: pin.lat,
          lng: pin.long,
        });
        return { ...pin, distance };
      });

      // Sort trip pins by distance from the driver (closest to furthest)
      const sortedTripPins = tripPinsWithDistance.sort(
        (a, b) => a.distance - b.distance
      );

      // Create waypoints excluding the last (furthest) trip pin
      const waypoints = sortedTripPins.slice(0, -1).map((pin) => ({
        location: { lat: pin.lat, lng: pin.long },
        stopover: true,
      }));

      // Set the destination as the furthest trip pin
      const destinationPin = sortedTripPins[sortedTripPins.length - 1];
      const destination = { lat: destinationPin.lat, lng: destinationPin.long };

      // Update the route request state
      setRouteRequest({
        origin: driverLocation,
        destination: destination,
        waypoints: waypoints,
        travelMode: "DRIVING",
      });

      // Set map center to the driver's location if not already set
      if (!mapCenterRef.current) {
        mapCenterRef.current = driverLocation;
      }
    } else {
      // No trip pins, so we should not attempt routing
      setRouteRequest(null);
      setDirectionsResponse(null); // Clear any previous directions response
    }
  }, [
    props.mapPoints,
    props.currentDriver,
    props.driverPinsList,
    props.tripPinsList,
    props.exceptionPinsList,
    driverMapPoints,
    tripMapPoints,
    relatedTripPIN,
    showRelatedTripPIN,
    showOrgRelatedPIN,
  ]);

  /**
   * useEffect hook to fetch breadcrumb points when necessary
   */
  useEffect(() => {
    console.log(
      "DriverMap - getBreadCrumbPoints - startTime-" + props.startTime
    );
    console.log("DriverMap - getBreadCrumbPoints - endTime-" + props.endTime);
    let locationList = [];
    let orderList = [];

    if (String(props.startTime) === String(props.endTime)) {
      // Fetch breadcrumb points without time range
      httpRequest
        .getBreadcrumbPoints(props.accessToken, props.currentDriver)
        .then(async function (body) {
          console.log("DriverMap === getBreadCrumbPoints-" + body.data.length);
          body.data.forEach((value) => {
            if (value.type.toUpperCase().includes("LOCATION"))
              locationList.push(value);
            else orderList.push(value);
          });
          setBreadCrumbPoints_location(locationList);
          setBreadCrumbPoints_order(orderList);
          setBreadCrumbPoints(body.data);
        });
    } else {
      // Fetch breadcrumb points with time range
      httpRequest
        .getBreadcrumbPointsWithRange(
          props.accessToken,
          props.currentDriver,
          props.startTime,
          props.endTime
        )
        .then(async function (body) {
          console.log("DriverMap - getBreadCrumbPoints-" + body.data.length);
          body.data.forEach((value) => {
            if (value.type.toUpperCase().includes("LOCATION"))
              locationList.push(value);
            else orderList.push(value);
          });
          setBreadCrumbPoints_location(locationList);
          setBreadCrumbPoints_order(orderList);
          setBreadCrumbPoints(body.data);
        });
    }

    // Set map center if not already set
    if (
      props.mapPoints &&
      props.mapPoints.length > 0 &&
      !mapCenterRef.current
    ) {
      mapCenterRef.current = {
        lat: props.mapPoints[0].lat,
        lng: props.mapPoints[0].long,
      };
    }
  }, [
    props.currentDriver,
    props.IsShowBreadcrumbPoints,
    props.startTime,
    props.endTime,
    props.mapPoints,
  ]);

  // Event handler: Handle click on driver icons
  function handleIconClick(drNum, json) {
    setSelectedDriver(drNum);
    json = JSON.parse(json);
    props.handlerDriverPinClick(drNum);
  }

  // Event handler: Handle click on trip pins
  function handleTripClick(drNum, jobID, json) {
    let inxURL = "/jobdetail/" + jobID;
    window.open(inxURL, "_blank");
  }

  // Event handler: Handle mouse over on trip pins to show related pins
  function handleMouseOver(pin) {
    if (relatedTripPIN !== null) return;
    var target;
    switch (pin.mapLabel.toUpperCase()) {
      case "D":
        target = "P";
        break;
      case "DD":
        target = "PP";
        break;
      case "P":
        target = "D";
        break;
      case "PP":
      default:
        target = "DD";
        break;
    }
    mapPoints.map((item) => {
      if (item.jobID === pin.jobID && item.mapLabel.toUpperCase() === target) {
        setRelatedTripPINColor(item.mapColor);
        setRelatedOrgTripPIN(item);

        item.mapColor = "light green";
        setRelatedTripPIN(item);
        localStorage.setItem("relatedPIN", JSON.stringify(item));

        setShowRelatedTripPIN(true);
        setShowOrgRelatedPIN(false);

        // Hide the related pin after 1.5 seconds
        let cnt = 0;
        let timerId = setInterval(() => {
          cnt++;
          if (cnt === 1) {
            clearInterval(timerId);
            setRelatedTripPIN(null);
            setRelatedOrgTripPIN(null);
            setShowOrgRelatedPIN(false);
            setShowRelatedTripPIN(false);
            NoticeEmitter.emit("freshAllDriverMap", "");
          }
        }, 1500);
      }
      return null; // Avoid ESLint warning
    });
  }

  // Function to render the related trip pin when hovering
  function renderRelatedTripPin() {
    return relatedTripPIN ? (
      <OverlayView
        position={{ lat: relatedTripPIN.lat, lng: relatedTripPIN.long }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={(width, height) => ({
          x: -(width / 2),
          y: -height,
        })}
      >
        <TripPin
          onClick={() =>
            handleTripClick(
              relatedTripPIN.drNum,
              relatedTripPIN.jobID,
              relatedTripPIN.json
            )
          }
          pin={relatedTripPIN}
          json={relatedTripPIN.json}
          onMouseOver={() => {}}
        />
      </OverlayView>
    ) : null;
  }

  // Function to render the original related trip pin
  function renderRelatedOrgTripPin() {
    return (
      <OverlayView
        position={{ lat: relatedOrgTripPIN.lat, lng: relatedOrgTripPIN.long }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={(width, height) => ({
          x: -(width / 2),
          y: -height,
        })}
      >
        <TripPin
          onClick={() =>
            handleTripClick(
              relatedOrgTripPIN.drNum,
              relatedOrgTripPIN.jobID,
              relatedOrgTripPIN.json
            )
          }
          pin={relatedOrgTripPIN}
          json={relatedOrgTripPIN.json}
          onMouseOver={() => handleMouseOver(relatedOrgTripPIN)}
        />
      </OverlayView>
    );
  }

  // Event handler: Handle mouse over on breadcrumb pins (implement as needed)
  function handleMouseOverBreadCrumb(pin) {
    console.log("handleMouseOverBreadCrumb", pin);
  }

  // Map container style
  const mapContainerStyle = {
    width: "100%",
    height: "80vh",
  };

  // Render the component
  return driverMapPoints.length > 0 || exceptionMapPoints.length > 0 ? (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
      <GoogleMap
        ref={mapRef}
        mapContainerStyle={mapContainerStyle}
        center={mapCenterRef.current}
        zoom={10}
        options={{
          fullscreenControl: true,
          mapTypeId: "roadmap",
          gestureHandling: "auto",
        }}
      >
      <TrafficLayer />
        {/* Render driver markers */}
        {driverMapPoints.map((value) => (
          <OverlayView
            key={`driver-${value.drNum}`}
            position={{ lat: value.lat, lng: value.long }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <DriverPin
              onMouseOver={() => props.hoverHandler(value.drNum, value.json)}
              onClick={() => handleIconClick(value.drNum, value.json)}
              drNum={value.drNum}
              pin={value}
            />
          </OverlayView>
        ))}

        {/* Render exception driver markers */}
        {exceptionMapPoints.map((value) => (
          <OverlayView
            key={`exception-${value.drNum}`}
            position={{ lat: value.lat, lng: value.long }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <>
              <DriverPin
                onMouseOver={() => props.hoverHandler(value.drNum, value.json)}
                onClick={() => handleIconClick(value.drNum, value.json)}
                drNum={value.drNum}
                pin={value}
              />
              <ExceptionPin />
            </>
          </OverlayView>
        ))}

        {/* Render pickup trip pins */}
        {props.IsShowPickup &&
          pickTripMapPoints.map((value) => (
            <OverlayView
              key={`pickup-${value.jobID}`}
              position={{ lat: value.lat, lng: value.long }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <TripPin
                onClick={() =>
                  handleTripClick(value.drNum, value.jobID, value.json)
                }
                onMouseOver={() => handleMouseOver(value)}
                pin={value}
                json={value.json}
              />
            </OverlayView>
          ))}

        {/* Render delivery trip pins */}
        {props.IsShowDelivery &&
          deliveryTripMapPoints.map((value) => (
            <OverlayView
              key={`delivery-${value.jobID}`}
              position={{ lat: value.lat, lng: value.long }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <TripPin
                onClick={() =>
                  handleTripClick(value.drNum, value.jobID, value.json)
                }
                onMouseOver={() => handleMouseOver(value)}
                pin={value}
                json={value.json}
              />
            </OverlayView>
          ))}

        {/* Render related trip pins */}
        {showRelatedTripPIN
          ? renderRelatedTripPin()
          : relatedOrgTripPIN !== null
          ? renderRelatedOrgTripPin()
          : null}

        {/* Render breadcrumb points */}
        {props.IsShowBreadcrumbPoints &&
          breadCrumbPoints_location.map((value, index) => (
            <OverlayView
              key={`breadcrumb-location-${index}`}
              position={{ lat: value.lat, lng: value.long }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <div onMouseOver={() => handleMouseOverBreadCrumb(value)}>
                <BreadcrumbPin pin={value} />
              </div>
            </OverlayView>
          ))}

        {props.IsShowBreadcrumbPoints &&
          breadCrumbPoints_order.map((value, index) => (
            <OverlayView
              key={`breadcrumb-order-${index}`}
              position={{ lat: value.lat, lng: value.long }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <div onMouseOver={() => handleMouseOverBreadCrumb(value)}>
                <BreadcrumbPin pin={value} />
              </div>
            </OverlayView>
          ))}

        {/* Render the route using DirectionsService and DirectionsRenderer */}
        {routeRequest && (
          <DirectionsService
            options={routeRequest}
            callback={(result, status) => {
              if (status === "OK") {
                setDirectionsResponse(result);
              } else {
                console.error(`Error fetching directions ${result}`);
                setDirectionsError(result);
              }
            }}
          />
        )}

        {directionsResponse && (
          <DirectionsRenderer
            options={{
              suppressMarkers: true,
              directions: directionsResponse,
              preserveViewport: true,
            }}
          />
        )}
      </GoogleMap>
    </LoadScript>
  ) : null;
};

export default DriversMapPanel;
