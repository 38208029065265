import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextInput from "../../../components/inputFields/TextInput"
import CustomIconButton from "../../../components/inputFields/CustomIconButton.js";

import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

/** 
    MemoInput (two icons oh left side)
    takes in props
    @inputType - either text or select
    @multiline - if text input is multiline
    @optionList - options if select inputType
    @value - value displayed by the input
    @onChange - onChange handler
    @label - label (title) of the input
    @labelSize - 1-12 how much of the width is the label vs the input
    @color - default is primary <-blue could choose secondary (ie red)
    @iconOne- icon if there is an icon
    @iconTwo- icon if there is an icon
    @onIconClick - when icon is clicked
    @onIconTwoClick - when second icon is click
**/

/*
 props.onChange({
            target: {
                name: props.name,
                value: newSize,
            }
        });
*/

function MemoInput(props) {
  //const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9-?+$%?,.!<>;: ]+$/;
  const [speechToolTip, setSpeechToolTip] = useState("start speech");

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const handerSpeechClick = () => {
    console.log("=========handerSpeechClick===============");
    if (speechToolTip.startsWith("start")) {
      setSpeechToolTip("stop speech");
      SpeechRecognition.startListening();
      document.getElementById(props.name).value = "";
    } else {
      setSpeechToolTip("start speech");
      SpeechRecognition.stopListening();
      document.getElementById(props.name).value = transcript;
      props.onBlur({
        target: {
          name: props.name,
          value: transcript,
        },
      });
    }
  };

  function renderInput() {
    return (
      <TextInput
        multiline
        minRows={props.minRows}
        id={props.name}
        type={props.type}
        label={props.label}
        hideLabel={props.hideLabel}
        labelSize={props.labelSize}
        inputProps={{ maxLength: props.inputLength }}
        variant="outlined"
        size="large"
        color={props.color}
        //onChange={props.onChange}
        onChange={(event) => {
          const value = event.target.value;
          event.target.value = value.replace(/[^a-zA-Z0-9-?+$%@&<>|#?,.!<>()/*'\\;: ]/g, "");
          /* if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) {
            event.target.value = value.substring(0, value.length-1);       
            return;
          }   */
        }}
        {...props}
      />
    );
  }

  return (
    <div>
      <Grid
        container
        direction="row"
        //justifyContent='flex-end'
        justifyContent="stretch"
        style={{ margin: "0", width: "100%", minWidth: '600px' }}
        alignItems="stretch"
        spacing={0}
        sm={12}
      >
        <Grid item sm={10}>
          {renderInput()}
        </Grid>
        <Grid item sm={2}>
          <span>
            <CustomIconButton
              icon={props.iconOne}
              tooltip={props.iconOneTooltip}
              onClick={props.onIconOneClick}
              color={props.color}
            />
            <CustomIconButton
              icon={speechToolTip.startsWith("start") ? "volumeUpIcon" : "volumeOffIcon"}
              tooltip={speechToolTip}
              onClick={handerSpeechClick}
              color={props.color}
            />
          </span>
        </Grid>
      </Grid>
    </div>
  );
}

export default MemoInput;
