import React, { useState, useEffect } from 'react';
import CustomDialog from '../../components/dialog/Dialog';
import TripFunctionDialog from './tripFunctionDialog';

const DialogManager = ({ 
  openUpdatingDialog,
  locationUpdateDialog,
  updatingItemStr,
  updateGPSSource,
  latestUpdatedPosition,
  setOpenUpdatingDialog,
  setLocationUpdateDialog,
  loadTripDetails,
  updateGPS,
  jobId,
  dialogIsOpen,
  tripFunction,
  handleDialogClose,
  rateAdjustmentDialog,
  setRateAdjustmentDialog,
  handleRateAdjustment,
  setMessageInfo,
  setOpenSnack,
  updateOrderFields 
}) => {
  const [activeDialog, setActiveDialog] = useState(null);

  useEffect(() => {
    if (openUpdatingDialog) setActiveDialog('updating');
    else if (locationUpdateDialog) setActiveDialog('location');
    else if (dialogIsOpen) setActiveDialog('tripFunction');
    else if (rateAdjustmentDialog.isOpen) setActiveDialog('rateAdjustment');
    else setActiveDialog(null);
  }, [openUpdatingDialog, locationUpdateDialog, dialogIsOpen, rateAdjustmentDialog.isOpen]);

  const handleTimeAdjustment = (adjustRate = false) => {
    setRateAdjustmentDialog(prev => ({ ...prev, isOpen: false }));
    
    if (adjustRate) {
      // Update both time and rate
      handleRateAdjustment();
    } else {
      // Update only time by calling updateOrderFields directly
      updateOrderFields(jobId);
      setMessageInfo("Time updated successfully");
      setOpenSnack(true);
    }
  };

  switch (activeDialog) {
    case 'updating':
      return (
        <CustomDialog
          open={true}
          onClose={() => setOpenUpdatingDialog(false)}
          hasCloseBtn={false}
          dialogMessage={updatingItemStr}
          dialogTitle="Confirmation"
          dialogAction={() => {
            setOpenUpdatingDialog(false);
            setTimeout(() => { loadTripDetails(jobId); }, 1500);
          }}
          isConfirmDialog={true}
        />
      );
    case 'rateAdjustment':
      return (
        <CustomDialog
          open={true}
          onClose={() => {
            setRateAdjustmentDialog({ isOpen: false, changeRate: false });
            // Ensure time update happens even on dialog close
            updateOrderFields(jobId);
          }}
          dialogTitle="Time Change Confirmation"
          dialogMessage={
            <div className="text-center">
              <p className="text-lg font-medium mb-2">The delivery time has been changed.</p>
              <p className="text-base">Would you like to adjust the rate based on this new time?</p>
              <p className="text-sm text-gray-600 mt-2">
                • Selecting "Yes" will update both time and rate<br/>
                • Selecting "No" will update only the time
              </p>
            </div>
          }
          dialogAction={() => handleTimeAdjustment(true)} // Yes - update both time and rate
          onCancel={() => handleTimeAdjustment(false)}   // No - update only time
          isConfirmDialog={false}
          showCancelButton={true}
          confirmButtonText="Yes"
          cancelButtonText="No"
        />
      );
    case 'location':
      return (
        <CustomDialog
          open={true}
          onClose={() => setLocationUpdateDialog(false)}
          hasCloseBtn={true}
          dialogTitle="Confirm GPS Update"
          dialogMessage={
            updateGPSSource === "dropman" ? "Are you sure you want to update GPS Coordinates?" : 
            updateGPSSource === "photo" ? "Are you sure you want to update GPS Coordinates to the photo location?" : 
            "Are you sure you want to update GPS Coordinates to the signature location?"
          }
          dialogAction={() => {
            setLocationUpdateDialog(false);
            if (latestUpdatedPosition && 
                !isNaN(latestUpdatedPosition.lat) && 
                !isNaN(latestUpdatedPosition.lng)) {
              updateGPS();
            } else {
              console.error('Invalid GPS coordinates');
            }
          }}
          isConfirmDialog={false}
        />
      );
    case 'tripFunction':
      return (
        <TripFunctionDialog
          tripFunction={tripFunction}
          open={true}
          handleDialogClose={handleDialogClose}
          onOperationComplete={(message) => {
            console.log("DialogManager received message:", message);
            setMessageInfo(message);
            setOpenSnack(true);
            if (tripFunction.refreshAfter) {
              loadTripDetails(jobId);
            }
          }}
        />
      );
    default:
      return null;
  }
};

export default DialogManager;