import React from 'react';
import { Snackbar, Alert } from "@mui/material";

const SnackbarManager = ({ openSnack, setOpenSnack, messageInfo }) => {
  React.useEffect(() => {
    console.log("SnackbarManager state changed:", { openSnack, messageInfo });
  }, [openSnack, messageInfo]);

  return (
    <Snackbar 
      open={openSnack} 
      autoHideDuration={1500} 
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      onClose={() => { setOpenSnack(false); }}
    >
      <Alert onClose={() => { setOpenSnack(false); }} severity="info" sx={{ width: "100%" }}>
        {messageInfo}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarManager;