import React, { useState, useEffect } from "react";

import httpRequest from "../../HttpService";

import "devextreme/dist/css/dx.dark.css";
import DataGrid, {
  Column,
  Selection,
  FilterRow,
  Paging,
  Export,
  ColumnChooser,
  Pager,
  SearchPanel,
} from "devextreme-react/data-grid";

const TripHistoryList = (props) => {
  const [tripHistoryList, setTripHistoryList] = useState([]);
  const [tableHeight, setTableHieght] = useState(150);

  useEffect(() => {
    var historyList = [];
    httpRequest.getTripHistory(props.accessToken, props.jobId).then((body) => {
      console.log("getTripHistory-" + JSON.stringify(body.data));
      if (body.data.length !== 0) {
        body.data.forEach((value) => {
          value.timestamp = value.timestamp.substring(0, 19).replace("T", " ");
          value.id = historyList.length;
          historyList.push(value);
        });
        historyList.sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1));
        setTripHistoryList(historyList);
        setTableHieght(100 + 36 * historyList.length);
      }
    });
  }, []);

  const renderDrNum = (params) => {
    return <a style={{ color: "#13ACB1" }}>{params.value} </a>;
  };

  const columns = [
    { field: "timestamp", headerName: "Date/Time", width: 200 },
    { field: "descr", headerName: "History Description", width: 280 },
    {
      field: "messageText",
      headerName: "Message",
      minWidth: 350,
      maxWidth: 800,
    },
    { field: "drNum", headerName: "From", width: 120, renderCell: renderDrNum },
  ];

  return (
    <div
      className="darkDataGrid"
      style={{  width: "100%" }}
    >     
      <DataGrid
        dataSource={tripHistoryList}
        showBorders={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        keyExpr="id"
        rowAlternationEnabled={true}
      >
        <Paging defaultPageSize={15} />
        <SearchPanel visible={true} width={240} placeholder="Search..." />
        <Pager
          visible={true}
          showInfo={true}
          infoText="Page #{0}. Total: {1} ({2} items)"
          displayMode="full"
        />
        <Column dataField="timestamp" caption="Date/Time" width={200} />
        <Column dataField="descr" caption="History Description" width={280} />
        <Column dataField="messageText" caption="message" />
        <Column dataField="drNum" caption="Creator" cellRender={renderDrNum} />
      </DataGrid>
    </div>
  );
};

export default TripHistoryList;
