import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import {Typography} from '@mui/material';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import styles from '../../../components/inputFields/styles.js';
import CustomIconButton from "../../../components/inputFields/CustomIconButton.js";
import "animate.css/animate.css";

const TagDecimalFieldEditable = (props) => {
  const minNum = props.minNum;
  const maxNum = props.maxNum;
  const [numValue, setNumValue] = useState(props.value || 0);
  const [tipText, setTipText] = useState(props.prompt);
  const [warningMsg, setWarningMsg] = useState('');  
 
  useEffect(() => {
      //console.log("**********TagDecimalFielsEditable**********" + props.value);      

      if (minNum !== undefined || maxNum !== undefined) {
        if (minNum !== undefined && maxNum !== undefined) {
          setTipText( props.prompt?`${props.prompt} Number must be greater then ${minNum}, and less then ${maxNum}.`:
          `Number must be greater then ${minNum}, and less then ${maxNum}.`);
          setNumValue(Math.round(props.value) < Math.round(minNum)? minNum: Math.round(props.value) > Math.round(maxNum)? maxNum: props.value);
        } else if (minNum !== undefined) {
          setTipText( props.prompt?`${props.prompt} Number must be greater then ${minNum}.`:`Number must be greater then ${minNum}.`);
          setNumValue(Math.round(props.value) < Math.round(minNum)? minNum: props.value);
        } else if (maxNum !== undefined) {
          setTipText(props.prompt? `${props.prompt} Number must be less then ${maxNum}.` :`Number must be less then ${maxNum}.`)
          setNumValue(Math.round(props.value) > Math.round(maxNum)? maxNum: props.value);
        }
      }
      else{
        setNumValue(props.value);
      }
    },[props.value]);  
    
  useEffect(() => {
      //console.log("**********TagDecimalFielsEditable**********" + numValue);
      try{
        let num = parseFloat(numValue);
        if(num>maxNum) setWarningMsg(`Number must be less then ${maxNum}`);
        else if(num<minNum) setWarningMsg(`Number must be greater then ${minNum}`);
        else setWarningMsg('');
      }
      catch(ex){};
  },[numValue]);

 
  
  const handleChange = (event) => {
    console.log("TagDecimal onChange-" + event.target.value);
    try{
      let num = parseFloat(event.target.value).toFixed(2);  
      if ((minNum !== undefined && maxNum !== undefined) && (Math.round(num) > Math.round(maxNum)))
        setNumValue(maxNum.toString());
      else
        setNumValue(event.target.value);
      props.onChange({
          name: props.param,
          value: num,
      });
    }
    catch(ex){
      console.log("TagDecimal onChange exception", ex.toString());
      setNumValue(event.target.value);
    }
    
     
  };

  /*
  const handleBlur = (event) => {    
    let num = parseFloat(event.target.value===''?'0':event.target.value).toFixed(2);
    try{
      //setNumValue(num.toString());
      if (Math.round(num) >= Math.round(minNum) && Math.round(num) <= Math.round(maxNum)) {     
        setNumValue(num.toString());
        props.onChange({
          name: props.param,
          value: num,
        });
      }   
      else if(Math.round(num) < Math.round(minNum)){
        setNumValue(minNum.toString());
        props.onChange({
          name: props.param,
         value: minNum,
        });
      } 
      else if(Math.round(num) > Math.round(maxNum)){
        setNumValue(maxNum.toString());
        props.onChange({
          name: props.param,
          value: maxNum,
        });
      } 
    }
    catch(ex){
      console.log("TagDecimalFielsEditable exception", ex.toString());
      setNumValue(num.toString());
        props.onChange({
          name: props.param,
          value: num,
        });
    }
  };
  */

  const clearNum = () => {
    setNumValue(0);
    props.onChange({
      name: props.param,
      value: 0,
    });
  };


  return (
    <Grid container direction="row" justifyContent="stretch" style={{ margin: "0", width: "100%"}} spacing={0} sm={10}>
      <Grid item sm={10}>     
        <Tooltip title={tipText} placement="top" arrow>
        <div className="tagField">  
        <TextField
          label={props.label}
          size="small"
          type="number"
          value={numValue}
          onChange={handleChange}
          color={props.color}
          //onBlur={handleBlur}
          variant={"outlined"}
          inputProps={{
            style: {
              height: 40             
            },
            min: minNum,
            max: maxNum,
          }}          
        />
        </div>
        </Tooltip>
        <Typography color="error"> {warningMsg} </Typography>
      </Grid>
      <Grid item sm={2}>
        <CustomIconButton
          icon={props.icon || "ClearIcon"}
          tooltip={props.iconTooltip || "Clear"}
          onClick={clearNum}
          color={props.color}
          style={{ height:55 }}  
        />
      </Grid> 
    </Grid>
  );
};

export default TagDecimalFieldEditable;
