
export const capitalizeFirstLetter = (str) => {
    if (!str) return '';
    str = str.trim();
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  
  export const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.replace(/^data:(.*;base64,)?/, '');
        if ((encoded.length % 4) > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = error => reject(error);
    });
  }
  
  const checkIfFieldExists = (fields) => {
    const checkList = ['DATE', 'FULLNAME', 'ADDRESS', 'FIRSTNAME', 'NAME_CHILD', 'FIRSTNAME_CHILD'];
    return fields.map(fieldName => {
      if (checkList.includes(fieldName.getName()))
        return fieldName.getName();
    });
  }
    
  export const isValidEmail = (emailAddress) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(emailAddress);
  }
  
  export const convertToUTC = (dateTime) => {
    const date = dateTime ? new Date(dateTime) : new Date();
    //date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
    return date.toISOString();
  }
  
  
  export const dateDiffInYears =(dateold, datenew) => {
    let ynew = datenew.getFullYear();
    let mnew = datenew.getMonth();
    let dnew = datenew.getDate();
    let yold = dateold.getFullYear();
    let mold = dateold.getMonth();
    let dold = dateold.getDate();
    let diff = ynew - yold;
    if (mold > mnew) diff--;
    else {
        if (mold === mnew) {
            if (dold > dnew) diff--;
        }
    }
    return diff;
    // let currentTime = datenew.getTime();
    // let birthDateTime= dateold.getTime();
    // let difference = (currentTime - birthDateTime);
    // var age=difference/(1000*60*60*24*365);
    // return age;
  }
  
  export const padNumberWithChar = (str, strlen = 2, padNumber = 0) => {
    str = str + ''
    if (str.length >= strlen) {
      return str
    }
    return str.padStart(parseInt(strlen), padNumber)
  }
  
  export const getDateTimeStr = () => {
    const date = new Date()
    return date.getFullYear() +
        padNumberWithChar(date.getMonth() + 1) +
        padNumberWithChar(date.getDate()) +
        padNumberWithChar(date.getHours()) +
        padNumberWithChar(date.getMinutes()) +
        padNumberWithChar(date.getSeconds() + 1)
  }
  
  export const handleNullValuesZero = (data) => {
    return Object.fromEntries(
        Object.entries(data).map(([key, value]) => [
          key,
          value ?? 0,
        ])
    );
  }
  
  
  export const handleNullValuesEmpty = (data) => {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key,
        value ?? '',
      ])
    );
  }
  
  export const dateEnglishFormat = (dt) => {
    if (dt) {
      const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
      dt = dt.substring(0, 10)
      const dateList = dt.split("-")
      if (dateList && dateList.length >= 2) {
        const monthIndex = parseInt(dateList[1]) -1
        if (monthIndex >= 0) {
          return months[monthIndex] + ' ' + dateList[2] + ', ' + dateList[0]
        }
      }
    }
  }
  
  export const shortUtcDateTime = (dt) => {
    if (dt) {
      dt = convertToUTC(dt)
      dt = dt.substring(0, 10)
    }
    return dt
  }
  
  export const shortDateTime = (dt) => {
    if (dt) {
      dt = dt.substring(0, 10)
    }
    return dt
  }
  
  export const shortDayStr = (dateStr) => {
    if (dateStr && typeof dateStr === 'string') {
      return dateStr.substring(0, 10)
    }
  }
  
  export const currencyFormat = (amount) => {
    return new Intl.NumberFormat('en-us', {style: 'currency', currency: 'USD'}).format(amount)
  }


  export const isValidDecimal = (value) => {
    const decimalRegex = /^-?\d+(\.\d+)?$/;
    return decimalRegex.test(value);
  }

  export const isValidInterger = (value) => {
    //return Number.isInteger(Number(value));
    const integerRegex = /^-?\d+$/;
    return integerRegex.test(value);
  }