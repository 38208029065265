import React, { useState, useRef, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { ZoomIn, ZoomOut, RotateLeft, RotateRight, Refresh } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  controls: {
    marginBottom: theme.spacing(2),
    '& > *': {
      margin: theme.spacing(0, 1),
    }
  },
  imageContainer: {
    width: '100%',
    height: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    cursor: 'move',
    backgroundColor: 'transparent',
    position: 'relative'
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    userSelect: 'none'
  }
}));

const ImageViewer = ({ src }) => {
  const classes = useStyles();
  const [scale, setScale] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const lastMousePos = useRef({ x: 0, y: 0 });
  const lastPosition = useRef({ x: 0, y: 0 });
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  
  const imageRef = useRef(null);
  const containerRef = useRef(null);

  // Update image dimensions when image loads
  useEffect(() => {
    const updateDimensions = () => {
      if (imageRef.current) {
        setImageDimensions({
          width: imageRef.current.naturalWidth,
          height: imageRef.current.naturalHeight
        });
      }
    };

    const imageElement = imageRef.current;
    if (imageElement) {
      if (imageElement.complete) {
        updateDimensions();
      } else {
        imageElement.addEventListener('load', updateDimensions);
      }
    }

    return () => {
      if (imageElement) {
        imageElement.removeEventListener('load', updateDimensions);
      }
    };
  }, [src]);

  const calculateBounds = () => {
    if (!imageRef.current || !containerRef.current) return { maxX: 0, maxY: 0 };

    const containerRect = containerRef.current.getBoundingClientRect();
    const containerWidth = containerRect.width;
    const containerHeight = containerRect.height;

    // Get image dimensions considering rotation
    let scaledWidth, scaledHeight;
    if (rotation % 180 === 0) {
      scaledWidth = imageDimensions.width * scale;
      scaledHeight = imageDimensions.height * scale;
    } else {
      scaledWidth = imageDimensions.height * scale;
      scaledHeight = imageDimensions.width * scale;
    }

    // Calculate the actual space the image can move
    const xOffset = Math.max(0, (scaledWidth - containerWidth) / 2);
    const yOffset = Math.max(0, (scaledHeight - containerHeight) / 2);

    return {
      maxX: xOffset,
      maxY: yOffset
    };
  };

  useEffect(() => {
    const handleGlobalMouseMove = (e) => {
      if (isDragging && scale > 1) {
        const deltaX = e.clientX - lastMousePos.current.x;
        const deltaY = e.clientY - lastMousePos.current.y;

        const rotatedCoords = getRotatedCoordinates(deltaX, deltaY, -rotation);
        const bounds = calculateBounds();
        
        const newX = lastPosition.current.x + rotatedCoords.x;
        const newY = lastPosition.current.y + rotatedCoords.y;

        // Constrain movement to image edges
        const boundedX = Math.min(Math.max(newX, -bounds.maxX), bounds.maxX);
        const boundedY = Math.min(Math.max(newY, -bounds.maxY), bounds.maxY);
        
        setPosition({
          x: boundedX,
          y: boundedY
        });
      }
    };

    const handleGlobalMouseUp = () => {
      if (isDragging) {
        setIsDragging(false);
        lastPosition.current = position;
      }
    };

    if (isDragging) {
      window.addEventListener('mousemove', handleGlobalMouseMove);
      window.addEventListener('mouseup', handleGlobalMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleGlobalMouseMove);
      window.removeEventListener('mouseup', handleGlobalMouseUp);
    };
  }, [isDragging, scale, rotation, position]);

  // Recenter image when scale or rotation changes
  useEffect(() => {
    const bounds = calculateBounds();
    
    // If current position is out of bounds, animate it back
    const newX = Math.min(Math.max(position.x, -bounds.maxX), bounds.maxX);
    const newY = Math.min(Math.max(position.y, -bounds.maxY), bounds.maxY);
    
    if (newX !== position.x || newY !== position.y) {
      setPosition({ x: newX, y: newY });
      lastPosition.current = { x: newX, y: newY };
    }
  }, [scale, rotation]);

  const handleZoomIn = () => setScale(prev => prev * 1.2);
  const handleZoomOut = () => {
    setScale(prev => {
      const newScale = Math.max(0.1, prev / 1.2);
      if (newScale <= 1) {
        setPosition({ x: 0, y: 0 });
        lastPosition.current = { x: 0, y: 0 };
      }
      return newScale;
    });
  };

  const handleRotateLeft = () => {
    setRotation(prev => (prev - 90) % 360);
    setPosition({ x: 0, y: 0 });
    lastPosition.current = { x: 0, y: 0 };
  };

  const handleRotateRight = () => {
    setRotation(prev => (prev + 90) % 360);
    setPosition({ x: 0, y: 0 });
    lastPosition.current = { x: 0, y: 0 };
  };

  const handleReset = () => {
    setScale(1);
    setRotation(0);
    setPosition({ x: 0, y: 0 });
    lastPosition.current = { x: 0, y: 0 };
  };

  const getRotatedCoordinates = (deltaX, deltaY, angle) => {
    const radians = (angle * Math.PI) / 180;
    return {
      x: deltaX * Math.cos(radians) + deltaY * Math.sin(radians),
      y: -deltaX * Math.sin(radians) + deltaY * Math.cos(radians)
    };
  };

  const handleMouseDown = (e) => {
    if (scale > 1) {
      setIsDragging(true);
      lastMousePos.current = { x: e.clientX, y: e.clientY };
      lastPosition.current = position;
      e.preventDefault();
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.controls}>
        <IconButton onClick={handleZoomIn} size="small">
          <ZoomIn />
        </IconButton>
        <IconButton onClick={handleZoomOut} size="small">
          <ZoomOut />
        </IconButton>
        <IconButton onClick={handleRotateLeft} size="small">
          <RotateLeft />
        </IconButton>
        <IconButton onClick={handleRotateRight} size="small">
          <RotateRight />
        </IconButton>
        <IconButton onClick={handleReset} size="small">
          <Refresh />
        </IconButton>
      </div>
      <div 
        ref={containerRef}
        className={classes.imageContainer}
        onMouseDown={handleMouseDown}
      >
        <img
          ref={imageRef}
          src={src}
          alt="Preview"
          className={classes.image}
          style={{
            transform: `rotate(${rotation}deg) scale(${scale}) translate(${position.x}px, ${position.y}px)`,
            transition: isDragging ? 'none' : 'transform 0.2s ease-in-out',
          }}
          draggable={false}
        />
      </div>
    </div>
  );
};

export default ImageViewer;