import React, { useState, useEffect,forwardRef} from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Divider, Button, IconButton, Card, CardActions, CardContent, FormControl, Select, Autocomplete, Checkbox, MenuItem, } from '@mui/material';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import InputField from '../jobDetail/components/inputField';
import InfoField from '../jobDetail/components/infoField';
import '../jobDetail/jobDetail.css'
import DateFnsUtils from "@date-io/date-fns";
import TextInput from '../components/inputFields/TextInput';
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DoneAllIcon from "@mui/icons-material/DoneAll";
import {KeyboardDateTimePicker,MuiPickersUtilsProvider,} from "@material-ui/pickers";
import {Dialog,DialogTitle, DialogContent, DialogContentText,  DialogActions, Fade} from "@material-ui/core";
// import {Dialog,DialogTitle, DialogContent, DialogContentText,  DialogActions,} from '@mui/material';
import EditAddressForm from './EditAddressForm';
import { palette } from '../palette';
import httpRequest from '../HttpService';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


const Transition = forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
  });

// const useStyles = makeStyles((theme) => ({
//     dialog: {
//       // the dropdown is next to the dialog root, not inside
//       "& + .pac-container": {
//         zIndex: theme.zIndex.modal + 1
//       }
//     }
// }));

export default function EditAccountForm(props) {
    //const classes = useStyles();
    const [isEditAddress,setIsEditAddress] = useState(false);
    const [accountDetail,setAccountDetail] = useState({});
    const [updatingItem,setUpdatingItem] = useState({});

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    //"account":"302046","Brand":"WDEL","Shipper":"CITY OF CALGARY","address":"800 Macleod Tr S, Calgary, Alberta","companyID":1,"companyName":"[ 3304 call-in] ","phone":"",
    //"Hours":"08:00 - 17:30","closeLunch":false,"Cnt":3304,"name":"CITY OF CALGARY-302046","value":"302046","id":0,"open":"08:00","close":"17:30"}

    useEffect(() => {    
        //const [value, setValue] = React.useState<Dayjs | null>(dayjs('2022-04-17T15:30'));
        if(props.account!== null){
          let currentDate = new Date();
          currentDate = currentDate.toISOString().split('T')[0]
          setAccountDetail({
            accountName:props.account.name,
            accountID:props.account.account,
            address:props.account.address,
            openTime:dayjs(`${currentDate}T${props.account.open}`),
            closeTime:dayjs(`${currentDate}T${props.account.close}`),
            accountListNote:'',
            accountListNoteExpiry:new Date(),
          })
          setUpdatingItem({account:props.account.account}); 
          console.log("accountDetail",accountDetail);  
          //console.log("currentDate-"+currentDate);  
          setIsEditAddress(false);
        }
    }, [props.account?.account]);

    const handleValueChange = (event) => {
        console.log(`handleValueChange - ${event.target.name} - ${ event.target.value}`);  
        setUpdatingItem((prevState) => {
            return {
                ...prevState,
                [event.target.name]: event.target.value === ""?" ":event.target.value,        
            };
        });    
        setAccountDetail((prevState) => {
            return {
                ...prevState,
                [event.target.name]: event.target.value === ""?" ":event.target.value,        
            };
        });      
    };     

    const renderTextEditor = (label, name, type, val, size) =>{
        return(
            <Grid item xs={size}>
                {renderLabel(label)}
                <InputField inputType={type} name={name} defaultValue={val}  onBlur={handleValueChange}/> 
            </Grid> 
        );
    }
   
    const renderTimeFieldEditor = (label, name, type, val, size) =>{
        return(
            <Grid item xs={size}>
                {renderLabel(label)}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimeField
                        label=""
                        value={val}
                        onChange={(newValue) =>{      
                            //console.log("renderTimeFieldEditor",newValue);                      
                            let hour =  newValue.$H<10?`0${newValue.$H}`:newValue.$H;
                            let min = newValue.$m<10?`0${newValue.$m}`:newValue.$m;
                            console.log("renderTimeFieldEditor",`${hour}:${min}`);
                            setUpdatingItem((prevState) => {
                                return {
                                    ...prevState,
                                    [name]:`${hour}:${min}`,        
                                };
                            })
                            // setAccountDetail((prevState) => {
                            //     return {
                            //         ...prevState,
                            //         [name]:`${hour}:${min}`,        
                            //     };
                            // });  
                          }                      
                        }
                       format="HH:mm"
                    />  
                </LocalizationProvider>              
            </Grid> 
        );
    }

    const renderDateTimeEditor = (label, name, val, size) =>{
        return(
            <Grid item xs={size} container justifyContent="flex-start">
                {renderLabel(label)}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDateTimePicker name={name} className="editableJobField" fullWidth
                    format="yyyy/MM/dd HH:mm"  value={val}
                    onChange={(input) => {
                        setUpdatingItem((prevState) => {
                           return {...prevState, [name]: input,};
                        });   
                        setAccountDetail((prevState) => {
                            return {...prevState, [name]: input,};
                         });                                  
                    }}
                 />
                </MuiPickersUtilsProvider> 
            </Grid> 
        );
    }

    const renderMultilineTextEditor = (label, name, type, val, size,backgroundColor,border) =>{
        return(
            <Grid item xs={size}>
                 {renderLabel(label)}
                 {/* <TextInput inputType={type}  name={name}  multiline  minRows={2}  hideLabel={true} value={val}  */}
                 <TextInput name={name}  multiline  minRows={2}  hideLabel={true} value={val} 
                  onChange={handleValueChange}
                  style={{
                    backgroundColor: {backgroundColor},
                    borderRadius: "4px",
                    color: "#fff",
                    border: {},
                }}/>
            
            </Grid> 
        );
    }

    const renderLabel = (text) => {               
        return (<Typography variant="button"  color="primary" style={{fontSize: 12,fontWeight: 500,}}> {text} </Typography>)
    }; 

    const handleClose = () => {
        props.setOpen(false);
    };

    const updateAccount = () => {          
        console.log("updateAddress",updatingItem)
        httpRequest.postAccountDetail(props.accessToken,updatingItem).then(async function(body){      
            console.log("postAccountDetail-" + JSON.stringify(body.data));  
            props.setIsUpdatedAccount(true);
            props.setOpen(false);
        });       
    };
    
    return(
        <div>
           {/* {props.open &&  <Grid item container direction="row"  justifyContent="center" alignItems="center"
                        style={{ width: "50%" }} spacing={1}
                        className="darkForm editJobForm">
                        {renderTextEditor("Account Name", "accountName", "text", accountDetail.accountName,12)}                     
                        {renderTimeFieldEditor("Open Time", "openTime", "text", accountDetail.openTime,6)}
                        {renderTimeFieldEditor("Close Time", "closeTime", "text", accountDetail.closeTime,6)}
                        {renderMultilineTextEditor("Note","accountListNote","text",accountDetail.accountListNote,12,"#F78133","1px solid #F78133")}
                        {renderDateTimeEditor("Note Expiry","accountListNoteExpiry", accountDetail.accountListNoteExpiry,12)} 
                        <Grid item xs={2}><Typography variant="button"  color="primary" style={{fontSize: 12,fontWeight: 500,}}> Address </Typography></Grid>
                        <Grid item xs={8}><InfoField value={accountDetail.address} /></Grid>
                        <Grid item xs={1}>
                            <IconButton  size="medium"  tooltip="Edit address"   onClick={() => {setIsEditAddress(true)}}>
                                <EditIcon style={{verticalAlign: 'middle', transform: 'scale(1.2)', color: '#007E82' }}></EditIcon>
                            </IconButton>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton  size="medium"  tooltip="Edit address"   onClick={() => {setIsEditAddress(false)}}>
                                <ClearIcon style={{verticalAlign: 'middle', transform: 'scale(1.2)', color: '#007E82' }}></ClearIcon>
                            </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                           {isEditAddress &&<EditAddressForm setIsEditAddress={setIsEditAddress} setUpdatingFunc={setUpdatingItem} accountDetail={accountDetail}/>}
                        </Grid>                       
                        <Grid item xs={6} style={{ padding: '1% 1%' }} container justifyContent="center">
                            <IconButton size="medium"  tooltip="Cancel" onClick={() =>{props.setEditAccount(false)}}>
                               <ClearIcon style={{verticalAlign: 'middle', transform: 'scale(1.5)', color: palette.greys.white }}></ClearIcon>
                            </IconButton>                         
                        </Grid>
                        <Grid item xs={6} style={{ padding: '1% 1%' }} container justifyContent="center">
                            <IconButton size="medium" tooltip="Save"  onClick={() =>{ updateAccount()}}>
                               <DoneAllIcon  style={{verticalAlign: 'middle', transform: 'scale(1.5)', color: palette.greys.white }}></DoneAllIcon>
                            </IconButton>                           
                        </Grid>  
                    </Grid>} */}
             <Dialog  open={props.open}  
              maxWidth='xs'  
              className="classes.dialog" 
              //className="darkDialog"  
              onClose={handleClose} 
              aria-describedby="alert-dialog-slide-description"
              TransitionComponent={Transition}
              disableEnforceFocus
              fullScreen={fullScreen}
              sx={{ zIndex: (theme) => theme.zIndex.modal + 2 }}
            >
                <DialogTitle style={{ fontSize: '24px' }}>Edit Account</DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid item container direction="row"  justifyContent="flex-end" alignItems="center"
                        style={{ margin: "0", width: "100%" }} spacing={1}
                        className="darkForm editJobForm">
                        {renderTextEditor("Account Name", "accountName", "text", accountDetail.accountName,12)}
                        {renderTimeFieldEditor("Open Time", "openTime", "text", accountDetail.openTime,6)}
                        {renderTimeFieldEditor("Close Time", "closeTime", "text", accountDetail.closeTime,6)}
                        {renderMultilineTextEditor("Note","accountListNote","text",accountDetail.accountListNote,12,"#F78133","1px solid #F78133")}
                        {renderDateTimeEditor("Note Expiry","accountListNoteExpiry", accountDetail.accountListNoteExpiry,12)} 
                        <Grid item xs={2}><Typography variant="button"  color="primary" style={{fontSize: 12,fontWeight: 500,}}> Address </Typography></Grid>
                        <Grid item xs={8}><InfoField value={accountDetail.address} /></Grid>
                        <Grid item xs={1}>
                            <IconButton  size="medium"  tooltip="Edit address"   onClick={() => {setIsEditAddress(true)}}>
                                <EditIcon style={{verticalAlign: 'middle', transform: 'scale(1.2)', color: '#007E82' }}></EditIcon>
                            </IconButton>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton  size="medium"  tooltip="Edit address"   onClick={() => {setIsEditAddress(false)}}>
                                <ClearIcon style={{verticalAlign: 'middle', transform: 'scale(1.2)', color: '#007E82' }}></ClearIcon>
                            </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                           {isEditAddress &&<EditAddressForm setIsEditAddress={setIsEditAddress} setUpdatingFunc={setUpdatingItem} accountDetail={accountDetail}/>}
                        </Grid>                   
                        <Grid item xs={6} style={{ padding: '1% 1%' }} container justifyContent="center">
                            <IconButton size="medium"  tooltip="Cancel" onClick={() =>{props.setEditAccount(false)}}>
                               <ClearIcon style={{verticalAlign: 'middle', transform: 'scale(1.5)', color: palette.greys.white }}></ClearIcon>
                            </IconButton>                         
                        </Grid>
                        <Grid item xs={6} style={{ padding: '1% 1%' }} container justifyContent="center">
                            <IconButton size="medium" tooltip="Save"  onClick={() =>{ updateAccount()}}>
                               <DoneAllIcon  style={{verticalAlign: 'middle', transform: 'scale(1.5)', color: palette.greys.white }}></DoneAllIcon>
                            </IconButton>                           
                        </Grid>  
                    </Grid>
                </DialogContent>
            </Dialog>     
        </div>    
    );


};