import React, { useState, useEffect } from "react";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import "animate.css/animate.css";

const TagCheckBox = (props) => {
  const [checkBoxValue, setCheckBoxValue] = useState(props.value);  

  const handleChange = (event) => {
    props.onChange({
      name: props.param,
      value: !checkBoxValue,
    });
    setCheckBoxValue(!checkBoxValue);
  };

  return (
    <div style={{ marginBottom: "10px" }}>
      <FormControlLabel control={<Checkbox checked={checkBoxValue}/>} label={props.param} onChange={handleChange} />
    </div>
  );
};

export default TagCheckBox;
