
/*
TODO: 
1. Hover over driven icon displays driver contact info at top
2. Double click driver icon displays driver messages
3. if router's url contains driver id, then display only the driver
*/
import React, { useEffect, useState } from 'react';
import Map, { Marker, FullscreenControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import ExceptionPin from './ExceptionPin';
import DriverPin from './DriverPin';
import TripPin from './TripPin';
import NoticeEmitter from "../../NoticeUtil";

const AllMapPanel = props => {
    const [mapPoints, setMapPoints] = useState(props.mapPoints);
    const [clickCount, setClickCount] = useState(0);
    const [clickTimer, setClickTimer] = useState(null);
    const [selectedDriver, setSelectedDriver] = useState(null);
    const [exceptionMapPoints, setExceptionMapPoints] = useState([]);
    const [driverMapPoints, setDriverMapPoints] = useState([]);
    const [pickTripMapPoints, setPickTripMapPoints] = useState([]);
    const [deliveryTripMapPoints, setDeliveryTripMapPoints] = useState([]);

    useEffect(() => {
        const drvPointsList = [];
        const exceptionPointsList = [];
        const deliveryTripPointsList = [];
        const pickTripPointsList = [];

        props.mapPoints.forEach((value) => {
            if (value.mapImage === "Car") {
                if (value.exception !== null && value.exception === true)
                    exceptionPointsList.push(value);
                else
                    drvPointsList.push(value);
            } else {
                if (props.tripList.length > 0) {
                    var trip = props.tripList.find(function(obj) { return obj.jobID === value.jobID; });
                    if (trip !== undefined) {
                        if (value.mapLabel.toUpperCase() === "D" || value.mapLabel.toUpperCase() === "DD" || 
                            value.mapLabel.toUpperCase() === "SD" || value.mapLabel.toUpperCase() === "RD" || 
                            value.mapLabel.toUpperCase() === "DN")
                            deliveryTripPointsList.push(value);
                        else
                            pickTripPointsList.push(value);
                    }
                }
            }
        });

        setDriverMapPoints(drvPointsList);
        setExceptionMapPoints(exceptionPointsList);
        setPickTripMapPoints(pickTripPointsList);
        setDeliveryTripMapPoints(deliveryTripPointsList);

    }, [props.mapPoints, props.tripList]);

    const handleIconClick = (drNum, json) => {
        if (clickTimer) {
            // Double click detected
            clearTimeout(clickTimer);
            setClickTimer(null);
            props.handlerDriverPinClick(drNum);
        } else {
            // First click
            setSelectedDriver(drNum);
            props.hoverHandler(drNum, json);
            
            const timer = setTimeout(() => {
                setClickTimer(null);
            }, 300);
            setClickTimer(timer);
        }
    };

    function handleTripClick(drNum, jobID, json) {
        let inxURL = "/jobdetail/" + jobID;
        window.open(inxURL, "_blank");
    }

    // Cleanup
    useEffect(() => {
        return () => {
            if (clickTimer) {
                clearTimeout(clickTimer);
            }
        };
    }, [clickTimer]);

    return (mapPoints[mapPoints.length - 1] !== undefined) ? (
        <Map
            initialViewState={{
                longitude: mapPoints[0].long,
                latitude: mapPoints[0].lat,
                pitch: 0,
                zoom: 10
            }}
            style={{ width: "100%", height: "80vh" }}
            mapStyle="mapbox://styles/mapbox/streets-v9"
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
        >
            {props.IsShowDrivers && driverMapPoints.map((value) =>
                <Marker
                    key={`driver-${value.drNum}-${value.long}-${value.lat}`}
                    longitude={value.long}
                    latitude={value.lat}
                >
                    <DriverPin 
                        onClick={() => handleIconClick(value.drNum, value.json)} 
                        drNum={value.drNum} 
                        pin={value}
                    />
                </Marker>
            )}

            {props.IsShowDrivers && exceptionMapPoints.map((value) =>
                <Marker
                    key={`exception-${value.drNum}-${value.long}-${value.lat}`}
                    longitude={value.long}
                    latitude={value.lat}
                >
                    <DriverPin 
                        onClick={() => handleIconClick(value.drNum, value.json)} 
                        drNum={value.drNum} 
                        pin={value}
                    />
                    <ExceptionPin />
                </Marker>
            )}

            {props.IsShowPickup && pickTripMapPoints.map((value) =>
                <Marker
                    key={`pickup-${value.jobID}-${value.long}-${value.lat}`}
                    longitude={value.long}
                    latitude={value.lat}
                >
                    <TripPin 
                        onClick={() => handleTripClick(value.drNum, value.jobID, value.json)} 
                        pin={value}
                        json={value.json}
                    />
                </Marker>
            )}

            {props.IsShowDelivery && deliveryTripMapPoints.map((value) =>
                <Marker
                    key={`delivery-${value.jobID}-${value.long}-${value.lat}`}
                    longitude={value.long}
                    latitude={value.lat}
                >
                    <TripPin 
                        onClick={() => handleTripClick(value.drNum, value.jobID, value.json)} 
                        pin={value}
                        json={value.json}
                    />
                </Marker>
            )}

            <FullscreenControl />
        </Map>
    ) : <></>;
};

export default React.memo(AllMapPanel);